import React from 'react';

import {
    Box, 
    Checkbox,
    Flex
} from "@chakra-ui/react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { useTagBackgroundColor, useTagTextColor } from "../../styles/tagStyles";

import UserPermissionManagement from './UserPermissionManagement';

import { useForest } from "../../contexts/ForestContextProvider";
import { useGroupAccount } from '../../contexts/GroupAccountContextProvider';

const TreeManagement = () => {

    const defaultTagBackgroundColor = useTagBackgroundColor();
    const tagTextColor = useTagTextColor();
    const tagBackgroundColor = defaultTagBackgroundColor;

    const [ selectedTree, setSelectedTree ] = React.useState()
    const [ selectedUsers, setSelectedUsers ] = React.useState(null)
    const {
        trees
    } = useForest();

    const {
        getTreeUsers, saveTreeUsers, users, treeUsers
    } = useGroupAccount()

    React.useEffect(() => {
        setSelectedUsers(treeUsers.map(u => { return { email: u.email, permission: u.permission } }))
    }, [ treeUsers ])

    return (
        <Box 
            style={{ display:'flex', flexDirection:'column', justifyContent:'space-around'}}
        >
            <PanelGroup direction="horizontal">
                <Panel defaultSize={35}>
                    { trees && trees.map((tree) => {
                        return (
                            <Box
                                display="flex"
                                justifyContent="left"
                                alignItems="center"
                                width="100%"
                                minWidth="350px"
                                height={8}
                                gap={2}
                                padding={2}
                                shadow="md"
                                backgroundColor={tagBackgroundColor}
                                borderRadius={4}
                                color={tagTextColor}
                                // onClick={toggleTagHandler}
                            >
                                <Checkbox
                                    id={`cb-${tree.id}`}
                                    value={`cbv-${tree.id}`}
                                    onChange={(e) => {
                                        if (e.target.checked){
                                            setSelectedTree(tree)
                                            setSelectedUsers([])
                                            getTreeUsers(tree.id)
                                        }
                                        else {
                                            if (selectedTree.id === tree.id){
                                                setSelectedTree(null)
                                                setSelectedUsers([])
                                            }
                                        }
                                        e.stopPropagation();
                                    }}
                                    isChecked={selectedTree && selectedTree.id === tree.id}
                                    borderColor={tagTextColor}
                                    />
                                <Flex
                                    gap={2}
                                    id={`tbm-${tree.id}`}
                                >
                                    <Box width={4} textAlign="left" />
                                    {tree.name}
                                </Flex>
                            </Box>
                        )
                    })}
                </Panel>
                <PanelResizeHandle>
                    <Box width={1} height="100%" backgroundColor="gray.300"/>
                </PanelResizeHandle>
                <Panel>
                    <Flex
                        direction="column"
                        gap={1}
                        id={`up-tree-list`}
                    >
                    { selectedTree && selectedTree.id && users && users.length > 0 && users.map((user) => {
                        let isSelected = selectedUsers.some(d => d.email === user.email)
                        let permission = isSelected ? selectedUsers.filter(d => d.email === user.email)[0].permission : 'read'
                        return <UserPermissionManagement
                            user={user}
                            isChecked={selectedUsers.some(d => d.email === user.email)}
                            permission={permission}
                            onChange={(checked, permission) => {
                                // if (checked){
                                const selUsers = [ ...selectedUsers.filter(u => u.email !== user.email ), { email: user.email, permission: checked ? permission : 'remove' } ]
                                setSelectedUsers(selUsers)
                                saveTreeUsers(selectedTree, selUsers)
                                // }
                                // else {
                                //     const selUsers = [ ...selectedUsers.filter(u => u.email !== user.email )]
                                //     setSelectedUsers(selUsers)
                                //     saveTreeUsers(selectedTree, selUsers)
                                // }
                            }}
                        />
                    })
                    }
                    </Flex>
                </Panel>
            </PanelGroup>
        </Box>
    )
};

export default TreeManagement
