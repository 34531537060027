import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select
} from "@chakra-ui/react";
import { VscEye as EyeIcon, VscEyeClosed as EyeCloseIcon } from "react-icons/vsc";

import { getNames as getCountryNames } from "country-list";

import React, { useState } from "react";
import { createKeyHandler, keys } from "../../../helpers/keyboard.helper";
import { UserRepository } from "../../../repositories/UserRepository";

import Utils from '../../../helpers/utils.helper'

export function CreateNewGroupUserModal({ isOpen, onClose, initialFocusRef, onSubmit }) {

    const countryList = getCountryNames().sort();

    const [groupUser, setGroupUser] = useState("");
    
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [ showUserData, setShowUserData ] = useState(true)
    
    const enterKeyHandler = createKeyHandler(keys.ENTER, (e) => onSubmit(e.target.value));

    function onModalClose() {
        // resetFields();
        onClose?.();
    }

    const toggleShowPassword = (passwordInputName) => {
        if(passwordInputName === "password") {
            setShowPassword1(!showPassword1);
        } else if (passwordInputName === "confirmPassword") {
            setShowPassword2(!showPassword2);
        }
    }

    const handleChange = (e) => {

        const {name, value} = e.target;
        if (name === 'email'){
            if (Utils.isValidEmail(value)){
                UserRepository.isEmailExist(value)
                .then(res => {
                    setShowUserData(!res.isEmailExist)
                })
            }
        }
        setGroupUser({
            ...groupUser,
            [name]: value
        })
    }

    const isValidUser = () => {
        return !groupUser || !Utils.isValidEmail(groupUser?.email) || !groupUser?.firstName || !groupUser?.lastName || !groupUser?.country || !groupUser.password || groupUser?.password.length < 8 || groupUser?.password !== groupUser?.confirmPassword
    }

    return (
        <Modal
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onModalClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Create new group user</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl display="flex" flexDirection="column" gap={2}>

                        <FormControl isInvalid={!groupUser || !Utils.isValidEmail(groupUser?.email)}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                value={groupUser?.email}
                                onChange={handleChange}
                            />
                            { (!groupUser || !Utils.isValidEmail(groupUser?.email)) && 
                                <FormErrorMessage>A valid email is required.</FormErrorMessage>
                            }
                        </FormControl>
                        
                        { showUserData ? (
                        <>
                            <FormControl isInvalid={!groupUser || !groupUser?.firstName}>
                                <FormLabel fontWeight={600}>First Name</FormLabel>
                                <Input
                                    type="text"
                                    name="firstName"
                                    value={groupUser?.firstName}
                                    onChange={handleChange}
                                    placeholder="John"
                                />
                                { (!groupUser || !groupUser?.firstName) && 
                                    <FormErrorMessage>First name is required.</FormErrorMessage>
                                }
                            </FormControl>

                            <FormControl isInvalid={!groupUser || !groupUser?.lastName}
                                display="flex" flexDirection="column" gap={2}>
                                <FormLabel fontWeight={600}>Last Name</FormLabel>
                                <Input
                                    type="text"
                                    name="lastName"
                                    value={groupUser?.lastName}
                                    onChange={handleChange}
                                    placeholder="Doe"
                                />
                            </FormControl>
                            <FormControl isInvalid={!groupUser || !groupUser?.country}>
                                <FormLabel>Country</FormLabel>
                                <Select
                                    placeholder='Select option'
                                    name="country"
                                    value={groupUser?.country}
                                    onChange={handleChange}
                                >
                                    {countryList.map(country => (
                                        <option value={country}>{country}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl isInvalid={!groupUser || !groupUser.password || groupUser?.password.length < 8} display="flex" flexDirection="column" gap={2}>
                                <FormLabel fontWeight={600}>Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showPassword1 ? 'text' : 'password'}
                                        name="password"
                                        placeholder="Password"
                                        value={groupUser.password}
                                        onChange={handleChange}
                                    />
                                    <InputRightElement>
                                        {showPassword1 ?
                                            <EyeCloseIcon onClick={() => toggleShowPassword("password")}/> :
                                            <EyeIcon onClick={() => toggleShowPassword("password")} />}
                                    </InputRightElement>
                                </InputGroup>
                                { (!groupUser || !groupUser.password || groupUser?.password.length < 8) && 
                                    <FormErrorMessage>Password must have 8 characters minimun.</FormErrorMessage>
                                }
                            </FormControl>

                            <FormControl isInvalid={groupUser?.password !== groupUser?.confirmPassword} display="flex" flexDirection="column" gap={2}>
                                <FormLabel fontWeight={600}>Confirm Password</FormLabel>
                                <InputGroup>
                                    <Input
                                        type={showPassword2 ? 'text' : 'password'}
                                        name="confirmPassword"
                                        id="loginInput"
                                        placeholder="Confirm Password"
                                        value={groupUser.confirmPassword}
                                        onChange={handleChange}
                                        onKeyUp={enterKeyHandler}
                                    />
                                    <InputRightElement>
                                        {showPassword1 ?
                                            <EyeCloseIcon onClick={() => toggleShowPassword("confirmPassword")}/> :
                                            <EyeIcon onClick={() => toggleShowPassword("confirmPassword")} />}
                                    </InputRightElement>
                                </InputGroup>
                                { groupUser?.password !== groupUser?.confirmPassword && 
                                    <FormErrorMessage>Confirm password does not match with password.</FormErrorMessage>
                                }
                            </FormControl>
                        </>
                        ) : <>The user already exists</>}
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        isDisabled={isValidUser()}
                        colorScheme='blue'
                        mr={3}
                        onClick={(e) => {
                            onSubmit(groupUser)
                        }}
                    >
                        Add
                    </Button>
                    <Button onClick={onModalClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}