import api from './api.service'

const ENDPOINT = 'api/tree/tag'

const post = (data) => {
    return api.post(ENDPOINT, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getTagsWebsites = (tagIds) => {
    return api.post(`${ENDPOINT}/websites`, { tagIds: tagIds })
        .then(response => response?.data)
        .catch(error => {throw error})
}

const searchTags = (data) => {
    return api.post(`${ENDPOINT}/search`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const searchAllTags = (data) => {
    return api.post(`${ENDPOINT}/search/all`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const searchWords = (data) => {
    return api.post(`${ENDPOINT}/search/words`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const TagsService = {
    post,
    searchTags,
    searchAllTags,
    searchWords,
    getTagsWebsites
}
export default TagsService;
