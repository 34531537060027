import { useEffect } from 'react';

/**
 * @type {{IS_NOT: string, IS: string}}
 */
export const navConditions = {
    IS: "is",
    IS_NOT: "isNot",
}

/**
 * This hook will execute a callback when the user navigates to a specific route, based on a condition. The condition
 * can be either that the route IS `destRoute`, or that the route IS NOT `destRoute`. If the condition is met, the
 * callback will be executed when the current component is unmounted.
 * @param {"is" | "isNot"} condition
 * @param {string} destRoute
 * @param {() => {}} callback
 * @example
 *  useCallbackBasedOnNavCondition(navConditions.IS, "/user/profile", () => console.log("Navigating to user profile page"));
 */
export function useCallbackBasedOnNavCondition(condition = navConditions.IS, destRoute, callback = () => { }) {
    useEffect(() => {
        return () => {
            if (condition === navConditions.IS) {
                // TODO Find out if expecting the route will be already the destination route before leaving the useEffect
                //  is a correct assumption
                if (window.location.pathname === destRoute) {
                    callback();
                }
            } else if (condition === navConditions.IS_NOT) {
                if (window.location.pathname !== destRoute) {
                    callback();
                }
            }
        }
    }, []);
}