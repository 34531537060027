class GroupAccount {

    constructor({id, name, createdBy, createdAt, updatedAt}) {
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getCreatedBy() {
        return this.createdBy;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getUpdatedAt() {
        return this.updatedAt;
    }

    toString() {
        return JSON.stringify({
            id: this.getId(),
            name: this.getName(),
            createdBy: this.getCreatedBy(),
            createdAt: this.getCreatedAt(),
            updatedAt: this.getUpdatedAt(),
        });
    }
}

export function buildGroupAccount({id, name, createdBy, createdAt, updatedAt}) {
    return new GroupAccount({id, name, createdBy, createdAt, updatedAt});
}
