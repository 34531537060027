import React from "react";

import {useNavigate} from "react-router-dom";

import {UserRepository} from "../repositories/UserRepository";

import StoreService from "../services/store.service";
import StatisticsService from "../services/statistics.service";
import moment from "moment/moment";
import {useToast} from "@chakra-ui/react";

import {routes} from "../constants";

const UserContext = React.createContext();

export function UserContextProvider({children}) {

    const [user, setUser] = React.useState({
        firstName: '',
        lastName: '',
        country: '',
        address: '',
        phone: '',
        dob: ''
    })
    const [users, setUsers] = React.useState([]);
    const [userPaymentSession, setUserPaymentSession] = React.useState({});
    const [userSubscriptions, setUserSubscriptions] = React.useState([])

    const toast = useToast()
    const navigate = useNavigate();


    function openErrorToast(message) {
        toast({
            title: 'Error',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    function openSuccessToast(message) {
        toast({
            title: 'OK!',
            description: message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }

    const getUser = () => {
        UserRepository.getUser()
            .then(user => {
                setUser(user);
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const getUsers = () => {
        let params = {}
        UserRepository.getUsers(params)
            .then(users => {
                setUsers(users);
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const exportUsers = (body) => {
        UserRepository.exportUsers(body)
    }

    const updateUser = (user) => {
        return UserRepository.updateUser(user.userId, user)
            .then(user => {
                openSuccessToast('User updated!');
                return Promise.resolve(user);
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const deleteUsers = (userIds) => {
        return UserRepository.deleteUsers(userIds)
            .then(users => {
                setUsers(users)
            }, (error) => {
                openErrorToast(error.message);
            })
    }

    const deactivateUser = () => {
        return UserRepository.deactivateUser()
        .then(async user => {
            await StoreService.clearUserStorage()
            navigate(routes.LOGIN);
        }, (error) => {
            openErrorToast(error.message)
        })
    }

    const setUserAdmin = (userId, isAdmin) => {
        return UserRepository.setAdmin(userId, isAdmin)
            .then(user => {
                return user
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const handleSearch = (searchStr, country, fromRegisterDate, toRegisterDate) => {
        try {
            let data = {
                searchStr: searchStr,
                country: country,
                fromRegisterDate: fromRegisterDate && moment(fromRegisterDate).format("YYYY-MM-DD"),
                toRegisterDate: toRegisterDate && moment(toRegisterDate).format("YYYY-MM-DD"),
            };

            UserRepository.search(data)
                .then(users => {
                    setUsers(users);
                }, (error) => {
                    openErrorToast(error.message)
                })
        } catch (error) {
            openErrorToast(error.message);
        }
    }

    const getSubscriptions = () => {
        UserRepository.getSubscriptions()
            .then(subscriptions => {
                setUserSubscriptions(subscriptions);
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const getPaymentSession = () => {
        UserRepository.getPaymentSession()
            .then(paymentSession => {
                setUserPaymentSession(paymentSession);
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    const getStatistics = () => {
        return StatisticsService.get()
            .then(response => {
                return Promise.resolve(response.data)
            }, (error) => {
                openErrorToast(error.message)
            })
    }

    return (
        <UserContext.Provider
            value={{
                user,
                users,
                userSubscriptions,
                userPaymentSession,
                updateUser,
                getUser,
                getSubscriptions,
                getPaymentSession,
                getUsers,
                setUser,
                deleteUsers,
                deactivateUser,
                handleSearch,
                exportUsers,
                getStatistics,
                setUserAdmin
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return React.useContext(UserContext);
}
