import {Box, Checkbox, Text, Mark, useHighlight} from "@chakra-ui/react";
import React from "react";
import {getContextMenuItemsForTag, treeLayouts} from "../../helpers/treeContextualMenu.helper";
import {TagContextualMenu} from "../tag-contextual-menu";
import { TagPathText } from "../pathText/TagPathText";

import { useModal } from "../modal-manager";


export const TagSearchResult = ({
    tag,
    tagPath,
    searchQuery,
    onCheckboxChange
}) => {
    const chunks = useHighlight({
        text: tag.getName(),
        query: [searchQuery],
    });

    const { openTagModalOfType } = useModal();

    const contextualMenuItems = getContextMenuItemsForTag({ tag, currentLayout: treeLayouts.TAG_SEARCH_RESULTS });

    const onItemSelected = (e, item) => {
        openTagModalOfType(item.action, tag)
    }
    return (
        <>
            <Box padding={4} border="1px solid white">
                <TagPathText>{tagPath}</TagPathText>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={2} marginTop={2}>
                        <Checkbox
                            onChange={e => {
                                e.stopPropagation();
                                onCheckboxChange(e);
                            }}
                            isChecked={tag.isChecked()}
                        />
                        <Text fontSize="lg" fontWeight={700}>
                            {chunks.map(({ match, text }) => {
                                if (!match) return text;
                                return (
                                    <Mark bg='blue.700' color="white" borderRadius={4}>
                                        {text}
                                    </Mark>
                                )
                            })}
                        </Text>
                    </Box>
                    {contextualMenuItems.length > 0 && (
                        <TagContextualMenu contextualMenuItems={contextualMenuItems} onItemSelected={onItemSelected} />
                    )}
                </Box>
            </Box>
        </>
    );
};