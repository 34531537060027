import api from './api.service'

const ENDPOINT = 'api/website'

const getById = (websiteId) => {
    return api.get(`${ENDPOINT}/${websiteId}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const post = (data) => {
    return api.post(ENDPOINT, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const put = (websiteId, data) => {
    return api.put(`${ENDPOINT}/${websiteId}`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const del = (websiteId) => {
    return api.delete(`${ENDPOINT}/${websiteId}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getWebsiteTags = (websiteId) => {
    return api.get(`${ENDPOINT}/${websiteId}/tags`)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const getWeblinkWebsite = (url) => {
    return api.get('api/weblink/website',{
        params: {
            url: url
        }})
        .then(response => response?.data)
        .catch(error => {throw error})
}

const WebsiteService = {
    post,
    put,
    del,
    getById,
    getWeblinkWebsite,
    getWebsiteTags,
}
export default WebsiteService;
