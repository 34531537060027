import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
} from "@chakra-ui/react";
import {VscFilter as FilterIcon} from "react-icons/vsc";
import React from "react";

export const AdminFiltersAccordionWrapper = ({ children }) => {
    return (
        <Accordion allowToggle>
            <AccordionItem>
                <AccordionButton height={{ base: 12, md: undefined }}>
                    <Box as="span" flex='1' textAlign='left'>
                        Filters & Actions
                    </Box>
                    <FilterIcon />
                </AccordionButton>
                <AccordionPanel padding={0}>
                    {children}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}
