const tagIdsAreEqual = (tag1, tag2) => tag1.getId() === tag2.getId();
const tagIdsAndTreeIdsAreEqual = (tag1, tag2) => tag1.getId() === tag2.getId() && tag1.getTreeId() === tag2.getTreeId();

class Forest {

    constructor({ trees }) {
        this.trees = trees || [];
    }

    getTrees() {
        return this.trees;
    }

    setTrees(trees) {
        this.trees = trees;
    }

    getTreeById(treeId) {
        return this.trees.find(tree => tree.getId() === treeId);
    }

    addTree(tree) {
        this.trees = {
            ...this.trees,
            tree,
        }
    }

    unselectAllTags() {
        this.trees.forEach(tree => tree.unselectAllTags());
        // The following is not needed to uncheck all tags, but in order to create a new object
        //  we are recreating it with the mutated objects
        this.setTrees([
            ...this.trees,
        ]);
    }

    getAllForestTags() {
        return this.trees.flatMap(tree => tree.getTags());
    }

    getSelectedTags() {
        return this.trees.flatMap(tree => tree.getSelectedTags());
    }

    isAnyTagSelected() {
        return this.getSelectedTags().length > 0;
    }

    getSelectedTagIds() {
        const allSelectedTags = this.getSelectedTags();
        return [...new Set(allSelectedTags.map(tag => tag.getId()))];
    }

    updateCheckedStatusById(tags, checkedStatus) {
        // TODO Instead of operating with all the tags here, request each tree to perform the operation, and then create
        //  a new instance of the trees.
        const allTags = this.getAllForestTags();
        const tagsToUpdate = allTags.filter(t => tags.some(tag =>
            (checkedStatus
                ? tagIdsAreEqual(tag, t)
                : tagIdsAndTreeIdsAreEqual(tag, t) )));
        tagsToUpdate.forEach(t => t.setIsChecked(checkedStatus));
    }

    getSelectedTreesMap() {
        return this.trees.reduce((acc, tree) => {
            return {
                ...acc,
                [tree.getId()]: tree.getSelectedTags(),
            }
        },{});
    }
}

export function buildForest({ trees }) {
    return new Forest({ trees });
}
