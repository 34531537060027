import React, {useState, useEffect} from "react";
import {Box, Button, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {useForest} from "../../contexts/ForestContextProvider";
import {WebpageEditor} from "../webpage-editor";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../constants";
import {finishIntent, isIntentActive} from "../../native-mobile/intent-listener";
import {NoteEditor} from "../note-editor";
import {navConditions, useCallbackBasedOnNavCondition} from "../../hooks/useCallbackBasedOnNavDestination";

export function MobileConfirmationUI() {
    const {
        addWebpage,
        addNote,
        webpageData,
        noteData,
        setWebpageData,
        setNoteData,
        unselectAllTags,
        forest,
        collectActiveTabData,
    } = useForest();
    const navigate = useNavigate();

    const [note, setNote] = useState(noteData);
    const [webpage, setWebpage] = useState(webpageData);
    const [isSaving, setIsSaving] = useState(false);
    const location = useLocation();
    useCallbackBasedOnNavCondition(navConditions.IS_NOT, `${routes.TAG}/${routes.TAG_SELECT_TAGS}`, () => forest.unselectAllTags());

    useEffect(() => {
        collectActiveTabData();
    }, []);

    useEffect(() => {
        setNote(noteData);
    }, [noteData]);

    useEffect(() => {
        setWebpage(webpageData);
    }, [webpageData]);

    const selectedTags = forest.getSelectedTags();

    async function saveElement() {
        setIsSaving(true);
        try {
            if (webpage) {
                await addWebpage(webpage);
            } else if (note) {
                await addNote(note);
            }
        } catch (e) {
            console.log(e);
        }
        // TODO Should this code be in an application?
        if (isIntentActive()) {
            finishIntent();
        } else {
            setWebpageData(null);
            setNoteData(null);
            unselectAllTags();
            navigate(routes.DASHBOARD);
        }
        setIsSaving(false);
    }

    let activeTabIndex = 0;
    if(location.search.indexOf("note_id") !== -1) {
        activeTabIndex = 1;
    }

    return (
        <>
            {!isIntentActive() ? (
                <>
                    <Heading
                        as="h1"
                        fontSize="2xl"
                        height="4rem"
                        borderColor="gray.300"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        TagMachine Catalogation Page
                    </Heading>
                    <Tabs padding={4} defaultIndex={activeTabIndex}>
                        <TabList>
                            <Tab>Webpage</Tab>
                            <Tab>Note</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel paddingX={{ base: 0, sm: undefined }}>
                                <WebpageEditor
                                    webpageData={webpage}
                                    setWebpageData={setWebpage}
                                    selectedTags={selectedTags}
                                />
                            </TabPanel>
                            <TabPanel paddingX={{ base: 0, sm: undefined }}>
                                <NoteEditor
                                    noteData={note}
                                    setNoteData={setNote}
                                    selectedTags={selectedTags}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            ) : (
                <Box padding={4}>
                    {!!webpage && (
                        <WebpageEditor
                            webpageData={webpage}
                            setWebpageData={setWebpage}
                            selectedTags={selectedTags}
                        />
                    )}
                    {!!note && (
                        <NoteEditor
                            noteData={note}
                            setNoteData={setNote}
                            selectedTags={selectedTags}
                        />
                    )}
                </Box>
            )}
            <Flex
                justifyContent="end"
                gap={2}
                position="fixed"
                bottom="0"
                width="100%"
                paddingX={4}
                paddingY={2}
            >
                <Link to={"/tag/select-tags"} replace>
                    <Button>Back</Button>
                </Link>
                <Button
                    isDisabled={selectedTags?.length === 0 || (!webpage?.url && !note?.description)}
                    onClick={saveElement}
                    colorScheme="blue"
                >
                    {isSaving ? 'Saving' : 'Save'}
                </Button>
            </Flex>
        </>
    );
}