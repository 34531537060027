/**
 * @typedef {Object} Tag - A tag that can be associated with a tagged item.
 * @property {string} tagId - The unique identifier of the tag
 * @property {string} name - The name of the tag
 * @property {number} createdBy - The username of the user who created the tag
 * @property {number} createdAt - The date when the tag was created
 * @property {number} updatedAt - The date when the tag was last updated
 */

export const taggedItemTypes = {
    WEBSITE: 'website',
    NOTE: 'note',
};

/**
 * @typedef {Object} TaggedItem - An element that has been given tags. It can be a website or a note. It encapsulates
 * the details of the data model coming from the backend by exposing only what the frontend needs.
 */
class TaggedItem {
    /**
     * @param {Object} config
     * @param {string} config.id - The unique identifier of the tagged item
     * @param {string} config.url - The URL of the website. If it's a note, it will be undefined
     * @param {string} config.title - The title of the website or note
     * @param {string} config.description - The description of the website or note. In case of being a note, this will
     * be the body of the note.
     * @param {string} config.createdBy - The username of the user who created the tagged item
     * @param {string} config.createdAt - The date when the tagged item was created
     * @param {Array<Tag>} config.tags - The tags associated with the tagged item
     */
    constructor({ id, url, title, description, createdBy, createdAt, tags}) {
        this.id = id;
        this.url = url;
        this.title = title;
        this.description = description;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.tags = tags;
        this.type = this.url ? taggedItemTypes.WEBSITE : taggedItemTypes.NOTE;
    }

    getId() {
        return this.id;
    }

    getUrl() {
        return this.url;
    }

    /**
     * Returns the type of the tagged item. It can be 'website' or 'note'. Use taggedItemTypes enum to compare the result.
     * @returns {"website" | "note"}
     */
    getType() {
        return this.type;
    }

    getTags() {
        return this.tags.map((tag) => ({ tagId: tag.tagId, name: tag.name, treeId: tag.treeId, permission: tag.permission }));
    }

    /**
     * Returns true if the tagged item is a website
     * @returns {boolean}
     */
    isWebsite() {
        return this.type === taggedItemTypes.WEBSITE;
    }

    /**
     * Returns true if the tagged item is a note
     * @returns {boolean}
     */
    isNote() {
        return this.type === taggedItemTypes.NOTE;
    }

    /**
     * Removes a tag from the tagged item
     * @param {Tag} tagToDelete
     */
    deleteTag(tagToDelete) {
        this.tags = this.tags.filter(tag => tag.tagId !== tagToDelete.tagId);
    }
}

export function buildTaggedItem({ id, url, title, description, createdBy, createdAt, tags}) {
    return new TaggedItem({ id, url, title, description, createdBy, createdAt, tags});
}

// FIXME this probably should be handled in the infrastructure layer
export function buildTaggedItemFromBackendEntity(backendEntity) {
    const { noteId, websiteId, url, title, description, createdBy, createdAt, tags} = backendEntity;
    const id = websiteId || noteId;
    return new TaggedItem({id, url, title, description, createdBy, createdAt, tags});
}