import {
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    FormControl,
    FormLabel,
    IconButton,
    Box,
    useColorModeValue
} from "@chakra-ui/react";
import {
    VscTrash as DeleteIcon,
    VscSearch as SearchIcon,
    VscChromeClose as CloseIcon
} from "react-icons/vsc";
import { convertToDateDomElementFormat } from "../../helpers/convertToDateDomElementFormat";

const Filters = ({ text, startDate, endDate, onStartDateChange, onEndDateChange, onTextChange, onClearFilters, disabled }) => {
    // TODO This is repeated in several places, use a common place for delete buttons
    const inputBorderColor = useColorModeValue("gray.500", null);

    const startDateText = startDate ? convertToDateDomElementFormat(startDate) : '';
    const endDateText = endDate ? convertToDateDomElementFormat(endDate) : '';

    return (
        <>
            <FormControl
                width={{ base: "100%" , sm: "xs"}}
                borderBottom={{ base: "1px solid", sm: "none" }}
                borderColor="gray.300"
                paddingBottom={{ base: 4, sm: 0 }}
            >
                <FormLabel>Text Search</FormLabel>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents='none'
                        children={<SearchIcon color='gray.500' />}
                    />
                    <Input
                        name="text"
                        value={text}
                        type="text"
                        disabled={disabled}
                        borderColor={inputBorderColor}
                        onChange={(e) => {
                            onTextChange(e.target.value)
                        }}/>
                    {text && (<InputRightElement
                        cursor="pointer"
                        onClick={() => onTextChange("")}
                        children={<CloseIcon color='gray.500' />}
                    />)}
                </InputGroup>
            </FormControl>
            <FormControl width={{ base: "100%" , sm: "3xs"}}>
                <FormLabel>Start Date</FormLabel>
                <Input
                    value={startDateText}
                    type="date"
                    disabled={disabled}
                    borderColor={inputBorderColor}
                    onChange={(e) => {
                        const dateAsNumber = e.target.valueAsNumber;
                        // FIXME it is a bit weird to return an object that can be either string or a Date
                        const newDate = Number.isInteger(dateAsNumber) ? new Date(dateAsNumber) : "";
                        onStartDateChange(newDate);
                    }}/>
            </FormControl>
            <FormControl width={{ base: "100%" , sm: "3xs"}}>
                <FormLabel>End Date</FormLabel>
                <Input
                    value={endDateText}
                    type="date"
                    disabled={disabled}
                    borderColor={inputBorderColor}
                    onChange={(e) => {
                        const dateAsNumber = e.target.valueAsNumber;
                        // FIXME it is a bit weird to return an object that can be either string or a Date
                        const newDate = Number.isInteger(dateAsNumber) ? new Date(dateAsNumber) : "";
                        onEndDateChange(newDate);
                    }}/>
            </FormControl>
            <Box display="flex" alignItems="end" gap={2}>
                <IconButton
                    icon={<DeleteIcon />}
                    variant="outline"
                    borderColor={inputBorderColor}
                    aria-label="Clear Filters"
                    onClick={onClearFilters}
                />
            </Box>
        </>
    )
}

export { Filters };