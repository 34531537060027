import {useEffect, useMemo, useState} from "react";
import {getLocalPreference, saveLocalPreference} from "../../../helpers/storage";

const SEARCH_SHOW_ONLY_ESSENTIAL_PREFERENCE = "search-show-only-essential-info"
const SEARCH_LAYOUT_PREFERENCE = "search-layout-preference"

const layouts = {
    GRID: "grid",
    LIST: "list",
}

function saveLayoutPreference(newPreference) {
    saveLocalPreference(SEARCH_LAYOUT_PREFERENCE, newPreference)
}

function getLayoutPreference() {
    return getLocalPreference(SEARCH_LAYOUT_PREFERENCE) || layouts.LIST;
}

export function useSearchLayoutConfig() {
    const [showLimitedInfo, setShowLimitedInfo] = useState(parseInt(getLocalPreference(SEARCH_SHOW_ONLY_ESSENTIAL_PREFERENCE), 10));
    const [layoutPreference, setLayoutPreference] = useState(getLayoutPreference());

    useEffect(() => {
        saveLocalPreference(SEARCH_SHOW_ONLY_ESSENTIAL_PREFERENCE, showLimitedInfo);
    }, [showLimitedInfo]);

    useEffect(() => {
        saveLayoutPreference(layoutPreference);
    }, [layoutPreference]);

    const toggleEssentialInformation = () => {
        setShowLimitedInfo(!showLimitedInfo);
    }

    const toggleGrid = () => {
        const newLayout = layoutPreference === layouts.GRID ? layouts.LIST : layouts.GRID;
        setLayoutPreference(newLayout)
    }

    const isListLayout = useMemo(() => {
        return layoutPreference === layouts.LIST;
    }, [layoutPreference]);

return { showLimitedInfo, toggleEssentialInformation, isListLayout, layoutPreference, toggleGrid };
}