export const permissions = {
    READ: "read",
    WRITE: "write",
    ADMIN: "admin",
}

export const routes = {
    APP_ROOT: "/",
    LOGIN: "/login",
    SIGNUP: "/signup",
    RECOVERY: "/recovery",
    PASSWORD: "/password",
    COMPLETE_PROFILE: "/complete-profile",
    TAG: "/tag",
    TAG_WEBSITE: "website",
    TAG_NOTE: "note",
    TAG_SELECT_TAGS: "select-tags",
    TAG_CONFIRM: "confirm",
    DASHBOARD: "/dashboard",
    DASHBOARD_FILTERS: "/dashboard/filters",
    USER: "/user",
    USER_PROFILE: "/user_profile",
    GROUP_ACCOUNT: "/group_account",
    GROUP_ACCOUNT_PROFILE: "profile",
    GROUP_ACCOUNT_USERS: "users",
    MY_ACCOUNT: "/my_account",
}