import { Box, Flex, MenuItem } from "@chakra-ui/react";
import React from "react";

export function ColorContextualItem({ contextualItem, onClick }) {
    return (
        <MenuItem
            key={`menu-item-${contextualItem.action}-${contextualItem.value}`}
            onClick={(e) => onClick(e, contextualItem)}
        >
            <Flex gap={4}>
                <Box width="6" heigth="2" borderRadius="full" backgroundColor={contextualItem.value} />
                {contextualItem.label}
            </Flex>
        </MenuItem>
    );
}