import React from "react";
import { useToast } from "@chakra-ui/react";

import { GroupAccountRepository } from "../repositories/GroupAccountRepository";

const GroupAccountContext = React.createContext();

export function GroupAccountContextProvider({ children }) {
    
    const [ groupAccount, setGroupAccount ] = React.useState(null);
    const [ users, setUsers ] = React.useState([]);
    const [ treeUsers, setTreeUsers ] = React.useState([]);
    
    const toast = useToast();

    function openErrorToast(message) {
        toast({
            title: 'Error',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    function openSuccessToast(message) {
        toast({
            title: 'OK!',
            description: message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }

    const getGroupAccount = () => {
        return GroupAccountRepository.get()
        .then(data => {
            setGroupAccount(data)
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const saveGroupAccount = (data) => {
        return GroupAccountRepository.save(data)
        .then(data => {
            setGroupAccount(data)
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const getUsers = (text, fromDate, toDate) => {
        return GroupAccountRepository.getUsers(text, fromDate, toDate)
        .then(data => {
            setUsers(data)
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const saveUser = (data) => {
        return GroupAccountRepository.saveUser(data)
        .then((data) => {
            setUsers(data)
            openSuccessToast('User saved!')
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const deleteUsers = (userIds) => {
        return GroupAccountRepository.deleteUsers(userIds)
        .then((data) => {
            setUsers(data)
            openSuccessToast('Users deleted!')
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const getTreeUsers = (treeId) => {
        return GroupAccountRepository.getTreeUsers(treeId)
        .then(data => {
            setTreeUsers(data)
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    const saveTreeUsers = (tree, users) => {
        return GroupAccountRepository.saveTreeUsers(tree.id, tree.rootTagId, users)
        .then((data) => {
            setTreeUsers(data)
            openSuccessToast('User permission saved!')
        })
        .catch((error) => {
            openErrorToast(error.message)
        })
    }

    return <GroupAccountContext.Provider value={{
        getGroupAccount, saveGroupAccount, getUsers, saveUser, deleteUsers, getTreeUsers, saveTreeUsers,
        groupAccount, users, treeUsers
    }}>
        { children }
    </GroupAccountContext.Provider>
}

export function useGroupAccount() {
    return React.useContext(GroupAccountContext);
}
