// TODO moment is not maintained anymore, move to a different library
import moment from "moment";

export function DateDisplay({ date, dateInMillis }) {
    let dateDisplay;
    if (date) {
        dateDisplay = moment(date).format("YYYY-MM-DD hh:mm");
    } else {
        dateDisplay = moment(dateInMillis  * 1000).format("YYYY-MM-DD hh:mm");
    }
    return <time dateTime={dateDisplay}>{dateDisplay}</time>;
}