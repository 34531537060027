import {Box, Checkbox, FormControl, FormLabel, Switch, Text} from "@chakra-ui/react";
import React from "react";

export const WebSearchActionsBar = ({firstShownItem, lastShownItem, itemsCount, onToggleEssentialInformation, showLimitedInfo, onToggleAllCheckboxes}) => {
    return (
        <Box display="flex" gap={4} alignItems="center" paddingY={4}>
            <Checkbox onChange={onToggleAllCheckboxes}/>
            <Text whiteSpace="nowrap">Results: {firstShownItem}-{lastShownItem} of {itemsCount}</Text>
            <FormControl display="flex" alignItems="center" gap={2} marginStart={4}>
                <Switch id="only-essential-selector" onChange={onToggleEssentialInformation} defaultChecked={showLimitedInfo}/>
                <FormLabel htmlFor="only-essential-selector" marginBottom={0}>
                    Only essential information
                </FormLabel>
            </FormControl>
        </Box>
    )
}
