import NotesService from "../services/notes.service";
import TreesService from "../services/trees.service";
import tagHelper from "../helpers/tag.helper";
import {buildTag} from "../models/Tag";


export const NoteRepository = {
    save: function save(note, forest) {
        const selectedTagsByTreeId = tagHelper.formatSelectedTags(forest)
        return NotesService.post({
            ...note,
            treeTagIds: selectedTagsByTreeId,
        })
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response;
            }
        })
        .catch((error) => {
            throw error
        })
    },
    getById: function getById(noteId) {
        return NotesService.getById(noteId)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data;
            }
        })
        .catch((error) => {
            throw error
        })
    },
    getNoteTags: function getNoteTags(noteId) {
        return TreesService.getNoteTags(noteId)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data.map(tag => buildTag({id: tag.tagId, ...tag}));
            }
        })
        .catch((error) => {
            throw error;
        })
    }
}