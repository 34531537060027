import {permissions} from "../constants";

export const treeLayouts = {
    DEFAULT: "default",
    TAG_SEARCH_RESULTS: "tag-search-results",
};

export const contextualMenuIds = {
    NEW_GROUP_USER: "new-group-user",
    NEW_TAG: "new-tag",
    DELETE_TAG: "delete-tag",
    SHARE_TREE: "share-tree",
    RENAME_TAG: "rename-tag",
    CREATE_TREE_FROM_TAG: "create-tree-from-tag",
    EDIT_PERMISSIONS: "edit-permissions",
    DELETE_SHARED_TREE:"delete-shared-tree",
    CHANGE_COLOR: "change-tree-color",
}

export const contextualMenuTypes = {
    TEXT: "text",
    COLOR: "color",
}

const excludedMenuItemsPerLayout = {
    [treeLayouts.DEFAULT]: [],
    [treeLayouts.TAG_SEARCH_RESULTS]: [
        contextualMenuIds.RENAME_TAG,
        contextualMenuIds.DELETE_SHARED_TREE,
        contextualMenuIds.CHANGE_COLOR
    ],
}

/**
 * Returns the list of contextual menu items that correspond to a given tag within a tree based on the particular layout
 * it is present in.
 * @param config.tag: The tag object for which the list of contextual items needs to be calculated.
 * @param config.currentLayout: The layout of the tree the tag is displayed in ("default" or "tag-search-results" are the
 * possible values).
 * @returns {*[]}
 */
export function getContextMenuItemsForTag({ tag = {}, currentLayout = treeLayouts.DEFAULT }) {
    let contextualMenuItems = [];
    const treePermission = tag.permission;
    const isTagShared = tag.shares?.length > 0;
    if (treePermission === permissions.ADMIN || treePermission === permissions.WRITE) {
        contextualMenuItems = [{
            action: contextualMenuIds.NEW_TAG,
            type: contextualMenuTypes.TEXT,
            label: "New Tag",
        },
        {
            action: contextualMenuIds.DELETE_TAG,
            type: contextualMenuTypes.TEXT,
            label: "Delete Tag",
        },
        {
            action: contextualMenuIds.SHARE_TREE,
            type: contextualMenuTypes.TEXT,
            label: "Share Tree",
        },
        {
            action: contextualMenuIds.RENAME_TAG,
            type: contextualMenuTypes.TEXT,
            label: "Rename Tag",
        },
        {
            action: contextualMenuIds.CREATE_TREE_FROM_TAG,
            type: contextualMenuTypes.TEXT,
            label: "Create Tree from Tag",
        }];
    }

    if (treePermission === permissions.ADMIN && isTagShared) {
        contextualMenuItems.push({
            action: contextualMenuIds.EDIT_PERMISSIONS,
            type: contextualMenuTypes.TEXT,
            label: "Edit Permissions",
        });
    }

    if (treePermission === permissions.READ && isTagShared) {
        contextualMenuItems.push({
            action: contextualMenuIds.DELETE_SHARED_TREE,
            type: contextualMenuTypes.TEXT,
            label: "Delete Shared Tree",
        });
    }
    if(!tag.parentId) {
        contextualMenuItems.push({
            label: "Tree Color",
            action: contextualMenuIds.CHANGE_COLOR,
            submenu: [
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Default",
                    type: contextualMenuTypes.COLOR,
                    value: null,
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Red",
                    type: contextualMenuTypes.COLOR,
                    value: "#FC8181"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Orange",
                    type: contextualMenuTypes.COLOR,
                    value: "#F6AD55"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Yellow",
                    type: contextualMenuTypes.COLOR,
                    value: "#F6E05E"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Green",
                    type: contextualMenuTypes.COLOR,
                    value: "#68D391"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Teal",
                    type: contextualMenuTypes.COLOR,
                    value: "#4FD1C5"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Blue",
                    type: contextualMenuTypes.COLOR,
                    value: "#63b3ed"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Cyan",
                    type: contextualMenuTypes.COLOR,
                    value: "#76E4F7"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Purple",
                    type: contextualMenuTypes.COLOR,
                    value: "#B794F4"
                },
                {
                    action: contextualMenuIds.CHANGE_COLOR,
                    label: "Pink",
                    type: contextualMenuTypes.COLOR,
                    value: "#F687B3",
                }
            ],
        });
    }

    return contextualMenuItems.filter(byExcludedForCurrentLayout);

    function byExcludedForCurrentLayout(item) {
        return !excludedMenuItemsPerLayout[currentLayout].some(excludedItem => excludedItem === item.action);
    }
}