import {Box, Button, Flex, Heading, useColorModeValue} from "@chakra-ui/react";
import {Forest} from "../forest";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useForest} from "../../contexts/ForestContextProvider";
import {finishIntent, isIntentActive} from "../../native-mobile/intent-listener";
import { getElementBeingTagged, isElementBeingTaggedAWebsite } from "../../helpers/elementBeingTagged.helper";
import {routes} from "../../constants";
import {navConditions, useCallbackBasedOnNavCondition} from "../../hooks/useCallbackBasedOnNavDestination";

export function MobileTagSelectionUI() {
    const queryParams = new URLSearchParams(window.location.search);
    const elementBeingTagged = getElementBeingTagged(queryParams);

    const { forest, webpageData, setWebpageData, noteData, setNoteData } = useForest();
    const backgroundColor = useColorModeValue("white", "gray.800");
    useCallbackBasedOnNavCondition(navConditions.IS_NOT, `${routes.TAG}/${routes.TAG_CONFIRM}`, () => forest.unselectAllTags());

    useEffect(() => {
        const { url, title, description } = elementBeingTagged;
        if (url) {
            setWebpageData({
                url,
                title,
            });
        } else if (description) {
            setNoteData({
                description,
            });
        }
    }, []);

    function cancelTaggingOperation() {
        finishIntent();
    }

    const isAnyTagSelected = forest.isAnyTagSelected();

    return (
        <Box>
            <Heading as="h1" fontSize="lg" paddingY={2} paddingX={4}>Select tags</Heading>
            <Forest.Root
                key="forest"
                webpageData={isElementBeingTaggedAWebsite(elementBeingTagged) ? webpageData : noteData}
            >
                <Forest.SearchBar />
                <Forest.TitleBar title="Trees" />
                <Forest.Trees propagateSelection={true} />
            </Forest.Root>
            <Flex
                justifyContent="end"
                gap={2}
                position="sticky"
                bottom="0"
                paddingX={4}
                paddingY={2}
                backgroundColor={backgroundColor}
            >
                {isIntentActive() && <Button onClick={cancelTaggingOperation}>Cancel</Button>}
                <Link to={"/tag/confirm"}>
                    <Button colorScheme="blue" isDisabled={!isAnyTagSelected}>Next</Button>
                </Link>
            </Flex>
        </Box>
    );
}