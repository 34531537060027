import { isUrlValid } from "../helpers/url.helper";

export const intentTypes = {
    WEBSITE: "website",
    NOTE: "note",
};

export const intentFactory = {
    get({ url, title }) {
        if (!url) {
            throw new Error("Cannot instantiate an Intent without a URL property that contains either a valid URL or arbitrary text.");
        }
        if (isUrlValid(url)) {
            return new Intent({url, title});
        } else {
            return new Intent({description: url, title});
        }
    }
}

class Intent {
    constructor({ url, title, description }) {
        if (isUrlValid(url)) {
            this.url = url;
            this.intentType = intentTypes.WEBSITE;
        } else {
            this.intentType = intentTypes.NOTE;
        }
        this.title = title;
        this.description = description;
    }

    getUrl() {
        return this.url;
    }

    getTitle() {
        return this.title;
    }

    getDescription() {
        return this.description;
    }

    getIntentType() {
        return this.intentType;
    }

    isWebsiteIntent() {
        return this.getIntentType() === intentTypes.WEBSITE;
    }

    isNoteIntent() {
        return this.getIntentType() === intentTypes.NOTE;
    }
}