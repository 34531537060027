import React, {useMemo} from 'react';

import {Tag} from '../tag';
import {useForest} from "../../contexts/ForestContextProvider";
import {ForestApplication} from "../../applications/forestApplication";

const Tree = ({treeId, tags, webpageData, selectedSearchTag, propagateSelection }) => {

    const {
        fetchForest,
        updateTagCheckedStatus,
        setTreeColor,
        trees,
        moveTag
    } = useForest(webpageData);

    const getChildrenTags = (tagList => {
        return tagList.filter((t) => {
            if (selectedSearchTag?.getId?.()){
                return t.getId() !== selectedSearchTag.getId();
            }
            else {
                return t.getParentId()
            }
        })
    })

    const getParentTag = (tagList => {
        return tagList.find((t) => {
            if (selectedSearchTag?.getId?.()){
                return t.getId() === selectedSearchTag.getId();
            } else {
                return !t.getParentId();
            }
        })
    })

    const getTreeDepth = (parentTag, tags) => {
        if (tags && tags.length > 0){
            let children = tags.filter(tag => tag.getParentId() === parentTag.getId())
            if (children.length === 0){
                return 0
            }
            else {
                let childrenDepth = children.map(child => getTreeDepth(child, tags.filter(tag => tag.getParentId() !== parentTag.getId()) ))
                return 1 + Math.max(...childrenDepth)
            }
        }
        else {
            return 1
        }
    }

    const treeColor = useMemo(() => trees.find(tree => tree.getId() === treeId)?.color, [trees, treeId]);

    return (
        <div id={`tree-${treeId}-container`} >
            {/* FIXME We are calling getParentTag() many times here, which is an expensive operation, fix that */}
            { tags?.length > 0 && getParentTag(tags) &&
                <Tag
                    treeId={treeId}
                    key={`tree-${treeId}-parent-tag`}
                    tag={getParentTag(tags)}
                    tagList={getChildrenTags(tags)}
                    treeBackgroundColor={treeColor}
                    setTreeColor={setTreeColor}
                    depth={1}
                    maxDepth={getTreeDepth(getParentTag(tags), getChildrenTags(tags))}
                    onSelectedTags={updateTagCheckedStatus}
                    propagateSelection={propagateSelection}
                    onTagDropped={(tag, newParentTag) => {
                         moveTag(tag, newParentTag);
                    }}
                />
            }
        </div>
    )
}

export default React.memo(Tree);
