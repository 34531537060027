import React from 'react';
import ReactDOM from 'react-dom/client';
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react'
import theme from "./styles/theme";
import App from './App';

import { SnackbarProvider } from 'notistack';
import {BrowserRouter as Router} from "react-router-dom";

import {PlatformContextProvider} from "./contexts/PlatformContextProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SnackbarProvider maxSnack={10}>
        <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Router>
                <PlatformContextProvider>
                    <App />
                </PlatformContextProvider>
            </Router>
        </ChakraProvider>
    </SnackbarProvider>
);
