import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip as ChartTooltip
} from "recharts";
import React from "react";

const renderDot = (dataKey, stroke) => (props) => {
    const { cx, cy, payload } = props;
    if (payload[dataKey] === 0) {
        return null; // Hide the dot for value = 0
    }
    return <circle cx={cx} cy={cy} r={4} stroke="none" fill={stroke} />;
};

export function ActivityChart({ activityData, heightInPixels  }) {
    return(
        <ResponsiveContainer width="100%" height={heightInPixels}>
            <LineChart width={400} height={400} data={activityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" interval="preserveStartEnd" tickMargin={5} />
                <YAxis />
                <ChartTooltip labelStyle={{color: "#1A202C" }} />
                <Legend />
                <Line type="monotone" dataKey="websites" stroke="#3182ce" strokeWidth={3} dot={renderDot("websites", "#3182ce")} activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="tags" stroke="#805AD5" strokeWidth={3} dot={renderDot("tags", "#805AD5")} activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
}