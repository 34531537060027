import api from './api.service'

const ENDPOINT = 'api/note'

const getById = (noteId) => {
    return api.get(`${ENDPOINT}/${noteId}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const post = (data) => {
    return api.post(ENDPOINT, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const put = (noteId, data) => {
    return api.put(`${ENDPOINT}/${noteId}`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const del = (noteId) => {
    return api.delete(`${ENDPOINT}/${noteId}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const NoteService = {
    getById,
    post,
    put,
    del
}
export default NoteService;
