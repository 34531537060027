import React from "react";
import { Capacitor } from "@capacitor/core";

export const platforms = {
    ANDROID: 'android',
    IOS: 'ios',
    WEB: 'web',
};
const platform = Capacitor.getPlatform();

const initialContextValue = {
    platform,
    isWebPlatform: platform === platforms.WEB,
    isMobilePlatform: platform !== platforms.WEB,
};
const platformContext = React.createContext(initialContextValue);

export function PlatformContextProvider(props) {
    return (
        <platformContext.Provider value={{ ...initialContextValue }}>
            {props.children}
        </platformContext.Provider>
    );
}

export function usePlatformContext() {
    return React.useContext(platformContext);
}