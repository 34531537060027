import {Box, Button, Flex, Heading,useBreakpointValue } from "@chakra-ui/react";
import { VscAdd as AddIcon } from "react-icons/vsc";
import React, {useContext, useState} from "react";
import NewTreeModal from "../modals/NewTreeModal";
import {TreesForestContext} from "./Forest";

const ADD_TREE_LABEL = "Add Tree";
export const ForestTitleBar = ({ title }) => {
    // Modal states
    const [isOpenAddTreeModal, setIsOpenAddTreeModal] = useState(false);
    const { addNewTree } = useContext(TreesForestContext);
    const addTreeButtonText = useBreakpointValue({ base: "", sm: ADD_TREE_LABEL });

    function openAddNewTreeModal () {
        setIsOpenAddTreeModal(true)
    }

    return (
        <Box padding={2}
             borderBottom="1px solid"
             borderColor="gray.300"
        >
            <Flex justifyContent="space-between" alignItems="center">
                {title && <Heading as="h3" fontSize="lg">{title}</Heading>}
                <Button
                    variant="solid"
                    fontSize="sm"
                    paddingX={6}
                    colorScheme="blue"
                    onClick={openAddNewTreeModal}
                    id="add-tree"
                    title={ADD_TREE_LABEL}
                >
                    <Flex gap={2} alignItems="center">
                        <AddIcon strokeWidth={2} />
                        {addTreeButtonText}
                    </Flex>
                </Button>
            </Flex>
            <NewTreeModal
                isOpen={isOpenAddTreeModal}
                onClose={() => setIsOpenAddTreeModal(false)}
                onSubmit={(treeName) => {
                    addNewTree(treeName);
                    setIsOpenAddTreeModal(false)
                }}
            />
        </Box>
    )
}
