import React from 'react'

import { UserList } from "../../components/user_list";

import { UserContextProvider } from "../../contexts/UserContextProvider";
import { Topbar } from "../../components/topbar";

const AdminLayout = () => {
    return (
        <div id="user-access" style={{ height:'100vh', display: 'flex', flexDirection:'column' }}>
            <Topbar />
            <UserContextProvider>
                <UserList/>
            </UserContextProvider>
        </div>
    )
};

export default AdminLayout
