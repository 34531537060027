import { MenuItem } from "@chakra-ui/react";
import React from "react";

export function TextContextualItem({ contextualItem, onClick }) {
    return (
        <MenuItem
            key={`menu-item-${contextualItem.action}`}
            onClick={(e) => onClick(e, contextualItem)}
        >
            {contextualItem.label}
        </MenuItem>
    );
}