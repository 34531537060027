import api from './api.service'
import debounce from "lodash.debounce";

const ENDPOINT = 'api/item'

// FIXME We are debouncing the search call to mitigate a problem causing too many calls due to too many rerenders.
//  this is a patch and the real solution should be found instead. The solution is avoiding the big amount of rerenders
//  Dashboard has on first load caused by changed in useForest.
//  Furthermore, this solution will cause potential search calls to be lost if search parameters change too fast
//  (something) likely if the user checks several nodes in the tree, for example.
const debouncedPost = debounce(api.post, 500, { leading: true, trailing: false });

const searchItems = (data) => {
    // This removes any duplicated tagIds we may receive in data.tagIds
    const uniqueTagIds = [...new Set(data.tagIds)];
    const requestData =  {
        ...data,
        tagIds: uniqueTagIds,
    }
    return debouncedPost(`${ENDPOINT}/search`, requestData)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const exportItems = (data) => {
    return api.post(`${ENDPOINT}/search/export`, data, {
        responseType: 'blob',
    })
        .then(response => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'excel.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl)
        })
        .catch(error => { throw error })
}
const SearchItemService = {
    searchItems,
    exportItems
}
export default SearchItemService;
