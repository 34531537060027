import StoreService from "./store.service";
import api from "./api.service";

const auth = (endpoint, data) => {
    return api.post(endpoint, data)
        .then(async (response) => {
            let accountId = response.data.data.accountId;
            let email = response.data.data.email;
            let isAdmin = response.data.data.isAdmin;
            let isGroupAccountAdmin = response.data.data.isGroupAccountAdmin;
            let isSubscriptionExpired = response.data.data.isSubscriptionExpired
            let token = response.data.data.token;
            if (token)
                await StoreService.setJwtToken(token)
            if (accountId)
                await StoreService.setAccountId(accountId)
            await StoreService.setIsAdmin(isAdmin || false)
            await StoreService.setUserEmail(email)
            await StoreService.setIsGroupAccountAdmin(isGroupAccountAdmin || false)
            await StoreService.setIsSubscriptionExpired(isSubscriptionExpired || false)
            
            return response.data
        })
        .catch((error) => error)
}
const login = (data) => auth('api/login', data);
const register = (data) => auth('api/user', data)

const AuthService = {
    login,
    register,
}
export default AuthService
