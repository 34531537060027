import React from 'react'
import { UserProfile } from "../../components/user_profile"

import { UserContextProvider } from "../../contexts/UserContextProvider";
import { Topbar } from "../../components/topbar";

const UserProfileLayout = () => {

    return (
        <>
            <Topbar />
            <UserContextProvider>
                <UserProfile/>
            </UserContextProvider>
        </>
    )
}

export default UserProfileLayout
