import React, { useEffect, useState } from "react";

import {
  Box, Center
} from "@chakra-ui/react";

const SubscriptionExpiredMessage = () => {

    return (
        <Box bg='red.300' color='white'><Center>The subscription has expired. You can purchase a new subscription in the My Subscription menu and selecting the desired plan.</Center></Box>
    )
}
export default SubscriptionExpiredMessage