import React, {useState} from "react";

// Modals
import { NewTagModal } from "../modals/NewTagModal";
import { ShareTagModal } from "../modals/ShareTagModal";
import { DeleteTagModal } from "../modals/DeleteTagModal";
import { EditPermissionsModal } from "../modals/EditPermissionsModal";
import { RenameTagModal } from "../modals/RenameTagModal";
import { DeleteSharedTreeModal } from "../modals/DeleteSharedTreeModal"
import { CreateTreeFromTagModal } from "../modals/CreateTreeFromTagModal";
import { CreateNewGroupUserModal } from "../group_account/modals/CreateNewGroupUserModal";

import { useForest } from "../../contexts/ForestContextProvider";
import { useGroupAccount } from "../../contexts/GroupAccountContextProvider";

import { contextualMenuIds } from "../../helpers/treeContextualMenu.helper"

const ModalContext = React.createContext();

export function ModalManager({ children }) {

    const [isOpenNewGroupUserModal, setIsOpenNewGroupUserModal] = useState(false);
    
    const [isOpenNewTagModal, setIsOpenNewTagModal] = useState(false);
    const [isOpenShareTreeModal, setIsOpenShareTreeModal] = useState(false);
    const [isOpenEditPermissionsModal, setIsOpenEditPermissionsModal] = useState(false);
    const [isOpenRenameTagModal, setIsOpenRenameTagModal] = useState(false);
    const [isOpenDeleteTagModal, setIsOpenDeleteTagModal] = useState(false);
    const [isOpenCreateTreeFromTagModal, setIsOpenCreateTreeFromTagModal] = useState(false);
    const [isOpenDeleteSharedTreeModal, setIsOpenDeleteSharedTreeModal] = useState(false);
    
    // TODO Consider if we want to tie the ModalManager to tags
    const [tag, setTag] = useState();
    
    // TODO Consider if we want to tie the ModalManager to the useForest hook and provider
    const {
        createNewTag,
        deleteTag,
        shareTree,
        updateTag,
        deleteSharedTree,
        createTreeFromTag
    } = useForest();

    const {
        saveUser
    } = useGroupAccount();

    const cancelRef = React.useRef();
    const initialRef = React.useRef();

    function openTagModalOfType (selectedItem, tag) {

        setTag(tag);
        setIsOpenNewGroupUserModal(false);
        setIsOpenNewTagModal(false);
        setIsOpenShareTreeModal(false);
        setIsOpenDeleteTagModal(false);
        setIsOpenEditPermissionsModal(false);
        setIsOpenRenameTagModal(false);
        setIsOpenCreateTreeFromTagModal(false)
        if (selectedItem === contextualMenuIds.NEW_GROUP_USER) {
            setIsOpenNewGroupUserModal(true);
        } else if (selectedItem === contextualMenuIds.NEW_TAG) {
            setIsOpenNewTagModal(true);
        } else if (selectedItem === contextualMenuIds.SHARE_TREE) {
            setIsOpenShareTreeModal(true);
        } else if (selectedItem === contextualMenuIds.EDIT_PERMISSIONS) {
            setIsOpenEditPermissionsModal(true);
        } else if (selectedItem === contextualMenuIds.RENAME_TAG) {
            setIsOpenRenameTagModal(true);
        } else if (selectedItem === contextualMenuIds.DELETE_TAG) {
            setIsOpenDeleteTagModal(true);
        } else if (selectedItem === contextualMenuIds.DELETE_SHARED_TREE) {
            setIsOpenDeleteSharedTreeModal(true);
        } else if (selectedItem === contextualMenuIds.CREATE_TREE_FROM_TAG) {
            setIsOpenCreateTreeFromTagModal(true);
        }
    }

    return (
        <ModalContext.Provider value={{ 
            openTagModalOfType
        }}>
            { children }
            <CreateNewGroupUserModal
                initialFocusRef={initialRef}
                isOpen={isOpenNewGroupUserModal}
                onClose={() => setIsOpenNewGroupUserModal(false)}
                onSubmit={(groupUser) => {
                    setIsOpenNewGroupUserModal(false);
                    saveUser(groupUser)
                }}
            />
            
            <NewTagModal
                initialFocusRef={initialRef}
                isOpen={isOpenNewTagModal}
                onClose={() => setIsOpenNewTagModal(false)}
                onSubmit={(newTagName) => {
                    setIsOpenNewTagModal(false);
                    createNewTag(tag.getId(), tag.getTreeId(), newTagName);
                }}
            />

            <ShareTagModal
                initialFocusRef={initialRef}
                isOpen={isOpenShareTreeModal}
                onClose={() => setIsOpenShareTreeModal(false)}
                onSubmit={(email, permission) => {
                    setIsOpenShareTreeModal(false);
                    shareTree(tag.getId(), tag.getTreeId(), email, permission);
                }}
            />

            <DeleteTagModal
                isOpen={isOpenDeleteTagModal}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpenDeleteTagModal(false)}
                onSubmit={() => {
                    setIsOpenDeleteTagModal(false)
                    deleteTag(tag.getId(), tag.getTreeId())
                }}
            />

            <RenameTagModal
                initialFocusRef={initialRef}
                // initialTagName={tag?.getName()}
                isOpen={isOpenRenameTagModal}
                onClose={() => setIsOpenRenameTagModal(false)}
                onSubmit={(newValue) => {
                    setIsOpenRenameTagModal(false);
                    newValue !== tag.getName() && updateTag(tag.getId(), tag.getTreeId(), newValue);
                }}
            />

            <EditPermissionsModal
                isOpen={isOpenEditPermissionsModal}
                initialFocusRef={initialRef}
                onClose={() => setIsOpenEditPermissionsModal(false)}
                tagShares={tag?.getShares()}
                onSubmit={(email, permission) => {
                    shareTree(tag.getId(), tag.getTreeId(), email, permission);
                }}
            />

            <CreateTreeFromTagModal
                isOpen={isOpenCreateTreeFromTagModal}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpenCreateTreeFromTagModal(false)}
                onSubmit={() => {
                    setIsOpenCreateTreeFromTagModal(false)
                    createTreeFromTag(tag.getTreeId(), tag.getId())
                }}
            />

            <DeleteSharedTreeModal
                isOpen={isOpenDeleteSharedTreeModal}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsOpenDeleteSharedTreeModal(false)}
                onSubmit={() => {
                    setIsOpenDeleteSharedTreeModal(false);
                    deleteSharedTree(tag.getTreeId());
                }}
            />
        </ModalContext.Provider>
    );
}

export function useModal() {
    return React.useContext(ModalContext);
}