import React, { useState } from "react";
import {
    Image,
    Text,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Checkbox,
    Link as ChakraLink,
    Button,
    Box,
    FormControl,
    FormLabel,
} from "@chakra-ui/react";
import { VscEye as EyeIcon, VscEyeClosed as EyeCloseIcon } from "react-icons/vsc";

import AuthService from '../../services/auth.service'
import StoreService from "../../services/store.service";

import {Link, useNavigate} from 'react-router-dom';

import logo from "../../assets/images/tagmac.png";
import {createKeyHandler, keys} from "../../helpers/keyboard.helper";
import {routes} from "../../constants";

const LoginLayout = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const toggleEyeIcon = () => {
        setShowPassword(prev => !prev);
    }

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        check: false
    })

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name === 'password'){
            if (value.includes("…")){
                value = value.replace("…", "...");
            }
        }
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!showLoading) {
            setShowLoading(true);
        }
        try {
            let response = await AuthService.login(formData);
            if (response?.success) {
                let data = response.data;
                setLoginError('')
                if (data?.is_primary)
                    await StoreService.setIsPrimary(parseInt(data.is_primary))
                let url = StoreService.getCurrentUrl();
                if (url && url !== ''){
                    StoreService.setCurrentUrl('')
                    navigate(url);
                }
                else {
                    navigate(routes.APP_ROOT);
                }
            }
            if (!response?.success && response?.message) {
                setLoginError(response?.message);
                setShowLoading(false);
            }
        } catch (error) {
            setShowLoading(false);
        }
    }

    const shouldAllowLoggingIn = !(showLoading || !formData.email || !formData.password);
    const enterKeyHandler = createKeyHandler(keys.ENTER, (e) => {
        if (shouldAllowLoggingIn) {
            handleSubmit(e);
        }
    });

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="center"
            width="sm"
            marginTop={16}
            marginX="auto"
            padding={8}
            shadow="lg"
        >
            <figure>
                <Image src={logo} alt="Tag Machine logo"/>
            </figure>
            <Heading as="h1" fontSize="xl">Log in to your Account</Heading>
            <Text>Welcome back, please enter your details.</Text>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={6}>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Email</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                        />
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                id="loginInput"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                onKeyUp={enterKeyHandler}
                            />
                            <InputRightElement>
                                {showPassword ?
                                    <EyeCloseIcon onClick={toggleEyeIcon}/> :
                                    <EyeIcon onClick={toggleEyeIcon} />}
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    {loginError && <Text color="red">{loginError}</Text>}
                    <Box display="flex" justifyContent="space-between">
                        <FormControl display="flex" gap={2} alignItems="center" width="fit-content">
                            <Checkbox
                                name="check"
                                value={formData.check}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        check: e.target.checked
                                    })
                                }}
                                aria-labelledby="remember-me-label"
                            />
                            <FormLabel id="remember-me-label" fontSize="xs" margin="0" cursor="pointer">Remember me</FormLabel>
                        </FormControl>
                        <ChakraLink as={Link} to="/recovery" color="blue.500" fontSize="xs">Forgot Password?</ChakraLink>
                    </Box>

                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit}
                        isDisabled={!shouldAllowLoggingIn}>
                        {showLoading ? 'Logging in' : 'Log in'}
                    </Button>
                </Box>
            </form>
            <Text>Don’t have an account? <ChakraLink as={Link} to="/signup" color="blue.500">Sign up</ChakraLink>
            </Text>
        </Box>
    );
};

export default LoginLayout;
