import { SendIntent } from "send-intent";
import { intentFactory } from "../models/Intent";
import { platforms } from "../contexts/PlatformContextProvider";

let intentReceivedCallback = () => {};
let intentFinishedCallback = () => {};
let intentActive = false;
let currentPlatform;
    
function handleIntent(result) {
    if (result) {
        let incomingIntent = {};
        try {
            incomingIntent = intentFactory.get({...result});
        } catch (e) {
            console.warn("Intent was empty or was invalid, ignoring checkSendIntentReceived call.");
            return;
        }
        intentActive = true;
        triggerIntentReceivedEvent(incomingIntent);
    } else {
        finishIntent();
    }
}

function handleError(err) {
    if (err.message === 'No processing needed.') {
        console.warn(err.message);
    } else {
        console.error(err.message);
    }
}

export function startListening(platform) {
    currentPlatform = platform;
    SendIntent.checkSendIntentReceived()
        .then(handleIntent)
        .catch(handleError);

    window.addEventListener("sendIntentReceived", () => {
        SendIntent.checkSendIntentReceived()
            .then(handleIntent)
            .catch(handleError);
    });
}
export function onIntentReceived(callbackFunction) {
    intentReceivedCallback = callbackFunction;
}

export function onIntentFinished(callbackFunction) {
    intentFinishedCallback = callbackFunction;
}

export function triggerIntentReceivedEvent(intent) {
    intentReceivedCallback(intent);
}

export function isIntentActive() {
    return intentActive;
}

export function finishIntent() {
    try {
       if (currentPlatform === platforms.ANDROID) {
            SendIntent.finish();
       }
       intentFinishedCallback();
    } catch (e) {
        console.warn(e);
    }
}
