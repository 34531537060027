import TreesService from "../services/trees.service";
import {buildTag} from "../models/Tag";
import TagsService from "../services/tags.service";


export const TagsRepository = {
    save: function save(treeId, parentId, name) {
        return TagsService.post({
            name: name,
            treeId: treeId,
            parentId: parentId
        })
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            const tree = response.data
            return {
                ...tree,
                tags: tree.tags.map(tag => buildTag({
                    ...tag,
                    treeId: tree.treeId,
                    id: tag.tagId,
                }))
            }
        }, (error) => {
            throw error
        })
    },
    get: function get() {
        return TreesService.getTreeTags()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data.map(tree => {
                return {
                    ...tree,
                    tags: tree.tags.map(tag => buildTag({
                        ...tag,
                        treeId: tree.treeId,
                        id: tag.tagId,
                    }))
                }
            });
        })
        .catch((error) => {
            throw error
        })
    },
    search: function search({ query }) {
        if (query) {
            const searchParams = { text: query };
            return TagsService.searchTags(searchParams)
            .then(response => {
                if (!response.success && response.message){
                    throw new Error(response.message);
                }    
                return response.data.map(tag => buildTag({
                    id: tag.tagId,
                    ...tag,
                }));
            })
            .catch((error) => {
                throw error
            })
        }
        else {
            return [];
        }
    },
    move: function move(tag, newParent) {
        return TreesService.moveTag(tag.treeId, tag.id, newParent.treeId, newParent.id)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            } 
            return response.data 
        })
        .catch((error) => {
            throw error
        })
    }
}