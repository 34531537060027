import api from './api.service'

const ENDPOINT = 'api/tree'

const get = () => {
    return api.get(`${ENDPOINT}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getTreeTags = () => {
    return api.get(`${ENDPOINT}/tags`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getTags = (treeId) => {
    return api.get(`${ENDPOINT}/${treeId}/tags`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const post = (data) => {
    return api.post(ENDPOINT, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const setColor = (treeId, color) => {
    return api.post(`${ENDPOINT}/${treeId}/color`, { color: color })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const setPosition = (treeId, position) => {
    return api.post(`${ENDPOINT}/${treeId}/position`, { position: position })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const moveTag = (treeId, tagId, targetTreeId, targetTagId) => {
    return api.post(`${ENDPOINT}/${treeId}/tag/${tagId}/parent`, { treeId: targetTreeId, tagId: targetTagId })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const put = (treeId, tagId, data) => {
    return api.put(`${ENDPOINT}/${treeId}/tag/${tagId}`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const del = (treeId, tagId) => {
    return api.delete(`${ENDPOINT}/${treeId}/tag/${tagId}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getWeblinkTags = (url) => {
    return api.post(`${ENDPOINT}/weblink/tags`,{
        url: url
    })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getNoteTags = (noteId) => {
    return api.post(`${ENDPOINT}/note/tags`,{
        noteId: noteId
    })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const share = (treeId, tagId, email, permission) => {
    return api.get(`${ENDPOINT}/${treeId}/tag/${tagId}/share`, { params: { email , permission } } )
        .then(response => response?.data)
        .catch(error => {throw error})
}
const deleteShare = (treeId) => {
    return api.delete(`${ENDPOINT}/${treeId}/share`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const createTreeFromTag = (treeId, tagId) => {
    return api.get(`${ENDPOINT}/${treeId}/tag/${tagId}/create`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const searchTags = (data) => {
    return api.get(`${ENDPOINT}/search`, {
        params: {...data}
    })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const TreesService = {
    get,
    getTreeTags,
    getTags,
    post,
    setColor,
    setPosition,
    moveTag,
    put,
    del,
    searchTags,
    getWeblinkTags,
    getNoteTags,
    share,
    deleteShare,
    createTreeFromTag
}
export default TreesService;
