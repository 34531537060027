const getJwtToken = () => {
    return Promise.resolve().then(() => localStorage.getItem('token'))
}

const setJwtToken = (token) => {
    return Promise.resolve().then(() => localStorage.setItem("token", token))
}

const setHash = (hash) => {
    return Promise.resolve().then(() => localStorage.setItem('hash', hash))
}
const getHash = (hash) => {
    return Promise.resolve().then(() => localStorage.getItem('hash'))
}

const setKeyValue = (key, value) => {
    return Promise.resolve().then(() => localStorage.setItem(key, value))
}
const getKeyValue = (key) => {
    return localStorage.getItem(key)
}

const getAccountId = () => {
    return localStorage.getItem('accountId')
}
const setAccountId = (accountId) => {
    return Promise.resolve().then(() => localStorage.setItem('accountId', accountId))
}
const getUserId = () => {
    return localStorage.getItem('userId')
}
const setUserId = (userId) => {
    return localStorage.setItem('userId', userId)
}
const getUserEmail = () => {
    return localStorage.getItem('email')
}
const setUserEmail = (userId) => {
    return localStorage.setItem('email', userId)
}
const setIsPrimary = (isPrimary) => {
    return Promise.resolve().then(() => localStorage.setItem('pluto', isPrimary))
}
const getIsPrimary = () => {
    return Promise.resolve().then(() => localStorage.getItem('pluto'))
}
const setIsAdmin = (isAdmin) => {
    return localStorage.setItem('isAdmin', isAdmin)
}
const getIsAdmin = () => {
    return localStorage.getItem('isAdmin')
}
const setIsGroupAccountAdmin = (isAdmin) => {
    return localStorage.setItem('isGroupAccountAdmin', isAdmin)
}
const getIsGroupAccountAdmin = () => {
    return localStorage.getItem('isGroupAccountAdmin')
}
const setIsSubscriptionExpired = (isSubscriptionExpired) => {
    return localStorage.setItem('isSubscriptionExpired', isSubscriptionExpired)
}
const getIsSubscriptionExpired = () => {
    return localStorage.getItem('isSubscriptionExpired')
}
const setCurrentUrl = (url) => {
    return localStorage.setItem('currentUrl', url)
}
const getCurrentUrl = () => {
    return localStorage.getItem('currentUrl')
}

// Clear Storage Data
const clearStorage = () => {
    return Promise.resolve().then(() => localStorage.clear())
}

const clearUserStorage = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('accountId')
    localStorage.removeItem('isAdmin')
    localStorage.removeItem('isSubscriptionExpired')
    return
}

const StoreService = {
    getJwtToken,
    setJwtToken,
    setHash,
    getHash,
    setKeyValue,
    getKeyValue,
    getAccountId,
    setAccountId,
    getUserId,
    setUserId,
    getUserEmail,
    setUserEmail,
    getIsPrimary,
    setIsPrimary,
    getIsAdmin,
    setIsAdmin,
    getIsGroupAccountAdmin,
    setIsGroupAccountAdmin,
    getIsSubscriptionExpired,
    setIsSubscriptionExpired,
    getCurrentUrl,
    setCurrentUrl,
    clearUserStorage,
    clearStorage
}
export default StoreService
