export function buildSharedSubtrees(rootTag, pathToSubtreeRoot = []) {
    let sharedSubtreeRoots = [];
    if (rootTag.getShares().length > 0) {
        return [{ tag: rootTag, isRoot: true, pathToSubtreeRoot }];
    }
    rootTag.getChildren()?.forEach(childTag => {
        const rootTagsDownInTheTree = buildSharedSubtrees(childTag, [...pathToSubtreeRoot, rootTag]);
        sharedSubtreeRoots = sharedSubtreeRoots.concat(rootTagsDownInTheTree);
    });
    return sharedSubtreeRoots;
}