import {useMemo, useState} from "react";

import StoreService from "../../../services/store.service"

export const useSearchResultSelection = function (items) {
    const [selectedItems, setSelectedItems] = useState([]);

    const toggleAllCheckboxes = (source) => {
        setSelectedItems(source.target.checked ? items.filter(i => i.createdBy === StoreService.getUserEmail()) : [])
    }

    const isAnyWebsiteSelected = useMemo(() => {
        return !!selectedItems.filter(item => item.url).length;
    }, [selectedItems]);

    return { selectedItems, setSelectedItems,  toggleAllCheckboxes, isAnyWebsiteSelected };
}