export const keys = {
    ENTER: 'Enter'
}

/**
 * Given a key and a callback, it returns a function that can be used as a callback for
 * any key event. The returned function will be called if the key event corresponds to
 * the passed key.
 * @param key The key to which the callback should respond.
 * @param callback The function to be called if the event corresponds to key.
 * @returns {(function(*): void)|*}
 */
export function createKeyHandler(key, callback = () => {}) {
    return function(event) {
        if (event.key === key) {
            callback(event);
        }
    }
}