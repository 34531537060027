import React from 'react'
import moment from 'moment'

import {
    Card as ChakraCard,
    Checkbox,
    Flex,
    Box,
    Heading,
    UnorderedList,
    ListItem,
    Icon,
    Tooltip,
    useDisclosure,
    Collapse,
    Spinner,
    AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, AlertDialog,
    Switch
} from "@chakra-ui/react";
import {
    VscGraphLine as ChartIcon,
    VscPlay as PlayIcon,
    VscDebugPause as PauseIcon,
    VscTrash as DeleteIcon,
    VscInfo as InfoIcon,
} from "react-icons/vsc";

import UserService from "../../services/user.service";

import { useUser } from "../../contexts/UserContextProvider";
import {MetaDataText} from "../metadata_text";
import {ActivityChart} from "../activity-chart";

export const Member = ({ userData, onSelect, selected }) => {

    const { updateUser, deleteUsers, setUserAdmin  } = useUser();
    const [user, setUser] = React.useState(userData)
    const [isChartLoading, setIsChartLoading] = React.useState(false);
    const cancelRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const chartDisclosure = useDisclosure();
    const isChartOpen = chartDisclosure.isOpen;
    const onChartToggle = chartDisclosure.onToggle;
    const infoDisclosure = useDisclosure();
    const isInfoOpen = infoDisclosure.isOpen;
    const onInfoToggle = infoDisclosure.onToggle;

    const [ activityData, setActivityData ] = React.useState(null)

    function requestChart() {
        if (!isChartOpen && !activityData) {
            setIsChartLoading(true);
            UserService.getUserStatistics(user.userId).then(response => {
                setActivityData(response.data)
                setIsChartLoading(false);
            });
        }
        onChartToggle();
    }

    return (
        <>
            <ChakraCard
                width="100%"
                marginY={2}
                padding={2}
                gap={2}
                shadow="md"
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Flex
                        alignItems="center"
                        gap={4}
                    >
                        <Box width={4}>
                            <Checkbox
                                isChecked={selected}
                                onChange={(e) => {
                                    onSelect(user.userId, e.target.checked)
                                }}
                            />
                        </Box>
                        <Box>
                            <Heading as="h2" fontSize="xl">{user.firstName} {user.lastName}</Heading>
                            <MetaDataText>{user.email}</MetaDataText>
                        </Box>
                    </Flex>
                    <Flex gap={4}>
                        <Tooltip label="Extended user information">
                            <span>
                                <Icon
                                    as={InfoIcon}
                                    onClick={onInfoToggle}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip label="Toggle activity chart">
                            <span>
                                {isChartLoading ?
                                    <Spinner size='sm' />
                                    :
                                    <Icon
                                        as={ChartIcon}
                                        onClick={requestChart}
                                    />
                                }
                            </span>
                        </Tooltip>
                        {user.active === true ?
                            <Tooltip label="Deactivate user">
                                <span>
                                    <Icon
                                        as={PauseIcon}
                                        onClick={() => {
                                            updateUser({ ...user, active: false })
                                            .then((u) => {
                                                setUser(u)
                                            })
                                        }}
                                    />
                                </span>
                            </Tooltip>
                            :
                            <Tooltip label="Activate user">
                                <span>
                                    <Icon
                                        as={PlayIcon}
                                        onClick={() => {
                                            updateUser({ ...user, active: true })
                                            .then((u) => {
                                                setUser(u)
                                            })
                                        }}
                                    />
                                </span>
                            </Tooltip>
                        }
                        <Tooltip label="Delete user">
                            <span>
                                <Icon as={DeleteIcon} onClick={onOpen}/>
                            </span>
                        </Tooltip>
                    </Flex>
                </Flex>
                <Collapse in={isChartOpen} animateOpacity>
                    <ActivityChart activityData={activityData} heightInPixels={200} />
                </Collapse>
                <Collapse in={isInfoOpen} animateOpacity>
                    <Box>
                        <UnorderedList
                            listStyleType="none"
                        >
                            <ListItem>
                                <MetaDataText>Admin</MetaDataText>
                                <Switch id='is-admin' 
                                    isChecked={user.isAdmin === true}
                                    onChange={(e) => {
                                        setUserAdmin(user.userId, e.target.checked)
                                        .then((u) => {
                                            setUser(u)
                                        })
                                    }}
                                />
                            </ListItem>
                            <ListItem><strong>Phone:</strong> {user.phone}</ListItem>
                            <ListItem><strong>Birth date:</strong> {user.dob}</ListItem>
                            <ListItem><strong>Country:</strong> {user.country}</ListItem>
                            <ListItem><strong>Created at:</strong> {moment(user.createdAt * 1000).format("YYYY-MM-DD HH:mm:ss")}</ListItem>
                        </UnorderedList>
                    </Box>
                </Collapse>
            </ChakraCard>
            <AlertDialog
                isOpen={isOpen}
                aria-labelledby="form-dialog-title"
                onClose={() => {
                    onClose();
                }}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader id="form-dialog-title">Are you sure?</AlertDialogHeader>
                        <AlertDialogBody>
                            This action will remove the selected user and all the data.
                        </AlertDialogBody>
                        <AlertDialogFooter gap={4}>
                            <Button
                                colorScheme="red"
                                onClick={() => {
                                    onClose();
                                    deleteUsers([user.userId])
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={onClose}
                                ref={cancelRef}
                            >
                                No
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
export default Member