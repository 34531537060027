import React from 'react';

import {
    Box, 
    Checkbox,
    Flex,
    IconButton,
    Select
} from "@chakra-ui/react";

import {
    VscSaveAs as SaveIcon
} from "react-icons/vsc";

import { useTagBackgroundColor, useTagTextColor } from "../../styles/tagStyles";

const UserPermissionManagement = (props) => {

    const defaultTagBackgroundColor = useTagBackgroundColor();
    const tagTextColor = useTagTextColor();
    const tagBackgroundColor = defaultTagBackgroundColor;

    const [ user, setUser ] = React.useState(props.user)
    const [ permission, setPermission ] = React.useState(props.permission)
    const [ isChecked, setIsChecked ] = React.useState(props.isChecked)

    React.useEffect(() => {
        setPermission(props.permission)
    }, [ props.permission ])

    React.useEffect(() => {
        setIsChecked(props.isChecked)
    }, [ props.isChecked ])

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            minWidth="350px"
            height={10}
            gap={2}
            padding={2}
            shadow="md"
            backgroundColor={tagBackgroundColor}
            borderRadius={4}
            color={tagTextColor}
        >
            <Flex>
                <Checkbox
                    id={`cb-${user.userId}`}
                    value={`cbv-${user.userId}`}
                    onChange={(e) => {
                        setIsChecked(e.target.checked)
                        e.stopPropagation();
                    }}
                    isChecked={isChecked}
                    borderColor={tagTextColor}
                />
            </Flex>
            <Flex
                gap={2}
                id={`tbm-${user.userId}}`}
            >
                <Box width={4} textAlign="center" />
                {user.firstName} {user.lastName} ({user.email})
            </Flex>
            <Flex>
                <Select
                    variant="ghost"
                    name="Share permissions"
                    value={permission}
                    onChange={(e) => {
                        setPermission(e.target.value)
                        e.stopPropagation();
                    }}
                >
                    <option value="read">Read</option>
                    <option value="write">Read/Write</option>
                    <option value="admin">Admin</option>
                </Select>
            </Flex>
            <IconButton
                icon={<SaveIcon />}
                variant="outline"
                // borderColor={inputBorderColor}
                aria-label="Save"
                onClick={() => {
                    props.onChange(isChecked, permission || 'read')
                }}
            />
        </Box>
    )
}

export default UserPermissionManagement