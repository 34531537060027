import React from 'react';
import { VscTrash as DeleteIcon } from "react-icons/vsc";
import {Button, Select, Flex, IconButton, useColorModeValue} from "@chakra-ui/react";

const UserPermission = ({email, sharePermission, onSave }) => {
    const inputBorderColor = useColorModeValue("gray.500", null);

    const [permission, setPermission] = React.useState(sharePermission);

    return (
        <Flex gap={4} padding='1%' alignItems='center'>
            {email}
            <Select
                name="Share permissions"
                value={permission}
                onChange={(event) => {
                    setPermission(event.target.value);
                }}
            >
                <option value="read">Read</option>
                <option value="write">Read/Write</option>
            </Select>
            <IconButton
                icon={<DeleteIcon />}
                variant="outline"
                borderColor={inputBorderColor}
                aria-label="Remove permission"
                onClick={() => onSave(email, "remove")} />
            <Button
                colorScheme="blue"
                variant="outline"
                onClick={() => onSave(email, permission)}
            >
                Save
            </Button>
        </Flex>
    )
}

export default UserPermission