// TODO Is it OK to import a model from another model?
import { buildTag } from "./Tag";

class Tree {

    // TODO Currently this model stores the trees as a plain array. Instead of that, create a real tree to ease the
    //  rendering of the UI, while keeping the flat array to ease the needed operations that benefit from that. This
    //  will be transparent to the outside
    constructor({ id, tags = [], color = null, position, name, permission, rootTagId }) {
        this.id = id;
        this.tags = tags;
        this.color = color;
        this.position = position;
        this.permission = permission;
        this.name = name
        this.rootTagId = rootTagId
    }

    getId() {
        return this.id;
    }

    getRootTagId() {
        return this.rootTagId;
    }

    getTags() {
        return this.tags;
    }

    setTags(tags) {
        this.tags = tags;
    }

    getColor() {
        return this.color;
    }

    setColor(color) {
        this.color = color;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getPosition() {
        return this.position;
    }

    setPosition(position) {
        this.position = position;
    }

    getPermission() {
        return this.permission;
    }

    setPermission(permission) {
        this.permission = permission;
    }

    unselectAllTags() {
        this.tags.forEach(tag => tag.setIsChecked(false));
        // The following is not needed to uncheck all tags, but in order to create a new object
        //  we are recreating it with the mutated objects
        this.setTags([
            ...this.tags,
        ]);
    }

    getSelectedTags() {
        return this.tags.filter(tag => tag.isChecked());
    }

    // TODO Consider making this operation once at object initialization and just return it if needed
    getTagsAsTree() {
        const tags = this.getTags();
        let intermediateTreeStructure = {};
        tags.forEach(tag => {
            const tagId = tag.getId();
            tag.setChildren(intermediateTreeStructure[tagId]?.getChildren() || []);
            intermediateTreeStructure[tagId] = tag;
            const parentTagId = tag.getParentId();
            if (parentTagId) {
                if (!intermediateTreeStructure[parentTagId]) {
                    intermediateTreeStructure[parentTagId] = buildTag({
                        children: [],
                    });
                }
                intermediateTreeStructure[parentTagId].addChild(tag);
            }
        });
        return Object.values(intermediateTreeStructure).find(tag => !tag.getParentId());
    }

    getPathToTag(tag) {
        const path = [];
        let currentTag = tag;
        do {
            path.push(currentTag);
            currentTag = this.tags.find(tag => tag.getId() === currentTag.getParentId());
        } while (currentTag?.getParentId());
        if (currentTag) {
            // Add the last tag in the path, which doesn't have a parent and was not added in the while
            path.push(currentTag);
        }
        path.reverse();
        return path;
    }

    insertTagAfter(tag, newPreviousSiblingTag) {
        const newParentTagId = newPreviousSiblingTag.getParentId();
        tag.setParentId(newParentTagId);
        const positionOfInsertedTag = this.tags.findIndex(t => t.getId() === newPreviousSiblingTag.getId()) + 1;
        const newListOfTags = this.tags.toSpliced(positionOfInsertedTag, 0, tag);
        this.setTags([...newListOfTags]);
    }

    insertTagAsChild(tag, newParentTag) {
        const newParentTagId = newParentTag.getId();
        tag.setParentId(newParentTagId);
        this.addTag(tag);
    }

    addTag(tag) {
        this.tags.push(tag);
    }

    removeTag(tag) {
        const positionOfTag = this.tags.findIndex(t => t.getId() === tag.getId());
        const newListOfTags = this.tags.toSpliced(positionOfTag, 1);
        this.setTags([...newListOfTags]);
    }

}

export function buildTree({ id, tags = [], color = null, position, name, permission, rootTagId }) {
    return new Tree({ id, tags, color, position, name, permission, rootTagId });
}
