import React, {useEffect, useRef, useState} from 'react'
import debounce from "lodash.debounce";
import moment from 'moment'
import {
    Box,
    Checkbox,
    Text,
    Button,
    Flex,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Image,
    useDisclosure,
    useColorModeValue,
} from "@chakra-ui/react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import { Filters } from "../../components/filters"
import {Forest} from "../../components/forest";
import { SearchItemList } from "../../components/search_item_list";

import { useForest } from "../../contexts/ForestContextProvider";
import { useSearchItem } from "../../contexts/SearchItemContextProvider";
import { Topbar } from "../../components/topbar";
import WebsiteService from "../../services/websites.service";
import NoteService from "../../services/notes.service";
import {
    appTopBarHeight,
    filtersHeight,
    searchResultsHeaderHeight,
    searchResultsTopbarHeight
} from "../../styles/layoutStyles";
import {DashboardTopbar} from "./components";
import {useSearchLayoutConfig} from "./hooks/useSearchLayoutConfig";
import {useSearchResultSelection} from "./hooks/useSearchResultSelection";
import {getLocalPreference, saveLocalPreference} from "../../helpers/storage";

const SKIP_OPEN_PERMISSION_DIALOG = "skip-open-permission-dialog";

const initialFilterFormData = {
    searchBy: [],
    text: "",
    fromDate: '',
    toDate: '',
    tagIds: []
};

const DashboardLayout = () => {

    const filtersBackgroundColor = useColorModeValue("blue.50", "blue.800");
    const searchListTopBarBg = useColorModeValue("white", "gray.800");
    const tabOpenPermissionIconSrc = useColorModeValue("/multi-tab-open-permission-icon-light.png", "/multi-tab-open-permission-icon-dark.png");
    const { items, searchItems, exportItems, itemsCount, itemsPage, itemsLimit, isLoading } = useSearchItem();
    const { forest } = useForest();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [text, setText] = useState('');
    // FIXME This piece of state is redundant with startDate, endDate and text above. Probably the best is to remove the
    //  above ones and keep only this
    const [filterFormData, setFilterFormData] = useState(initialFilterFormData);
    const filterFormDataRef = React.useRef(filterFormData);

    const [currentResultsPage, setCurrentResultsPage] = useState(0);

    const { selectedItems, setSelectedItems, toggleAllCheckboxes, isAnyWebsiteSelected } = useSearchResultSelection(items);
    const { showLimitedInfo, toggleEssentialInformation, isListLayout, toggleGrid } = useSearchLayoutConfig();

    const firstShownItem = (itemsPage * itemsLimit) + 1;
    const lastShownItem = Math.min(firstShownItem + itemsLimit - 1, itemsCount);

    const debouncedChangeFilterFormData = useRef(debounce(changeFilterFormData, 1000, { leading: false, trailing: true }));
    const cancelRef = React.useRef();
    const [skipOpenPermissionDialog, setSkipOpenPermissionDialog] = useState(false);

    const { isOpen : isOpenOpenSelectedItems, onOpen: onOpenOpenSelectedItems, onClose: onCloseOpenSelectedItems } = useDisclosure()
    const { isOpen : isOpenUpdateSelectedItems, onOpen: onOpenUpdateSelectedItems, onClose: onCloseUpdateSelectedItems } = useDisclosure()

    // FIXME This useEffect is a code smell (a mess, in other words). We should not need to do this black magic in order
    //  to change the current search criteria. Summary:
    //  - We have a Ref that mimics the filterFormData because the identical state object is not reachable from the
    //  debounced function.
    useEffect(() => {
        const uniqueSelectedTagIds = forest.getSelectedTagIds();
        filterFormDataRef.current = {
            ...filterFormData,
            tagIds: uniqueSelectedTagIds,
        }
        searchItems(filterFormDataRef.current, currentResultsPage);
    }, [filterFormData, currentResultsPage]);

    function changeFilterFormData(term) {
        if (term.length > 2 || term.length === 0) {
            onFilterFormChange({
                ...filterFormDataRef.current,
                text: term
            });
        }
    }

    async function clearFiltersHandler() {
        setText(initialFilterFormData.text);
        setStartDate(initialFilterFormData.fromDate);
        setEndDate(initialFilterFormData.toDate);
        setFilterFormData(initialFilterFormData);
    }

    function showUpdateHelpMessage() {
        const userRequestedToSkipDialog = getLocalPreference(SKIP_OPEN_PERMISSION_DIALOG);
        if (userRequestedToSkipDialog === 'true') {
            updateSelectedItems();
        } else {
            onOpenUpdateSelectedItems();
        }
    }

    function updateSelectedItems() {
        selectedItems
            .forEach(item => {
                if (item.isWebsite()){
                    window.open(`${window.location.protocol}//${window.location.host}/tag/website?website_id=${item.getId()}`, "_blank");
                }
                if (item.isNote()){
                    window.open(`${window.location.protocol}//${window.location.host}/tag/note?note_id=${item.getId()}`, "_blank");
                }
            });
        setSelectedItems([]);
    }

    function showOpenHelpMessage() {
        const userRequestedToSkipDialog = getLocalPreference(SKIP_OPEN_PERMISSION_DIALOG);
        if (userRequestedToSkipDialog === 'true') {
            openSelectedItems();
        } else {
            onOpenOpenSelectedItems();
        }
    }

    function openSelectedItems() {
        selectedItems
            .filter(item => item.url)
            .forEach(item => {
                window.open(item.url, "_blank");
            });
        setSelectedItems([]);
    }

    function deleteSelectedItems () {
        let deletePromises = selectedItems.map((item) => {
            if (item.isWebsite()) {
                return WebsiteService.del(item.getId());
            } else {
                return NoteService.del(item.getId());
            }
        })
        Promise.all(deletePromises).then(() => {
            clearFiltersHandler();
            setSelectedItems([]);
        })
    }

    function toggleDontShowAgain() {
        setSkipOpenPermissionDialog(!skipOpenPermissionDialog);
    }

    function onUnderstoodButtonPressed() {
        onCloseOpenSelectedItems();
        onCloseUpdateSelectedItems();
        
        saveLocalPreference(SKIP_OPEN_PERMISSION_DIALOG, skipOpenPermissionDialog);
        
        isOpenOpenSelectedItems && openSelectedItems();
        isOpenUpdateSelectedItems &&  updateSelectedItems();
    }

    function resultsPageChanged(newPage) {
        setCurrentResultsPage(newPage - 1);
    }

    function onFilterFormChange(filterData) {
        setFilterFormData(filterData)
        setCurrentResultsPage(0)
    }

    return (
        <>
            <Box zIndex="dropdown">
                <Topbar />
            </Box>
            <PanelGroup direction="horizontal" autoSaveId="dashboard-resizable-panels">
                <Panel defaultSize={25} minSize={20} maxSize={50}>
                    <Forest.Root
                        key="forest"
                        onSelectedTreeTags={(selectedTreeTags) => {
                            let selectedTags = Object.keys(selectedTreeTags).flatMap(d => {
                                return selectedTreeTags[d]
                            }).map(d => d.getId());
                            onFilterFormChange({
                                ...filterFormData,
                                tagIds: selectedTags
                            })
                        }}
                    >
                        <Forest.SearchBar />
                        <Forest.TitleBar title="Trees" />
                        <Forest.Trees propagateSelection={false} />
                    </Forest.Root>
                </Panel>
                <PanelResizeHandle>
                    <Box width={2} height="100%" backgroundColor="gray.300"/>
                </PanelResizeHandle>
                <Panel defaultSize={75}>
                    <Flex height={`calc(100vh - ${appTopBarHeight})`} overflowY="hidden" flexDirection="column">
                        <Box id="search-results-header" height={searchResultsHeaderHeight}>
                            <Box
                                display="flex"
                                flexDirection={{ base: "column", sm: "row" }}
                                justifyContent="flex-start"
                                gap={4}
                                padding={8}
                                borderBottom="1px solid"
                                borderColor="gray.200"
                                backgroundColor={filtersBackgroundColor}
                                alignItems="end"
                                height={{ base: "default", sm: filtersHeight }}
                            >
                                <Filters
                                    text={text}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onTextChange={(term) => {
                                        setText(term);
                                        debouncedChangeFilterFormData.current(term);
                                    }}
                                    onStartDateChange={(date) => {
                                        setStartDate(date);
                                        if (date){
                                            let formattedDate = moment(date).format("YYYY-MM-DD")
                                            onFilterFormChange({
                                                ...filterFormData,
                                                fromDate: formattedDate
                                            })
                                        }
                                        else {
                                            onFilterFormChange({
                                                ...filterFormData,
                                                fromDate: date
                                            })
                                        }
                                    }}
                                    onEndDateChange={(date) => {
                                        setEndDate(date);
                                        if (date){
                                            let formattedDate = moment(date).format("YYYY-MM-DD")
                                            onFilterFormChange({
                                                ...filterFormData,
                                                toDate: formattedDate
                                            })
                                        }
                                        else {
                                            onFilterFormChange({
                                                ...filterFormData,
                                                toDate: date
                                            })
                                        }
                                    }}
                                    onClearFilters={() => {
                                        setStartDate(null)
                                        setEndDate(null)
                                        setText('')
                                        onFilterFormChange({
                                            ...filterFormData,
                                            text: "",
                                            fromDate: "",
                                            toDate: ""
                                        })
                                    }}
                                />
                            </Box>
                            <Box
                                display="flex"
                                gap={4}
                                paddingX={4}
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="gray.300"
                                height={searchResultsTopbarHeight}
                                backgroundColor={searchListTopBarBg}
                            >
                                {!isLoading &&
                                    <DashboardTopbar
                                        filterFormData={filterFormData}
                                        isListLayout={isListLayout}
                                        firstShownItem={firstShownItem}
                                        lastShownItem={lastShownItem}
                                        itemsCount={itemsCount}
                                        selectedItems={selectedItems}
                                        isAnyWebsiteSelected={isAnyWebsiteSelected}
                                        onToggleAllCheckboxes={toggleAllCheckboxes}
                                        onToggleEssentialInformation={toggleEssentialInformation}
                                        onOpenWebpageClicked={showOpenHelpMessage}
                                        onUpdateWebpageClicked={showUpdateHelpMessage}
                                        onExportItems={exportItems}
                                        onToggleGrid={toggleGrid}
                                        deleteSelectedItems={deleteSelectedItems}
                                        showOpenHelpMessage={showOpenHelpMessage}
                                        showLimitedInfo={showLimitedInfo}
                                    />
                                }
                            </Box>
                        </Box>
                        <Box height="inherit" overflowY="scroll">
                            <SearchItemList
                                items={items}
                                searchItems={searchItems}
                                exportItems={exportItems}
                                itemsCount={itemsCount}
                                itemsPage={itemsPage}
                                itemsLimit={itemsLimit}
                                isLoading={isLoading}
                                filterFormData={filterFormData}
                                isListLayout={isListLayout}
                                showLimitedInfo={showLimitedInfo}
                                clearFiltersHandler={clearFiltersHandler}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                resultsPage={currentResultsPage}
                                onResultsPageChanged={resultsPageChanged}
                            />
                        </Box>
                    </Flex>
                </Panel>
            </PanelGroup>
            <AlertDialog
                isOpen={isOpenOpenSelectedItems | isOpenUpdateSelectedItems}
                aria-labelledby="form-dialog-title"
                onClose={() => {
                    onCloseOpenSelectedItems();
                    onCloseUpdateSelectedItems();
                }}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader id="form-dialog-title">Tab open permission</AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>If you are trying to open several pages at once, the browser could block the attempt.</Text>
                            <br/>
                            <Text>Click on the icon shown below to configure the permission:</Text>
                            <Image src={tabOpenPermissionIconSrc} alt="Icon to edit tab open permissions" margin="auto" />
                        </AlertDialogBody>
                        <AlertDialogFooter gap={4} justifyContent="space-between">
                            <Flex gap={2}>
                                <Checkbox
                                    aria-labelledby="dont-show-again-label"
                                    isChecked={skipOpenPermissionDialog}
                                    onChange={toggleDontShowAgain}
                                />
                                <Text
                                    id="dont-show-again-label"
                                    cursor="pointer"
                                    onClick={toggleDontShowAgain}
                                >
                                    Don't show again
                                </Text>
                            </Flex>
                            <Button
                                onClick={onUnderstoodButtonPressed}
                                ref={cancelRef}
                            >
                                Understood
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
};

export default DashboardLayout;
