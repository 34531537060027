// TODO There is opportunity to create models for Websites and Notes here
export function getElementBeingTagged(queryParams) {
    const url = queryParams.get("url");
    if (url) {
        return {
            url,
            title: queryParams.get("title"),
        };
    } else {
        return {
            description: queryParams.get("description"),
        };
    }
}

export function isElementBeingTaggedAWebsite(element) {
    return !!element.url;
}