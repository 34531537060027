import WebsitesService from "../services/websites.service";


export const WebpageDataRepository = {
    get: async function get() {
        const queryParams = new URLSearchParams(window.location.search);

        const url = queryParams.get("url");
        const title = queryParams.get("title");
        if (url && title){
            const favicon = "https://www.google.com/s2/favicons?domain=" + url;
            try {
                const response = await WebsitesService.getWeblinkWebsite(url);
                const website = response.data;
                if (website && Object.keys(website).length > 0){
                    // TODO Can't we combine both returns into one?
                    return {
                        ...website,
                        favicon: favicon
                    };
                } else {
                    return {
                        title: title,
                        url: url,
                        description: title,
                        favicon: favicon,
                    };
                }
            } catch (e) {
                // FIXME throw a proper exception
                throw new Error(e.message);
            }
        }
    },
    getById: async function getById(websiteId) {
        const response = await WebsitesService.getById(websiteId);
        if (!response.success && response.message){
            throw new Error(response.message);
        }
        const website = response.data;
        if (website && Object.keys(website).length > 0){
            // TODO Can't we combine both returns into one?
            return {
                ...website,
                favicon: "https://www.google.com/s2/favicons?domain=" + website.url
            };
        } else {
            return {
                title: '',
                url: '',
                description: '',
                favicon: '',
            };
        }
    }
}