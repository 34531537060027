import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";

const linkStyles = {
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    minWidth: "3rem",
    minHeight: "3rem",
    _hover: {
        textDecoration: "none",
        color: "gray.500",
    }
};
export const TopbarLink = function(props) {
    return <ChakraLink as={Link} to={props.href} {...linkStyles}>{props.children}</ChakraLink>
}