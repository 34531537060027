import React, {useMemo} from 'react'
import {Pagination} from "../pagination";

import {
    Box,
    Text,
    useToast,
} from "@chakra-ui/react";
import {ListItem} from "../list-item";
import WebsitesService from "../../services/websites.service";
import NotesService from "../../services/notes.service";
import {GridItem} from "../grid-item";
import {LoadingState} from "./LoadingState";
import {buildTaggedItem} from "../../models/TaggedItem";

function getEssentialInformation({id, title, tags, url}) {
    return {id, title, tags, url};
}

const SearchItemList = React.memo(function SearchItemList({
                                                              items,
                                                              itemsCount,
                                                              itemsLimit,
                                                              isLoading,
                                                              clearFiltersHandler,
                                                              isListLayout,
                                                              showLimitedInfo,
                                                              selectedItems,
                                                              setSelectedItems,
                                                              resultsPage,
                                                              onResultsPageChanged,
                                                          }) {
    const toast = useToast();

    const updateItem = (item) => {
        if (item.isWebsite()) {
            updateWebsite(item);
        } else {
            updateNote(item);
        }
    }

    const updateWebsite = (website) => {
        WebsitesService.put(website.getId(), website)
            .catch(error => (
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            )
        );
    }

    const updateNote = (note) => {
        NotesService.put(note.getId(), note)
            .catch(error => (
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            )
        );
    }

    const displayedItems = useMemo(() => {
        if (showLimitedInfo) {
            return items.map((item) => buildTaggedItem(getEssentialInformation(item)));
        }
        return items;
    }, [items, showLimitedInfo]);

    if (isLoading) {
        return <LoadingState isListLayout={isListLayout} />;
    }

    return (
        <Box display="flex" flexGrow="2" flexDirection="column">
            {displayedItems.length === 0 ? (
                <Text margin={24} textAlign="center" color="red">
                    No results found
                </Text>) : (
                <>
                    {isListLayout ? (
                        displayedItems.map((item) => (
                            <ListItem
                                key={item.getId()}
                                item={item}
                                selected={selectedItems.findIndex(it => it.getId() === item.getId()) > -1}
                                onSelected={(it, selected) => {
                                    if (selected) {
                                        setSelectedItems([...selectedItems, it])
                                    } else {
                                        setSelectedItems([...selectedItems.filter(itt => itt.getId() !== it.getId())]);
                                    }

                                }}
                                onSave={updateItem}
                                onDeleted={() => clearFiltersHandler()}
                            />
                        ))
                    ) : (
                        <Box display="flex" gap={4} padding={4} flexWrap="wrap">
                            {displayedItems.map((item) => (
                                <GridItem
                                    key={item.getId()}
                                    item={item}
                                    selected={selectedItems.findIndex(it => it.getId() === item.getId()) > -1}
                                    onSelected={(it, selected) => {
                                        if (selected) {
                                            setSelectedItems([...selectedItems, it])
                                        } else {
                                            setSelectedItems([...selectedItems.filter(itt => itt.getId() !== it.getId())]);
                                        }

                                    }}
                                    onSave={updateItem}
                                    onDeleted={() => clearFiltersHandler()}
                                />
                            ))}
                        </Box>
                    )}

                    {itemsCount > displayedItems.length && (
                        <Box display="flex" justifyContent="center" padding={4}>
                            <Pagination
                                currentPage={resultsPage + 1}
                                amountOfPages={Math.ceil(itemsCount / itemsLimit)}
                                onChange={onResultsPageChanged}
                            />
                        </Box>
                    )}
                </>
            )
            }
        </Box>
    )
});

export {SearchItemList};
