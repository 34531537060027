import React, {useEffect, useRef, useState} from "react";

import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Icon,
} from "@chakra-ui/react";

import { createKeyHandler, keys } from "../../helpers/keyboard.helper";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteGroup,
    AutoCompleteGroupTitle,
} from "@choc-ui/chakra-autocomplete";
import { VscTag as TagIcon } from "react-icons/vsc";
import debounce from "lodash.debounce";
import TagsService from "../../services/tags.service";

export function RenameTagModal({ initialTagName, isOpen, onClose, onSubmit, initialFocusRef }) {
    
    const [tagName, setTagName] = useState(initialTagName);
    const [autocompleteTags, setAutocompleteTags] = useState([]);
    const [autocompleteWords, setAutocompleteWords] = useState([]);
    const autocompleteElementRef = useRef();

    const enterKeyHandler = createKeyHandler(keys.ENTER, (e) => submit(e.target.value));
    const debouncedAutocompleteSuggestions = useRef(debounce(retrieveAutocompleteSuggestions, 1000, { leading: false, trailing: true }));

    function tagNameChanged(value) {
        setTagName(value);
        debouncedAutocompleteSuggestions?.current(value);
    }
    async function retrieveAutocompleteSuggestions(value) {
        if (value) {
            const parallelCalls = [
                TagsService.searchWords({ text: value }),
                TagsService.searchTags({ text: value })
            ];
            // TODO Create an endpoint that does the following in a single call to the backend
            //  - Retrieve the matching tags
            //  - Retrieve the matching words
            //  - Transform the objects to have the same anatomy
            //  - Avoid duplicates
            const [{data: suggestedWords}, {data: suggestedTags}] = await Promise.all(parallelCalls);
            const autocompleteTags = suggestedTags.map(({ name }) => name);
            const autocompleteWords = suggestedWords.map(({ word }) => word);
            setAutocompleteTags([...new Set(autocompleteTags)]);
            setAutocompleteWords([...new Set(autocompleteWords)]);
        }
    }

    useEffect(() => {
        async function getAutocompleteItems() {
            const autocompleteData = await debouncedAutocompleteSuggestions?.current(tagName);
            !!autocompleteData?.autocompleteTags && setAutocompleteTags(autocompleteData?.autocompleteTags);
            !!autocompleteData?.autocompleteWords && setAutocompleteWords(autocompleteData?.autocompleteWords);
        }
        getAutocompleteItems();
    }, [tagName]);
    
    async function autocompleteItemSelected({ item }) {
        submit(item.value);
        autocompleteElementRef.current?.resetItems();
    }

    function onModalClose() {
        resetFields();
        onClose?.();
    }

    function resetFields() {
        setTagName("");
        setAutocompleteTags([]);
        setAutocompleteWords([]);
    }

    const submit = (tagName => {
        if (isValidTagName(tagName)){
            if (onSubmit){
                onSubmit(tagName)
            }
        }
    })

    const isValidTagName = (tagName) => {
        return tagName && tagName.trim() !== ""
    }

    return (
        <Modal
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onModalClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Rename tag</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl isInvalid={!isValidTagName(tagName)}>
                        <FormLabel>Tag name</FormLabel>
                        <AutoComplete
                            onSelectOption={autocompleteItemSelected}
                            ref={autocompleteElementRef}
                            emptyState={false}
                        >
                            <AutoCompleteInput
                                type="text"
                                value={tagName}
                                ref={initialFocusRef}
                                onChange={(e) => tagNameChanged(e.target.value)}
                                onKeyUp={enterKeyHandler}
                                placeholder="Name"
                            />
                            {(autocompleteTags.length > 0 || autocompleteWords.length > 0) &&
                                <AutoCompleteList>
                                    {autocompleteTags.length > 0 &&
                                        <AutoCompleteGroup showDivider>
                                            <AutoCompleteGroupTitle textTransform="capitalize">
                                                Suggestions from your tags
                                            </AutoCompleteGroupTitle>
                                            {autocompleteTags.map(name => (
                                                <AutoCompleteItem
                                                    key={`option-${name}`}
                                                    value={name}
                                                    textTransform="capitalize"
                                                    display="flex"
                                                    gap={2}
                                                    alignItems="center"
                                                >
                                                    <Icon as={TagIcon} />
                                                    {name}
                                                </AutoCompleteItem>
                                            ))}
                                        </AutoCompleteGroup>
                                    }
                                    {autocompleteWords.length > 0 &&
                                        <AutoCompleteGroup showDivider>
                                            <AutoCompleteGroupTitle textTransform="capitalize">
                                                Other suggestions
                                            </AutoCompleteGroupTitle>
                                            {autocompleteWords.map(name => (
                                                <AutoCompleteItem
                                                    key={`option-${name}`}
                                                    value={name}
                                                    textTransform="capitalize"
                                                    display="flex"
                                                    gap={2}
                                                    alignItems="center"
                                                    paddingLeft="14px"
                                                >
                                                    {name}
                                                </AutoCompleteItem>
                                            ))}
                                        </AutoCompleteGroup>
                                    }
                                </AutoCompleteList>}
                        </AutoComplete>
                        { (!isValidTagName(tagName)) && 
                            <FormErrorMessage>Tag name is required.</FormErrorMessage>
                        }
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        mr={3}
                        onClick={() => submit(tagName)}
                    >
                        Rename
                    </Button>
                    <Button onClick={onModalClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}