import { TagsRepository } from "../repositories/TagsRepository";
import { buildSharedSubtrees } from "../helpers/shared-filter.helper";

function findLocalVersionOfTag(tag, localTags) {
    return localTags.find(localTag => localTag.getId() === tag.getId() && localTag.getTreeId() === tag.getTreeId());
}

export const SearchTagsApplication = {
    /**
     * Performs a tag search based on the received query. Given that results from backend don't include the information
     * of what tags are checked locally, we need to extend them. For that, once results are obtained, the localTags are
     * used to find the local version of the found tags, which are returned to the caller.
     * @param query A string formatted query to match tag names
     * @param localTags A list of tags present locally, which contain the checked status
     * @returns {Promise<any[]|*[]>}
     */
    searchTags: function searchTags({ query, localTags }) {
        if (query) {
            return TagsRepository.search({ query })
            .then(data => {
                return data.map(tag => {
                    return findLocalVersionOfTag(tag, localTags);
                }).filter(tag => tag);
            })
            .catch(e => {
                return []
            })
        }
        return [];
    },
    /**
     * Gets a forest, which is a list of trees and returns a new set of trees containing only the tags that are shared.
     * The returned trees are a subset of the trees whose roots are the first shared element on each tree.
     * @param forest
     * @returns {{isRoot: boolean, pathToSubtreeRoot: [], tag: *}[]}
     */
    findSharedSubtrees: function findSharedSubtrees(forest) {
        const treeList = forest.getTrees();
        const trees = treeList.map(tree => tree.getTagsAsTree());
        return trees.flatMap(tree => buildSharedSubtrees(tree));
    }
}