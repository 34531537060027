import React, { useState } from 'react'; 

import {
    Button,
    Input,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    FormErrorMessage,
    ModalFooter,
    Modal,
} from "@chakra-ui/react";

import { createKeyHandler, keys } from "../../helpers/keyboard.helper";

const NewTreeModal = ({ isOpen, onClose, onSubmit, initialFocusRef }) => {

    const [ treeName, setTreeName ] = useState('');
    const enterKeyHandler = createKeyHandler(keys.ENTER, () => submit(treeName));

    const submit = (treeName => {
        if (isValidTreeName(treeName)){
            if (onSubmit){
                onSubmit(treeName)
            }
        }
    })

    const isValidTreeName = (treeName) => {
       return treeName && treeName.trim() !== ""
    }

    return (
        <Modal
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Create new tree</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <FormControl isInvalid={!isValidTreeName(treeName)}>
                        <FormLabel>Tree name</FormLabel>
                        <Input
                            ref={initialFocusRef}
                            placeholder='Name'
                            onChange={(e) => setTreeName(e.target.value)}
                            onKeyUp={enterKeyHandler}
                        />
                        { (!isValidTreeName(treeName)) && 
                            <FormErrorMessage>Tree name is required.</FormErrorMessage>
                        }
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        mr={3}
                        onClick={() => submit(treeName)}
                    >
                        Add
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NewTreeModal