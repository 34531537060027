import React, {useState} from "react";

const DragDataContext = React.createContext();

export function DragDataContextProvider({children}) {
    const [draggedData, setDraggedData] = useState(null);

    return (
        <DragDataContext.Provider value={{draggedData, setDraggedData}}>
            {children}
        </DragDataContext.Provider>
    );
}

export function useDraggedData() {
    return React.useContext(DragDataContext);
}
