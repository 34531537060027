import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";
import debounce from "lodash.debounce";

import {Filters} from "../../../components/filters";
import {Forest} from "../../../components/forest";
import {
    Accordion,
    AccordionButton, AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    useColorModeValue
} from "@chakra-ui/react";
import {useMobileSearchFilter} from "../contexts/MobileSearchFilterContextProvider";
import {filtersHeight} from "../../../styles/layoutStyles";

export const MobileDashboardFilters = () => {
    // FIXME This piece of state is redundant with startDate, endDate and text above. Probably the best is to remove the
    //  above ones and keep only this
    const {filterFormData, setFilterFormData} = useMobileSearchFilter();
    const [startDate, setStartDate] = useState(filterFormData.fromDate || null);
    const [endDate, setEndDate] = useState(filterFormData.toDate || null);
    const [text, setText] = useState(filterFormData.text || '');
    // TODO This is repeated in several places, use a common place for delete buttons
    const inputBorderColor = useColorModeValue("gray.500", null);
    const filterFormDataRef = React.useRef(filterFormData);
    const debouncedChangeFilterFormData = useRef(debounce(changeFilterFormData, 1000, {
        leading: false,
        trailing: true
    }));
    const backgroundColor = useColorModeValue("white", "gray.800");

    const navigate = useNavigate();

    function changeFilterFormData(term) {
        if (term.length > 2 || term.length === 0) {
            setFilterFormData({
                ...filterFormDataRef.current,
                text: term
            });
        }
    }

    function goToResults() {
        navigate(-1);
    }

    return (
        <>
            <Box as="main" paddingY={4}>
                <Accordion allowMultiple>
                    <AccordionItem
                        borderTop="none"
                        paddingBottom={2}
                    >
                        <h1>
                            <AccordionButton>
                                <Box as="span" flex='1' textAlign='left'>Filters</Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h1>
                        <AccordionPanel padding={0}>
                            <Box
                                display="flex"
                                flexDirection={{base: "column", sm: "row"}}
                                justifyContent="flex-start"
                                gap={4}
                                padding={4}
                                alignItems="end"
                                height={{base: "default", sm: filtersHeight}}
                            >
                                <Filters
                                    text={text}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onTextChange={(term) => {
                                        setText(term)
                                        debouncedChangeFilterFormData.current(term);
                                    }}
                                    onStartDateChange={(date) => {
                                        setStartDate(date);
                                        if (date) {
                                            let formattedDate = moment(date).format("YYYY-MM-DD")
                                            setFilterFormData({
                                                ...filterFormData,
                                                fromDate: formattedDate
                                            })
                                        } else {
                                            setFilterFormData({
                                                ...filterFormData,
                                                fromDate: date
                                            })
                                        }
                                    }}
                                    onEndDateChange={(date) => {
                                        setEndDate(date);
                                        if (date) {
                                            let formattedDate = moment(date).format("YYYY-MM-DD")
                                            setFilterFormData({
                                                ...filterFormData,
                                                toDate: formattedDate
                                            })
                                        } else {
                                            setFilterFormData({
                                                ...filterFormData,
                                                toDate: date
                                            })
                                        }
                                    }}
                                    onClearFilters={() => {
                                        setStartDate(null)
                                        setEndDate(null)
                                        setText('')
                                        setFilterFormData({
                                            ...filterFormData,
                                            text: "",
                                            fromDate: "",
                                            toDate: ""
                                        })
                                    }}
                                />
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <Forest.Root
                    key="forest"
                    onSelectedTreeTags={(selectedTreeTags) => {
                        let selectedTags = Object.keys(selectedTreeTags).flatMap(d => {
                            return selectedTreeTags[d]
                        }).map(d => d.getId());
                        setFilterFormData({
                            ...filterFormData,
                            tagIds: selectedTags
                        })
                    }}
                >
                    <Forest.SearchBar />
                    <Forest.Trees propagateSelection={false} />
                </Forest.Root>
            </Box>
            <Box
                display="flex"
                gap={2}
                justifyContent="end"
                position="fixed"
                bottom={0}
                padding={2}
                width="100%"
                backgroundColor={backgroundColor}
            >
                <Button
                    colorScheme="blue"
                    variant="outline"
                    borderColor={inputBorderColor}
                    aria-label="Clear Filters"
                    onClick={goToResults}
                >
                    Back
                </Button>
                <Button
                    colorScheme="blue"
                    borderColor={inputBorderColor}
                    aria-label="Clear Filters"
                    onClick={goToResults}
                >
                    Apply
                </Button>
            </Box>
        </>
    )
}
