import React from 'react'

import { GroupAccountUI } from '../../components/group_account';

import { Topbar } from "../../components/topbar";

const GroupAccountLayout = () => {

    return (
        <>
            <Topbar />
            <GroupAccountUI />
        </>
    )
}
export default GroupAccountLayout;