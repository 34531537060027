export function getLocalPreference(preferenceName) {
    if(localStorage) {
        return localStorage.getItem(preferenceName);
    }
}

export function saveLocalPreference(preferenceName, value) {
    if(localStorage) {
        localStorage.setItem(preferenceName, value);
    }
}