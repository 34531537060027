import { useState } from "react";
import {
    Box,
    Tag,
    TagCloseButton,
    Checkbox,
    Editable,
    EditablePreview,
    EditableInput,
    EditableTextarea,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Image,
} from "@chakra-ui/react";
import {useSearchColorModeStyles} from "../../styles/searchColorModeStyles";
import {MetaDataText} from "../metadata_text";
import {useEditableInputStyles} from "../../styles/editableInputStyles";

import {DateDisplay} from "../date_display";
import {LinkRepresentation} from "../link-representation";

import { getFavicon } from "../../services/favicon.service";
import StoreService from "../../services/store.service";

export function GridItem({ item, selected, onSelected, onSave }) {
    const { url, title, description, createdBy, createdAt } = item;
    const { editablePreviewStyles } = useEditableInputStyles();
    const { hoverBgColor, selectedBgColor, hoverSelectedBgColor } = useSearchColorModeStyles();

    // TODO instead of making this an uncontrolled component, make the data come to it as props always
    const [tagsList, setTagsList] = useState(item.getTags());

    function onSubmit(fieldName) {
        return function(newValue) {
            if (newValue !== item[fieldName]) {
                item[fieldName] = newValue;
                onSave?.(item);
            }
        }
    }

    function onTagRemove(tag) {
        item.deleteTag(tag);
        // FIXME Don't extend objects like this. This is needed because the network request we will do later requires a
        //  field called deletedTags. The ideal solution would be that we just send the item in its new state to the
        //  backend and the backend deals with how to persist the changes.
        item.deletedTags = [tag.tagId];
        onSave?.(item);
        const newTagsList = tagsList.filter(stateTag => stateTag.tagId !== tag.tagId)
        setTagsList(newTagsList);
    }

    return (
        <Card
            width={{ base: "xs", "2xl": "sm" }}
            shadow="md"
            backgroundColor={selected ? selectedBgColor : null}
            _hover={{
                backgroundColor: selected ? hoverSelectedBgColor : hoverBgColor
            }}
        >
            <CardHeader paddingY={2}>
                <Box display="flex" gap={4}>
                    { (createdBy === StoreService.getUserEmail() || tagsList.every(tag => tag.permission === 'admin' || tag.permission === 'write')) &&
                        <Checkbox
                            isChecked={selected}
                            onChange={(e) => {
                                onSelected?.(item, e.target.checked)
                            }}
                        />
                    }
                    <Editable defaultValue={title} onSubmit={onSubmit("title")}>
                        <EditablePreview as="h2" fontSize="lg" fontWeight={700} {...editablePreviewStyles} />
                        <EditableInput />
                    </Editable>
                </Box>
                {createdBy && <MetaDataText>Created by: {createdBy}</MetaDataText>}
                {createdAt && <MetaDataText>Created at: <DateDisplay dateInMillis={createdAt}/></MetaDataText>}
            </CardHeader>
            {(url || description) && (
                <CardBody
                    paddingY={2}
                    minHeight="fit-content"
                    maxHeight="3xs"
                    overflow="hidden"
                    textOverflow="ellipsis"
                >
                    {url && (
                        <Box display="flex" gap={2} alignItems="center">
                            <Image alt={`Favicon of website ${url}`} width="4" height="4" src={getFavicon(url)}/>
                            <LinkRepresentation url={url} />
                        </Box>
                    )}
                    {description && (
                        <Editable
                            defaultValue={description}
                            onSubmit={onSubmit("description")}
                            isDisabled={ createdBy !== StoreService.getUserEmail()}
                            >
                            <EditablePreview {...editablePreviewStyles} whiteSpace="pre-wrap" />
                            <EditableTextarea rows={10} />
                        </Editable>
                        )
                    }
                </CardBody>
            )}
            <CardFooter
                paddingY={2}
                display="flex"
                gap={2}
                flexWrap="wrap"
                height="4xs"
                alignContent="flex-end"
            >
                {tagsList && tagsList.map((tag) => (
                    <Tag key={tag.tagId} colorScheme="blue">
                        {tag.name}
                        { tag.permission !== 'read' && <TagCloseButton onClick={() => onTagRemove(tag)} /> }
                    </Tag>))}
            </CardFooter>
        </Card>
    );
}