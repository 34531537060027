import React, { useMemo } from 'react';

import { 
    AlertDialog,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogOverlay,
    AlertDialogContent,
    Button,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    ListItem,
    UnorderedList,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";

import { VscTrash as DeleteIcon } from "react-icons/vsc";

import { User } from "./User";

import { convertToDateDomElementFormat } from "../../helpers/convertToDateDomElementFormat";

import { contextualMenuIds } from "../../helpers/treeContextualMenu.helper"

import { useGroupAccount } from "../../contexts/GroupAccountContextProvider"
import { useModal } from '../modal-manager';

export const Users = () => {

    const backgroundColor = useColorModeValue("blue.50", "blue.800");
    
    const { getUsers, users, deleteUsers } = useGroupAccount();
    const { openTagModalOfType } = useModal();

    const [ searchStr, setSearchStr] = React.useState(null);
    const [ fromRegisterDate, setFromRegisterDate] = React.useState(null);
    const [ toRegisterDate, setToRegisterDate] = React.useState(null);

    const [selectedUsers, setSelectedUsers] = React.useState([]);
    
    const cancelRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const visibleSelectedUsers = useMemo(() => {
        return users.filter(user => selectedUsers.includes(user.userId))
    }, [users, selectedUsers]);
    
    const amountOfUsersForBulkOperations = visibleSelectedUsers.length === 0
        ? users.length
        : visibleSelectedUsers.length;

    React.useEffect(() => {
        getUsers();
    }, []);

    React.useEffect(() => {
        getUsers(searchStr, fromRegisterDate, toRegisterDate);
    }, [searchStr, fromRegisterDate, toRegisterDate]);

    function onBulkDeleteClicked() {
        onClose();
        let usersToDelete;
        if (visibleSelectedUsers.length > 0) {
            usersToDelete = visibleSelectedUsers;
        } else {
            usersToDelete = users;
        }
        const userIdsToDelete = usersToDelete.map(user => user.userId);
        deleteUsers(userIdsToDelete)
        // .then(() => {
        //     getUsers()
        // });
    }
    
    function onClearSearch() {
        setSearchStr("");
        // setCountry("");
        setFromRegisterDate("");
        setToRegisterDate("");
        setSelectedUsers([])
    }

    const fromRegisterDateText = fromRegisterDate ? convertToDateDomElementFormat(fromRegisterDate) : "";
    const toRegisterDateText = toRegisterDate ? convertToDateDomElementFormat(toRegisterDate) : "";

    return (
        <>
            <Flex
                justifyContent="flex-start"
                gap={4}
                padding={8}
                alignItems="end"
                backgroundColor={backgroundColor}
                borderBottom="1px solid"
                borderColor="gray.200"
            >
                <FormControl width="xs">
                    <FormLabel>User</FormLabel>
                    <Input
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value);
                        }}
                    />
                </FormControl>
                <FormControl width="xs">
                    <FormLabel>From Register Date</FormLabel>
                    <Input
                        type="date"
                        value={fromRegisterDateText}
                        onChange={(event) => {
                            const date = event.target.valueAsDate;
                            setFromRegisterDate(date);
                        }}
                    />
                </FormControl>
                <FormControl width="xs">
                    <FormLabel>To Register Date</FormLabel>
                    <Input
                        type="date"
                        value={toRegisterDateText}
                        onChange={(event) => {
                            const date = event.target.valueAsDate;
                            setToRegisterDate(date);
                        }}
                    />
                </FormControl>
                <IconButton
                    icon={<DeleteIcon />}
                    variant="outline"
                    aria-label="Clear Filters"
                    onClick={() => {
                        onClearSearch()
                    }}
                />
                <Button
                    variant="outline"
                    colorScheme="red"
                    onClick={() => {
                        openTagModalOfType(contextualMenuIds.NEW_GROUP_USER, null)
                    }}
                >
                    New user
                </Button>
                { selectedUsers.length > 0 &&
                    <Button
                        variant="outline"
                        colorScheme="red"
                        onClick={onOpen}
                    >
                        Delete {amountOfUsersForBulkOperations} users
                    </Button>
                }
            </Flex>
            <Flex flexGrow={1} width="100%">
            { !!users?.length &&
                <UnorderedList
                    listStyleType="none"
                    width="full"
                    overflowY="scroll"
                    margin={0}
                    padding={2}
                >
                    {
                        users.map(user => (
                            <ListItem>
                                <User
                                    key={`group-user-${user.userId}`}
                                    user={user}
                                    selected={selectedUsers.includes(user.userId)}
                                    onSelect={(userId, checked) => {
                                        if (checked){
                                            setSelectedUsers([ ...selectedUsers, userId ])
                                        }
                                        else {
                                            setSelectedUsers([ ...selectedUsers.filter(e => e !== userId) ])
                                        }
                                    }}
                                    onDelete={() => getUsers() }
                                />
                            </ListItem>
                        ))
                    }
                </UnorderedList>
            }
            </Flex>
            <AlertDialog
                isOpen={isOpen}
                aria-labelledby="form-dialog-title"
                onClose={() => {
                    onClose();
                }}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader id="form-dialog-title">Are you sure?</AlertDialogHeader>
                        <AlertDialogBody>
                            This action will remove the selected users from the group account.
                        </AlertDialogBody>
                        <AlertDialogFooter gap={4}>
                            <Button
                                colorScheme="red"
                                onClick={onBulkDeleteClicked}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={onClose}
                                ref={cancelRef}
                            >
                                No
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
};

export default Users
