import {Box, Button, Heading, TabPanel, TabPanels, Tabs, TabList, Tab} from "@chakra-ui/react";
import {Forest} from "../forest";
import React, {useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {WebpageEditor} from "../webpage-editor";
import {NoteEditor} from "../note-editor";
import {Panel, PanelGroup, PanelResizeHandle} from "react-resizable-panels";
import {useForest} from "../../contexts/ForestContextProvider";

export function WebTaggingUI() {
    const [webpageData] = useState(null);
    const [noteData] = useState(null);
    const location = useLocation();

    let activeTabIndex = 0;
    if(location.pathname.indexOf("/note") !== -1) {
        activeTabIndex = 1;
    }

    return (
        <Box display="flex">
            <PanelGroup direction="horizontal">
                <Panel defaultSize={25} minSize={20} maxSize={50}>
                    <Forest.Root
                        key="forest"
                        webpageData={webpageData}
                        noteData={noteData}
                    >
                        <Forest.SearchBar />
                        <Forest.TitleBar title="Trees" />
                        <Forest.Trees propagateSelection={true} />
                    </Forest.Root>
                </Panel>
                <PanelResizeHandle>
                    <Box width={2} height="100%" backgroundColor="gray.300"/>
                </PanelResizeHandle>
                <Panel defaultSize={75}>
                    <Heading
                        as="h1"
                        fontSize="2xl"
                        height="4rem"
                        borderColor="gray.300"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        TagMachine Catalogation Page
                    </Heading>
                    <Tabs padding={4} defaultIndex={activeTabIndex}>
                        <TabList>
                            <Tab>Webpage</Tab>
                            <Tab>Note</Tab>
                        </TabList>
                        <Outlet />
                    </Tabs>
                </Panel>
            </PanelGroup>
        </Box>
    );
}

export function TaggingTabPanels() {
    const {
        addWebpage,
        webpageData,
        setWebpageData,
        addNote,
        noteData,
        setNoteData,
        forest,
        collectActiveTabData,
        unselectAllTags,
    } = useForest();

    const [webpage, setWebpage] = useState(webpageData);
    const [note, setNote] = useState(noteData);

    useEffect(() => {
        collectActiveTabData();
    }, []);

    useEffect(() => {
        setNote(noteData);
    }, [noteData]);

    useEffect(() => {
        setWebpage(webpageData);
    }, [webpageData]);

    async function saveElement() {
        try {
            if (webpage) {
                await addWebpage(webpage);
            } else if (note) {
                await addNote(note);
            }
        } catch (e) {
            console.log(e);
        }
        setWebpageData(null);
        setWebpage(null);
        setNoteData(null);
        setNote(null);
        unselectAllTags();
    }

    const selectedTags = forest.getSelectedTags();

    return (
        <TabPanels>
            <TabPanel>
                <WebpageEditor
                    webpageData={webpage}
                    setWebpageData={setWebpage}
                    selectedTags={selectedTags}
                />
                <Box marginTop={8} textAlign="end">
                    <Button
                        isDisabled={selectedTags?.length === 0 || !webpage?.url}
                        colorScheme="blue"
                        onClick={saveElement}
                    >
                        {webpage?.websiteId ? 'Update Page' : 'Add Page'}
                    </Button>
                </Box>
            </TabPanel>
            <TabPanel>
                <NoteEditor
                    noteData={note}
                    setNoteData={setNote}
                    selectedTags={selectedTags}
                />
                <Box marginTop={8} textAlign="end">
                    <Button
                        isDisabled={selectedTags?.length === 0 || !note?.title || !note?.description}
                        colorScheme="blue"
                        onClick={saveElement}
                    >
                        {note?.noteId ? 'Update Note' : 'Add Note'}
                    </Button>
                </Box>
            </TabPanel>
        </TabPanels>
    );
}