const tagHelper = {

    formatTag: function formatTag(tag) {
        return {
            ...tag,
            isOpen: false,
            tree: tag?.tree?.length ? tag?.tree?.map(this.formatTag) : tag?.tree
        }
    },
    // TODO Consider not padding forest, and passing tags directly instead
    formatSelectedTags: function formatSelectedTags(forest) {
        return Object
            .entries(forest.getSelectedTreesMap())
            .reduce((acc, [treeId, selectedTags]) => {
                return {
                    ...acc,
                    [treeId]: selectedTags.map(tag => ({ tagId: tag.getId() })),
                };
            }, {});
    },
    getSharedTagTooltipText: function(tagShares, treeId, accountId) {
        return tagShares
            .map(share => {
                if (share.from === accountId && share.treeId === treeId) {
                    // let user = tag.users.find(e => e.properties.accountId === share.properties.to)
                    // return `Subtree shared with ${user.properties.email} with ${share.properties.permission} permission`
                    return `Subtree shared with ${share.toFirstName || ""} ${share.toLastName || ""} (${share.toEmail}) with ${share.permission} permission`
                } else if (share.to === accountId && share.treeId === treeId) {
                    return `The admin of the subtree is ${share.fromFirstName || ""} ${share.fromLastName || ""} (${share.fromEmail}), you have ${share.permission} permission`
                } else {
                    return null
                }
            })
            .filter(r => r).join('\n');
    },
    getTagDragImage: function(tagName, tagHtmlElement) {
        document.querySelector(".tag-drag-image")?.remove();
        const dragImage = document.createElement('div');
        dragImage.classList.add('tag-drag-image');

        const styles = window.getComputedStyle(tagHtmlElement);
        dragImage.textContent = tagName;

        // Apply all styles of the received html element to dragImage
        for(let style in styles) {
            if (styles.hasOwnProperty(style) && isNaN(parseInt(style))) {
                dragImage.style[style] = styles[style];
            }
        }

        // Apply some constant styles
        dragImage.style.padding = '1rem 1rem 1rem 2rem';
        dragImage.style.border = '4px solid var(--chakra-colors-blue-100)';
        dragImage.style.borderRadius = '0.5rem';
        dragImage.style.maxWidth = '25%';
        // To avoid seeing the created element, we position it far from the viewport:
        dragImage.style.position = 'absolute';
        dragImage.style.top = "-10000px";

        document.body.appendChild(dragImage);
        return dragImage;
    }
}

export default tagHelper