import React, { useEffect, useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {
    Image,
    Text,
    Heading,
    Input,
    Link as ChakraLink,
    Button,
    Box,
} from "@chakra-ui/react";

import logo from '../../assets/images/tagmac.png'

import UserService from "../../services/user.service";
import StoreService from "../../services/store.service";
import {routes} from "../../constants";

const PasswordRecoveryLayout = ({email}) => {
    const [showLoading, setShowLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    })
    const [recoveryError, setRecoveryError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!showLoading)
            setShowLoading(true);
        try {
            let response = await UserService.forgetPassword(formData)
            if (response?.success) {
                setRecoveryError('')
                let hash = response.data.hash;
                await StoreService.setHash(hash);
                navigate(routes.PASSWORD);
            }
            if (!response?.success && response?.message)
                setRecoveryError(response?.message)
        }
        catch (error) {
            console.log(error)
        }
        setShowLoading(false);
    }
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }
    useEffect(() => {
        if (email)
            setFormData({
                email: email
            })
    }, [])
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="center"
            width="sm"
            marginTop={16}
            marginX="auto"
            padding={8}
            shadow="lg"
        >
            <figure>
                <Image src={logo} alt="Tag Machine logo"/>
            </figure>
            <Heading as="h1" fontSize="xl">Account Recovery</Heading>
            <Text>Enter email to start recovery of account</Text>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Text fontWeight={600}>Email</Text>
                        <Input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                        />
                    </Box>
                    {recoveryError && <Text color="red">{recoveryError}</Text>}

                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit}
                        className={showLoading ? ' button loader' : 'button'}
                        isDisabled={showLoading || !formData.email}>
                        {showLoading ? '' : 'Send Recovery Email'}
                    </Button>
                </Box>
            </form>
            <Text>Already have an account? <ChakraLink as={Link} to="/login" color="blue.500">Login</ChakraLink>
            </Text>
        </Box>
    )
}

export default PasswordRecoveryLayout
