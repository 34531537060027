import GroupAccountService from "../services/group_account.service";
import { buildGroupAccount } from "../models/GroupAccount";

import moment from "moment/moment";


export const GroupAccountRepository = {

    get: function get() {
        return GroupAccountService.get()
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                const account = response.data;
                return buildGroupAccount({
                    id: account.groupAccountId,
                    name: account.name,
                    createdAt: account.createdAt,
                    createdBy: account.createdBy
                })
            }
        })
        .catch((error) => {
            throw error
        })
    },

    save: function save(data) {
        return GroupAccountService.post({
            ...data,
            groupAccountId: data.id
        })
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                const account = response.data;
                return buildGroupAccount({
                    id: account.groupAccountId,
                    name: account.name,
                    createdAt: account.createdAt,
                    createdBy: account.createdBy
                })
            }
        })
        .catch((error) => {
            throw error
        })
    },

    getUsers: function getUsers(text, fromDate, toDate) {
        return GroupAccountService.getUsers(text, 
            fromDate && moment(fromDate).format("YYYY-MM-DD"), 
            toDate && moment(toDate).format("YYYY-MM-DD")
        )
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            throw error
        })
    },

    saveUser: function saveUser(data) {
        return GroupAccountService.addUser(data)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            throw error
        })
    },

    deleteUsers: function deleteUsers(userIds) {
        return GroupAccountService.deleteUsers(userIds)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            throw error
        })
    },

    getTreeUsers: function getTreeUsers(treeId) {
        return GroupAccountService.getTreeUsers(treeId)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            throw error
        })
    },

    saveTreeUsers: function saveTreeUsers(treeId, tagId, users) {
        return GroupAccountService.addTreeUsers(treeId, tagId, users)
        .then(response => {
            if (!response.success) {
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            throw error
        })
    },
}