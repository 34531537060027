import React from 'react'
import moment from 'moment'

import {
    Box,
    Text,
    Card,
    CardHeader,
    CardBody,
    useColorModeValue,
    Flex
} from "@chakra-ui/react";

import { Topbar } from "../../components/topbar";

import { useUser } from "../../contexts/UserContextProvider";



const MyAccountLayout = () => {

    const notActiveBgColor = useColorModeValue("red.300", "red.300");
    const activeBgColor = useColorModeValue("green.700", "green.700");

    const { getSubscriptions, userSubscriptions, getPaymentSession, userPaymentSession } = useUser();

    React.useEffect(() => {

        getSubscriptions()
        getPaymentSession()

        const script = document.createElement("script");

        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
    
        document.body.appendChild(script);
    }, [ ])
  
    return (
        <>
            <Topbar />

            <Flex direction="column">
            { userSubscriptions && 
                <Box display="flex" gap={4} padding={4} flexWrap="wrap">
                    {userSubscriptions.map((subscription) => (
                        <Card 
                            backgroundColor={(subscription.status === 'active' || subscription.status === 'trialing') ? activeBgColor : notActiveBgColor}
                            width={{ base: "xs", "2xl": "sm" }}
                            shadow="md"
                        >
                            <CardHeader paddingY={2}>
                                <Text as="h2" fontSize="lg" fontWeight={700}>{ subscription.items.data[0].plan.productDetails.name }</Text>
                                <Text>{subscription.status}</Text>
                            </CardHeader>
                            <CardBody>
                                <label>Started at {moment(subscription.current_period_start * 1000).format("YYYY-MM-DD HH:mm:ss")}</label><br/>
                                { subscription.current_period_end && <label>Ended at {moment(subscription.current_period_end * 1000).format("YYYY-MM-DD HH:mm:ss")}</label> }
                            </CardBody>
                        </Card>
                    ))}
                </Box>
            }
                <Box>
                    <stripe-pricing-table 
                        pricing-table-id={userPaymentSession.pricing_table_id}
                        publishable-key={userPaymentSession.publishable_key}
                        customer-session-client-secret={userPaymentSession.client_secret}
                    />
                </Box>
            </Flex>
        </>
    )
  }

  export default MyAccountLayout