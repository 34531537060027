import { useColorModeValue } from "@chakra-ui/react";

export function useEditableInputStyles() {
    const backgroundColor = useColorModeValue("gray.200", "gray.800");
    return {
        editablePreviewStyles: {
            paddingX: 1,
            _hover: {
                cursor: "pointer",
                backgroundColor,
            }
        }
    };
}