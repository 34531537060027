/**
 * This helper is a trick to be able to use history outside of a component. This is nice for places like the axios
 * interceptors, where we want to redirect the user to the login page in some cases, but want to avoid page reloads.
 */

let navigateFunction = null;
export const history = {
    navigate: (destination, target) => {
        if (navigateFunction) {
            navigateFunction(destination);
        } else {
            window.open(destination, target);
        }

    },
    setNavigateFunction: (func) => {
        navigateFunction = func;
    },
}