import React, {useEffect} from "react";
import {Navigate, Route, Routes, useNavigate,} from "react-router-dom";

// Layouts
import {Login as LoginLayout} from "./layouts/login";
import RegisterLayout from "./layouts/register";
import PasswordLayout from "./layouts/password";
import MyAccountLayout from "./layouts/my_account"
import {DashboardLayout, DashboardMobileLayout} from "./layouts/dashboard";
import {MobileDashboardFilters} from "./layouts/dashboard/components";
import {Recovery as PasswordRecoveryLayout} from "./layouts/recovery";
import {Admin as AdminLayout} from "./layouts/admin";
import {CompleteProfile as CompleteProfileLayout} from "./layouts/complete_profile";
import UserProfileLayout from "./layouts/user_profile";
import GroupAccountLayout from "./layouts/group_account";
import MachineLayout from "./layouts/machine";
import {MobileSearchFilterContextProvider} from "./layouts/dashboard/contexts/MobileSearchFilterContextProvider";

// Context
import {ForestContextProvider} from "./contexts/ForestContextProvider";
import {GroupAccountContextProvider} from "./contexts/GroupAccountContextProvider";
import {SearchItemContextProvider} from "./contexts/SearchItemContextProvider";
import {UserContextProvider} from "./contexts/UserContextProvider";
import {usePlatformContext} from "./contexts/PlatformContextProvider";

// Components
import {ModalManager} from "./components/modal-manager";
import {TaggingTabPanels, WebTaggingUI} from "./components/tagging-ui/WebTaggingUI";
import {GroupAccountTabPanels} from "./components/group_account/GroupAccountUI";

import {MobileTagSelectionUI} from "./components/tagging-ui/MobileTagSelectionUI";
import {MobileConfirmationUI} from "./components/tagging-ui/MobileConfirmationUI";

import SubscriptionExpiredMessage from "./components/subscription_expired_message"

// Others
import { onIntentReceived, onIntentFinished } from "./native-mobile/intent-listener";
import {routes} from "./constants";
import { history } from "./helpers/history.helper";
import StoreService from "./services/store.service";

const App = () => {
    const navigate = useNavigate();
    history.setNavigateFunction(navigate);

    const { isMobilePlatform, isWebPlatform, platform } = usePlatformContext();
    const defaultStartRoute = isMobilePlatform ? "/dashboard" : "/tag/website";

    useEffect(() => {
        async function setupIntentListener() {
            if (isMobilePlatform) {
                const { startListening } = await import("./native-mobile/intent-listener");
                startListening(platform);
                onIntentReceived((intent) => {
                    if (intent.isWebsiteIntent()) {
                        navigate(`/tag/select-tags?url=${intent.getUrl()}&title=${intent.getTitle()}`);
                    } else if (intent.isNoteIntent()) {
                        navigate(`/tag/select-tags?description=${intent.getDescription()}`);
                    }
                });
                onIntentFinished(() => navigate(routes.DASHBOARD));
            }
        }
        setupIntentListener();
    }, [isMobilePlatform]);

    return (
        <div className="App">
            { StoreService.getIsSubscriptionExpired() === 'true' && <SubscriptionExpiredMessage/>}
            {/* FIXME This context is only relevant in mobile Dashboard, try moving it down in the tree levels by
                 refactoring the routes (make new nested routers down in the tree) */}
            <MobileSearchFilterContextProvider>
                <Routes>
                    <Route path={routes.LOGIN} element={<LoginLayout/>}/>
                    <Route path={routes.COMPLETE_PROFILE} element={<CompleteProfileLayout/>}/>
                    <Route path={routes.USER_PROFILE} element={<UserProfileLayout/>}/>
                    <Route path={routes.SIGNUP} element={<RegisterLayout/>}/>
                    <Route path={routes.RECOVERY} element={<PasswordRecoveryLayout/>}/>
                    <Route path={routes.PASSWORD} element={<PasswordLayout/>}/>
                    <Route
                        path={routes.TAG}
                        element={
                            <GroupAccountContextProvider>
                                <ForestContextProvider>
                                    <ModalManager>
                                        <MachineLayout/>
                                    </ModalManager>
                                </ForestContextProvider>
                            </GroupAccountContextProvider>
                        }>
                        {isMobilePlatform && (
                            <>
                                <Route path={routes.TAG_SELECT_TAGS} element={<MobileTagSelectionUI/>}/>
                                <Route path={routes.TAG_CONFIRM} element={<MobileConfirmationUI/>}/>
                            </>
                        )}
                        {isWebPlatform && (
                            <Route path="*" element={<WebTaggingUI/>}>
                                <Route path={routes.TAG_WEBSITE} element={<TaggingTabPanels/>}/>
                                <Route path={routes.TAG_NOTE} element={<TaggingTabPanels/>}/>
                            </Route>
                        )}
                    </Route>
                    <Route path="/admin" element={<AdminLayout/>}/>
                    <Route
                        path={routes.DASHBOARD}
                        element={
                            <GroupAccountContextProvider>
                                <ForestContextProvider>
                                    <ModalManager>
                                        <SearchItemContextProvider>
                                            {isMobilePlatform ? <DashboardMobileLayout/> : <DashboardLayout/>}
                                        </SearchItemContextProvider>
                                    </ModalManager>
                                </ForestContextProvider>
                            </GroupAccountContextProvider>
                        }
                    />
                    {isMobilePlatform && (
                        <Route
                            path={routes.DASHBOARD_FILTERS}
                            element={(
                                <GroupAccountContextProvider>
                                    <ForestContextProvider>
                                        <ModalManager>
                                            <MobileDashboardFilters/>
                                        </ModalManager>
                                    </ForestContextProvider>
                                </GroupAccountContextProvider>
                            )}
                        />
                    )}
                    <Route
                        path={routes.GROUP_ACCOUNT}
                        element={
                            <UserContextProvider>
                                <ForestContextProvider>
                                    <GroupAccountContextProvider>
                                        <ModalManager>
                                            <GroupAccountLayout/>
                                        </ModalManager>
                                    </GroupAccountContextProvider>
                                </ForestContextProvider>
                            </UserContextProvider>
                        }
                    >
                        <Route path={routes.GROUP_ACCOUNT_PROFILE} element={<GroupAccountTabPanels/>}/>
                        <Route path={routes.GROUP_ACCOUNT_USERS} element={<GroupAccountTabPanels/>}/>
                    </Route>
                    <Route
                        path={routes.MY_ACCOUNT}
                        element={
                            <UserContextProvider>
                                <MyAccountLayout/>
                            </UserContextProvider>
                        }
                    />
                    <Route
                        path="*"
                        element={<Navigate to={defaultStartRoute} replace={true}/>}
                    />
                </Routes>
            </MobileSearchFilterContextProvider>
        </div>
    )
        ;
};
export default App;
