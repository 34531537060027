import React, { useEffect } from 'react'

import {
    Heading,
    Box,
    EditablePreview,
    EditableTextarea,
    Editable,
    EditableInput,
    Image,
    Flex,
    Text
} from "@chakra-ui/react";
import { EditorParameterName } from "../editor-parameter-name";
import {useEditableInputStyles} from "../../styles/editableInputStyles";
import {TagsList} from "../tagsList";

const urlProtocols = {
    HTTP: "http://",
    HTTPS: "https://",
};

// FIXME This could probably merged with NoteEditor
const WebpageEditor = ({ webpageData, setWebpageData, selectedTags }) => {
    const { editablePreviewStyles } = useEditableInputStyles();
    const [isEditingTitle, setIsEditingTitle] = React.useState(false);

    useEffect(() => {
        if (webpageData?.url) {
            // TODO Validate if the URL is correct
            setWebpageData({
                ...webpageData,
                favicon: "https://www.google.com/s2/favicons?domain=" + webpageData.url,
            });
        }
    }, [webpageData?.url]);

    const saveWebpageTitle = nextValue => {
        setWebpageData({
            ...webpageData,
            title: nextValue
        })
    }

    // const saveWebpageUrl = nextValue => {
    //     setWebpageData({
    //         ...webpageData,
    //         url: nextValue
    //     })
    // }

    const saveWebpageDescription = (nextValue) => {
        setWebpageData({
            ...webpageData,
            description: nextValue
        })
    }

    /**
     * Formats the URL to include the protocol if it's missing and to lowercase it.
     * @param {string} nextValue The value of the URL to be formatted
     * @returns void
     */
    function formatUrl(nextValue) {
        const isProtocolMissing = !nextValue?.startsWith(urlProtocols.HTTP) && !nextValue?.startsWith(urlProtocols.HTTPS);
        let formattedUrl = nextValue;
        if (isProtocolMissing && nextValue !== "") {
            formattedUrl = `${urlProtocols.HTTPS}${nextValue}`;
        }
        const regex = /^https?:\/\//i; // case insensitive https:// or http://
        if (regex.test(nextValue)) {
            // If the string starts with http:// or https://
            formattedUrl = nextValue.replace(regex, (match) => match.toLowerCase());
        }
        setWebpageData({
            ...webpageData,
            url: formattedUrl,
        });
    }

    return (
        <>
            <Flex
                width="100%"
                padding={2}
                direction="column"
                gap={4}
            >
                <Heading as="h3" fontSize="xl">Configure Website</Heading>
                <Box>
                    <EditorParameterName>Title:</EditorParameterName>
                    {/* TODO Instead of this, gather the real title of the page, might require backend */}
                    <Editable
                        value={isEditingTitle ? webpageData?.title : (webpageData?.title || webpageData?.url)}
                        onEdit={() => setIsEditingTitle(true)}
                        onCancel={() => setIsEditingTitle(false)}
                        onSubmit={(nextValue) => {
                            setIsEditingTitle(false);
                            saveWebpageTitle(nextValue);
                        }}
                        placeholder="Title"
                        onChange={(value) => setWebpageData({ ...webpageData, title: value})}
                    >
                        <EditablePreview {...editablePreviewStyles} color={!webpageData?.title ? "gray" : null} />
                        <EditableInput />
                    </Editable>
                </Box>
                <Box>
                    <EditorParameterName>Url:</EditorParameterName>
                    <Box display="flex" gap={4} alignItems="center">
                        {webpageData?.favicon &&
                            <Image src={webpageData.favicon} />
                        }
                        { webpageData && webpageData.websiteId ?
                            <Text>{webpageData?.url}</Text>
                        :
                            <Editable
                                value={webpageData?.url}
                                onSubmit={formatUrl}
                                placeholder="https://example.com"
                                onChange={(value) => setWebpageData({ ...webpageData, url: value})}
                            >
                                <EditablePreview {...editablePreviewStyles} color={!webpageData?.url ? "gray" : null} />
                                <EditableInput />
                            </Editable>
                        }
                    </Box>
                </Box>
                <Box>
                    <EditorParameterName>Description:</EditorParameterName>
                    <Editable
                        value={webpageData?.description || ""}
                        onSubmit={saveWebpageDescription}
                        placeholder="Longer explanation of what the page is about"
                        onChange={(value) => setWebpageData({ ...webpageData, description: value})}
                    >
                        <EditablePreview {...editablePreviewStyles} color={!webpageData?.description ? "gray" : null} />
                        <EditableTextarea />
                    </Editable>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                    <EditorParameterName>Tags:</EditorParameterName>
                    <TagsList tags={selectedTags} />
                </Box>
            </Flex>
        </>
    )
}

export default React.memo(WebpageEditor);