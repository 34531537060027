import {createMultiStyleConfigHelpers, extendTheme} from '@chakra-ui/react'
import {menuAnatomy} from "@chakra-ui/anatomy";

import "@fontsource/plus-jakarta-sans/400.css";
import "@fontsource/plus-jakarta-sans/800.css";

const responsiveSizeOverrides = {
    defaultProps: {
        size: {
            base: "lg",
            sm: "md",
        },
    }
};

const sizeBasedStyles = {
    sizes: {
        lg: {
            fontSize: "1rem",
        }
    }
};

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys)
const menuBaseStyle = definePartsStyle({
    item: {
        minHeight: "3rem",
    },
});
const menuTheme = defineMultiStyleConfig({ baseStyle: menuBaseStyle });

const theme = extendTheme({
    styles: {
        global: {
            'body': {
                marginTop: "constant(safe-area-inset-top)",
                marginTop: "env(safe-area-inset-top)",
                marginBottom: "calc(4px + env(safe-area-inset-top))",
                fontSize: { base: "14px", xl: "16px" },
            },
        },
    },
    config: {
        initialColorMode: 'system',
        useSystemColorMode: true,
    },
    fonts: {
        heading: `'Plus Jakarta Sans', sans-serif`,
        body: `'Plus Jakarta Sans', sans-serif`,
    },
    components: {
        Input: {
            ...responsiveSizeOverrides,
            ...sizeBasedStyles,
        },
        InputGroup: {
            ...responsiveSizeOverrides,
            ...sizeBasedStyles,
        },
        Button: {
            ...responsiveSizeOverrides,
            ...sizeBasedStyles,
        },
        Menu: menuTheme,
    }
})

export default theme;