import api from './api.service'

const ENDPOINT = 'api/user'

const isEmailExist = (email) => {
    return api.post(`${ENDPOINT}/checkEmailExist`, { email: email })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const updateUserData = (userId, data) => {
    return api.put(`${ENDPOINT}/${userId}`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const forgetPassword = (data) => {
    return api.post(`${ENDPOINT}/forget-password`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const resetPassword = (data) => {
    return api.post(`${ENDPOINT}/reset-password`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const searchUser = (data) => {
    return api.post(`${ENDPOINT}/search`, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const deleteUsers = (userIds) => {
    return api.delete(`${ENDPOINT}`, { params: { userIds: userIds } })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const deactivateUser = () => {
    return api.get(`${ENDPOINT}/deactivate`, )
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getUserStatistics = (userId) => {
    return api.get(`${ENDPOINT}/${userId}/statistics`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getUserSubscriptions = () => {
    return api.get(`${ENDPOINT}/subscriptions`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getUserPaymentSession = () => {
    return api.get(`${ENDPOINT}/payment/session`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const getUser = () => {
    return api.get(`${ENDPOINT}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const setUserAdmin = (userId, isAdmin) => {
    return api.get(`${ENDPOINT}/${userId}/admin?isAdmin=${isAdmin}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}
const exportUsers = (data) => {
    return api.post(`${ENDPOINT}/export`, data, { responseType: 'blob' })
        .then(response => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'excel.xlsx'); 
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl)
        })
        .catch(error => { throw error })
}

const UserService = {
    isEmailExist,
    updateUserData,
    forgetPassword,
    resetPassword,
    searchUser,
    getUser,
    deleteUsers,
    deactivateUser,
    exportUsers,
    getUserStatistics,
    getUserSubscriptions,
    getUserPaymentSession,
    setUserAdmin
}
export default UserService
