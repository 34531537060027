import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';

import logo from '../../assets/images/tagmac.png';

import AuthService from "../../services/auth.service";
import StoreService from "../../services/store.service";
import {
    Box,
    Button,
    Heading,
    Image,
    Input,
    Text,
    Link as ChakraLink,
    InputRightElement,
    InputGroup,
    Checkbox, 
    FormControl, 
    FormLabel,
    Select
} from "@chakra-ui/react";
import {VscEye as EyeIcon, VscEyeClosed as EyeCloseIcon} from "react-icons/vsc";
import {createKeyHandler, keys} from "../../helpers/keyboard.helper";
import {history} from "../../helpers/history.helper";
import {routes} from "../../constants";
const { getNames } = require('country-list');


const RegisterLayout = ({data}) => {

    const [showLoading, setShowLoading] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [registerError, setRegisterError] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        country: '',
        email: '',
        password: '',
        confirmPassword: '',
        accountId: ''
    });

    const countryList = getNames().sort();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!showLoading)
            setShowLoading(true);
        if (formData.accountId === "")
            await setFormData(prev => {
                delete prev.accountId
                return prev;
            })
        if (!formData.firstName){
            setRegisterError("First name must not be empty")
        }
        else if (!formData.lastName){
            setRegisterError("Last name must not be empty")
        }
        else if (!formData.email){
            setRegisterError("Email must not be empty")
        }
        else if (!acceptTerms) {
            setRegisterError("The terms must be accepted")
        }
        else {
            try {
                let response = await AuthService.register(formData);
                if (response?.success) {
                    setRegisterError('')
                    let responseData = response.data;
                    StoreService.setUserId(responseData.userId)
                    history.navigate(routes.USER_PROFILE, "_self");
                }
                if (!response?.success && response?.message)
                    setRegisterError(response?.message)
            } catch (error) {
                console.log(error)
            }
        }
        setShowLoading(false);
    }

    function handleCountryChange(event) {
        setFormData({
            ...formData,
            country: event.target.value,
        });
    }

    const shouldAllowRegistering = !(showLoading || !formData.firstName || !formData.lastName || !formData.email || !formData.password || !formData.confirmPassword || !formData.country || !acceptTerms);
    const enterKeyHandler = createKeyHandler(keys.ENTER, (e) => {
        if (shouldAllowRegistering) {
            handleSubmit(e);
        }
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    // TODO What does this do?
    //  This could be moved to the initial state declaration by setting a default initial value when defining state
    useEffect(() => {
        if (data && data.email !== "") {
            setFormData({
                ...formData,
                email: data.email,
                accountId: data.accountId
            })
        }
    }, [])

    const toggleShowPassword = (passwordInputName) => {
        if(passwordInputName === "password") {
            setShowPassword1(!showPassword1);
        } else if (passwordInputName === "confirmPassword") {
            setShowPassword2(!showPassword2);
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="center"
            width="sm"
            marginTop={16}
            marginX="auto"
            padding={8}
            shadow="lg"
        >
            <figure>
                <Image src={logo} alt="Tag Machine logo"/>
            </figure>
            <Heading as="h1" fontSize="xl">Create an Account</Heading>
            <Text style={{ textAlign: 'center' }}>
                Sign up now to get started and enjoy a 180 days free trial
            </Text>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={6}>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>First Name</FormLabel>
                        <Input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            placeholder="John"
                        />
                    </FormControl>

                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Last Name</FormLabel>
                        <Input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            placeholder="Doe"
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Country</FormLabel>
                        <Select name="country" onChange={handleCountryChange}>
                            <option value="country">Select Country</option>
                            {countryList.map(country => <option value={country} key={country}>{country}</option>)}
                        </Select>
                    </FormControl>
                    

                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Email</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                        />
                    </FormControl>

                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword1 ? 'text' : 'password'}
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            <InputRightElement>
                                {showPassword1 ?
                                    <EyeCloseIcon onClick={() => toggleShowPassword("password")}/> :
                                    <EyeIcon onClick={() => toggleShowPassword("password")} />}
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Confirm Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword2 ? 'text' : 'password'}
                                name="confirmPassword"
                                id="loginInput"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                onKeyUp={enterKeyHandler}
                            />
                            <InputRightElement>
                                {showPassword1 ?
                                    <EyeCloseIcon onClick={() => toggleShowPassword("confirmPassword")}/> :
                                    <EyeIcon onClick={() => toggleShowPassword("confirmPassword")} />}
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    {registerError && <Text color="red">{registerError}</Text>}
                    <FormControl display="flex" gap={2}>
                        <Checkbox
                            type="checkbox"
                            value={acceptTerms}
                            onChange={e => {
                                setAcceptTerms(e.target.checked)
                            }}
                        />
                        <FormLabel fontSize="xs" margin="0">I have read and agree to the <ChakraLink as={Link} to={"https://www.tagmachine.me/aviso-legal"} target={"_blank"}>Terms of Service</ChakraLink></FormLabel>
                    </FormControl>


                    <Button
                        colorScheme="blue"
                        onClick={handleSubmit}
                        className={showLoading ? ' button loader' : 'button'}
                        isDisabled={!shouldAllowRegistering}
                    >
                        {showLoading ? '' : 'Get Started'}
                    </Button>
                </Box>
            </form>
            <Text>Already have an account? <ChakraLink as={Link} to="/login" color="blue.500">Login</ChakraLink>
            </Text>
        </Box>
    )
}

export default RegisterLayout
