import api from './api.service'

const ENDPOINT = 'api/statistics'

const get = () => {
    return api.get(`${ENDPOINT}`)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const WebsiteService = {
   get
}
export default WebsiteService;
