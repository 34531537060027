import {Checkbox, Flex, Icon, Text, Box} from "@chakra-ui/react";
import {
    VscFilter as FilterIcon,
    VscFilterFilled as FilterIconFilled,
    VscCircleFilled as CircleIcon,
} from "react-icons/vsc";
import React from "react";
import {routes} from "../../../constants";
import {useNavigate} from "react-router-dom";
import {useMobileSearchFilter} from "../../../layouts/dashboard/contexts/MobileSearchFilterContextProvider";

export const MobileSearchActionsBar = ({firstShownItem, lastShownItem, itemsCount, onToggleAllCheckboxes}) => {
    const navigate = useNavigate();
    const { hasActiveFilters } = useMobileSearchFilter();

    function showDashboardFilters() {
        navigate(routes.DASHBOARD_FILTERS);
    }

    return (
        <Flex gap={4} justifyContent="space-between" alignItems="center" paddingY={4}>
            <Flex gap={4} alignItems="center">
                <Checkbox onChange={onToggleAllCheckboxes}/>
                <Text whiteSpace="nowrap">Results: {firstShownItem}-{lastShownItem} of {itemsCount}</Text>
            </Flex>
            {hasActiveFilters() ? (
                <Box position="relative" boxSize={6} onClick={showDashboardFilters}>
                    <Icon as={FilterIconFilled} boxSize={5} title="Filter" />
                    <Icon as={CircleIcon} position="absolute" right="0" top="-5px" color="red.600"/>
                </Box>
            ) : (
                <Icon as={FilterIcon} boxSize={5} onClick={showDashboardFilters} title="Filter" />
            )}
        </Flex>
    )
}
