import {Box, Skeleton} from "@chakra-ui/react";
import React from "react";

function ListSkeleton() {
    return <Skeleton height="6rem" marginX={2}/>;
}

function GridSkeleton() {
    return <Skeleton height="18rem" width="18rem" marginX={2}/>;
}

export function LoadingState({ isListLayout }) {
    return isListLayout ? (
        <Box display="flex" flexGrow="2" flexDirection="column" borderLeft="1px solid" borderColor="gray.300"
             gap={2}>
            <ListSkeleton />
            <ListSkeleton />
            <ListSkeleton />
            <ListSkeleton />
        </Box>
    ) : (
        <Box display="flex" flexDirection="column" borderColor="gray.300" maxWidth="80%" width="80%"
             justifyItems="">
            <Box display="flex" gap={4} padding={4} flexWrap="wrap">
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
                <GridSkeleton />
            </Box>
        </Box>
    );
}