import React, { useEffect } from "react";
import SearchItemService from "../services/search_items.service";
import { useToast } from "@chakra-ui/react";
import {buildTaggedItemFromBackendEntity} from "../models/TaggedItem";

const SearchItemContext = React.createContext();

export function SearchItemContextProvider({ children }) {

    const [items, setItems] = React.useState([]);
    const [itemsCount, setItemsCount] = React.useState(0);
    const [itemsPage, setItemsPage] = React.useState(0);
    const itemsLimit = 50;
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const toast = useToast();

    const searchItems = (filterFormData, page= 0) => {
        setIsLoading(true);
        SearchItemService.searchItems({...filterFormData, limit:itemsLimit, page: page})
        .then(response => {
            setIsLoading(false);
            if (response?.success) {
                let { content, page, total } = response.data;
                setItems(content.map(d => { return buildTaggedItemFromBackendEntity(d)}));
                setItemsCount(total)
                setItemsPage(page)
            }
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const exportItems = (filterFormData) => {
        SearchItemService.exportItems({...filterFormData, limit:0})
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        if (error) {
            toast({
                title: 'Error',
                description: error,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [error]);

    useEffect(() => {
        if (success) {
            toast({
                title: 'Ok!',
                description: success,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        }
    }, [success]);

    return <SearchItemContext.Provider value={{
        items, itemsCount, itemsPage, itemsLimit, searchItems, exportItems, isLoading }}
    >
        {children}
    </SearchItemContext.Provider>
}

export function useSearchItem() {
    return React.useContext(SearchItemContext);
}
