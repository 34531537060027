import TreesService from "../services/trees.service";
import { buildTree } from "../models/Tree";
import { buildTag } from "../models/Tag";


export const TreesRepository = {
    save: function save(name) {
        return TreesService.post({ name: name })
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data
        }, (error) => {
            throw error
        })

    },
    get: function get() {
        return TreesService.get()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data.map(tree => buildTree({
                id: tree.treeId,
                color: tree.color,
                position: tree.position,
                name: tree.name,
                permission: tree.permission,
                rootTagId: tree.rootTagId
            }));
        }, (error) => {
            throw error
        })
    },
    shareTreeTag: function shareTreeTag(treeId, tagId, mail, permission) {
        return TreesService.share(treeId, tagId, mail, permission)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            const tree = response.data
            return {
                ...tree,
                tags: tree.tags.map(tag => buildTag({
                    ...tag,
                    treeId: tree.treeId,
                    id: tag.tagId,
                }))
            }
        }, (error) => {
            throw error
        })
    },
    unshareTreeTag: function unshareTreeTag(treeId) {
        return TreesService.deleteShare(treeId)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            const tree = response.data
            return {
                ...tree,
                tags: tree.tags.map(tag => buildTag({
                    ...tag,
                    treeId: tree.treeId,
                    id: tag.tagId,
                }))
            }
        }, (error) => {
            throw error
        })
    },
    updateTreeTag: function updateTreeTag(treeId, tagId, tagName) {
        return TreesService.put(treeId, tagId, { name: tagName })
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            const tree = response.data
            return {
                ...tree,
                tags: tree.tags.map(tag => buildTag({
                    ...tag,
                    treeId: tree.treeId,
                    id: tag.tagId,
                }))
            }
        }, (error) => {
            throw error
        })
    },
    deleteTreeTag: function deleteTreeTag(treeId, tagId) {
        return TreesService.del(treeId, tagId)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            const tree = response.data
            return {
                ...tree,
                tags: tree.tags.map(tag => buildTag({
                    ...tag,
                    treeId: tree.treeId,
                    id: tag.tagId,
                }))
            }
        }, (error) => {
            throw error
        })
    },
    setPosition: function setPosition(treeId, position) {
        return TreesService.setPosition(treeId, position)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data
        }, (error) => {
            throw error
        })
    },
    setColor: function setColor(treeId, color) {
        return TreesService.setColor(treeId, color)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data
        }, (error) => {
            throw error
        })
    },
    createTreeFromTag: function createTreeFromTag(treeId, tagId) {
        return TreesService.createTreeFromTag(treeId, tagId)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data
        }, (error) => {
            throw error
        })
    }
}