import React from 'react'
import {
    Card,
    Box,
    EditablePreview,
    EditableInput,
    Editable, EditableTextarea, Heading, Flex
} from "@chakra-ui/react";
import { EditorParameterName } from "../editor-parameter-name";

import { useEditableInputStyles } from "../../styles/editableInputStyles";
import { TagsList } from "../tagsList";

// FIXME This could probably merged with WebsiteEditor
const NoteEditor = ({ noteData, setNoteData, selectedTags }) => {
    const { editablePreviewStyles } = useEditableInputStyles();

    return (
        <>
            <Flex
                width="100%"
                padding={2}
                direction="column"
                gap={4}
            >
                <Heading as="h3" fontSize="xl">Configure Note</Heading>
                <Box>
                    <EditorParameterName>Title:</EditorParameterName>
                    <Editable
                        value={noteData?.title || ""}
                        onChange={nextValue => {
                            setNoteData({
                                ...noteData,
                                title: nextValue
                            })
                        }}
                        placeholder="Add a title by clicking here"
                    >
                        <EditablePreview {...editablePreviewStyles} color={!noteData?.title ? "gray" : null} />
                        <EditableInput />
                    </Editable>
                </Box>
                <Box>
                    <EditorParameterName>Description:</EditorParameterName>
                    <Editable
                        value={noteData?.description || ""}
                        onChange={(nextValue) => {
                            setNoteData({
                                ...noteData,
                                description: nextValue
                            })
                        }}
                        placeholder="Add a description by clicking here"
                    >
                        <EditablePreview {...editablePreviewStyles} color={!noteData?.description ? "gray" : null} />
                        <EditableTextarea />
                    </Editable>
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                    <EditorParameterName>Tags:</EditorParameterName>
                    <TagsList tags={selectedTags} />
                </Box>
            </Flex>
        </>
    )
}

export default React.memo(NoteEditor);