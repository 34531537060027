import React, {useEffect, useState} from "react";
import logo from '../../assets/images/tagmac.png';

import StoreService from "../../services/store.service";
import UserService from "../../services/user.service";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    Link as ChakraLink,
} from "@chakra-ui/react";
import {Link, useNavigate} from 'react-router-dom';
import {
    VscEye as EyeIcon,
    VscEyeClosed as EyeCloseIcon,
    VscArrowLeft as BackIcon
} from "react-icons/vsc";
import {routes} from "../../constants";


const PasswordLayout = () => {

    const [showLoading, setShowLoading] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [formData, setFormData] = useState({
        code: '',
        password: '',
        confirmPassword: '',
        hash: ''
    });
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();

    const toggleEyeIconPassword1 = () => {
        setShowPassword1(prev => !prev);
    }

    const toggleEyeIconPassword2 = () => {
        setShowPassword2(prev => !prev);
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!showLoading)
            setShowLoading(true);
        try {
            let response = await UserService.resetPassword(formData);
            if (response?.success) {
                setPasswordError('');
                navigate(routes.LOGIN);
            }
            if (!response?.success && response?.message)
                setPasswordError(response?.message)
        }
        catch (error) {
            console.log(error)
        }
        setShowLoading(false);
    }

    useEffect(() => {
        async function fetchData(){
            let storedHash = await StoreService.getHash();
            await setFormData({
                ...formData,
                hash: storedHash
            })
        }
        fetchData()
    }, [])

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="center"
            width="sm"
            marginTop={16}
            marginX="auto"
            padding={8}
            shadow="lg"
        >
            <Box alignSelf="flex-start">
                <ChakraLink as={Link} to="/recovery" color="blue.500">
                    <Icon as={BackIcon} cursor="pointer"/>
                </ChakraLink>
            </Box>
            <figure>
                <Image src={logo} alt="Tag Machine logo"/>
            </figure>
            <Heading as="h1" fontSize="xl">Password Reset</Heading>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={6}>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Recovery code</FormLabel>
                        <Input
                            type="number"
                            name="code"
                            onChange={handleChange}
                            placeholder="Recovery Code"
                        />
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword1 ? 'text' : 'password'}
                                name="password"
                                id="loginInput"
                                placeholder="New Password"
                                onChange={handleChange}
                                // onKeyUp={enterKeyHandler}
                            />
                            <InputRightElement>
                                {showPassword1 ?
                                    <EyeCloseIcon onClick={toggleEyeIconPassword1}/> :
                                    <EyeIcon onClick={toggleEyeIconPassword1} />}
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl display="flex" flexDirection="column" gap={2}>
                        <FormLabel fontWeight={600}>Confirm Password</FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword2 ? 'text' : 'password'}
                                name="confirmPassword"
                                id="loginInput"
                                placeholder="Confirm Password"
                                onChange={handleChange}
                                // onKeyUp={enterKeyHandler}
                            />
                            <InputRightElement>
                                {showPassword2 ?
                                    <EyeCloseIcon onClick={toggleEyeIconPassword2}/> :
                                    <EyeIcon onClick={toggleEyeIconPassword2} />}
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    {passwordError && <Text color="red">{passwordError}</Text>}
                    <Button type="submit" colorScheme="blue">
                        {showLoading ? 'Applying' : 'Set New Password'}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default PasswordLayout;
