import React, {useEffect, useState, useRef, useMemo} from 'react';
import { useForest } from "../../contexts/ForestContextProvider";
import {
    Box,
} from "@chakra-ui/react";
import debounce from "lodash.debounce";
import { SearchTagsApplication } from "../../applications/searchTagsApplication";
import {appTopBarHeight} from "../../styles/layoutStyles";

export const SearchForestContext = React.createContext();
export const TagSelectionForestContext = React.createContext();
export const SharedFilterForestContext = React.createContext();
export const TreesForestContext = React.createContext();

const Root = ({ onSelectedTreeTags, children}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [sharedOnlySubtrees, setSharedOnlySubtrees] = useState();
    const [searchInProgress, setSearchInProgress] = useState(false);
    const [isSharedFilterOn, setIsSharedfFilterOn] = useState(false);

    const {
        forest,
        trees,
        allTags,
        unselectAllTags,
        selectedTreeTags,
        addNewTree,
    } = useForest();

    const debouncedTriggerTagSearch = useRef(debounce(triggerTagSearch, 1000, { leading: false, trailing: true }));
    const findSharedSubtrees = React.useCallback(() => SearchTagsApplication.findSharedSubtrees(forest), [forest]);

    function toggleSharedFilter() {
        setIsSharedfFilterOn(!isSharedFilterOn);
    }

    useEffect(() => {
        if (isSharedFilterOn) {
            const sharedSubtrees = findSharedSubtrees();
            setSharedOnlySubtrees(sharedSubtrees);
        } else {
            setSharedOnlySubtrees(null);
        }
    }, [isSharedFilterOn, forest]);

    useEffect(() => {
        if (onSelectedTreeTags){
            onSelectedTreeTags(selectedTreeTags)
        }
    }, [selectedTreeTags])

    // FIXME The second parameter gets passed because otherwise this function sees the very first version of allTags,
    //  which is empty, and this causes the selected status not to be propagated.
    async function triggerTagSearch(query, storedTags) {
        const matchingTags = await SearchTagsApplication.searchTags({ query, localTags: storedTags });
        setSearchResults(matchingTags);
        setSearchInProgress(false);
    }

    useEffect(() => {
        debouncedTriggerTagSearch.current.cancel();
        debouncedTriggerTagSearch.current(searchQuery, allTags);
        setSearchInProgress(!!searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        if (searchResults.length > 0 && !!searchQuery) {
            triggerTagSearch(searchQuery, allTags);
        }
    }, [trees]);

    const areTreeTagsSelected = useMemo(() => {
        return Object.values(selectedTreeTags).flat().length > 0;
    }, [selectedTreeTags]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            height={`calc(100vh - ${appTopBarHeight})`}
            overflowY="hidden"
            sx={{ containerType: "size" }}
        >
            <SearchForestContext.Provider value={{searchQuery, searchInProgress, setSearchQuery, searchResults, setSearchResults}}>
                <TagSelectionForestContext.Provider value={{areTreeTagsSelected, unselectAllTags}}>
                    <SharedFilterForestContext.Provider value={{toggleSharedFilter, isSharedFilterOn}}>
                        <TreesForestContext.Provider value={{trees, addNewTree, sharedOnlySubtrees}}>
                            {children}
                        </TreesForestContext.Provider>
                    </SharedFilterForestContext.Provider>
                </TagSelectionForestContext.Provider>
            </SearchForestContext.Provider>
        </Box>
    );
}

export const ForestRoot = React.memo(Root);
