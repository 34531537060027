import React from 'react'
import {Outlet, useLocation} from "react-router-dom";

import { Topbar } from "../../components/topbar";
import { isWebTaggingExperience } from "../../helpers/routes.helper";
import { isIntentActive } from "../../native-mobile/intent-listener";

const MachineLayout = () => {
    const location = useLocation();
    const isDesktopExperience = isWebTaggingExperience(location.pathname);
    const shouldShowTopbar = isDesktopExperience || !isIntentActive();

return (
    <>
        {shouldShowTopbar && <Topbar/>}
        <Outlet/>
    </>
    );
};

export default MachineLayout;
