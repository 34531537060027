import  React, {useMemo} from 'react';

import {
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Flex,
    useColorModeValue,
    UnorderedList,
    ListItem,
    Box,
    Heading,
    Text,
    IconButton,
    AlertDialog,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogOverlay,
    AlertDialogContent,
    useDisclosure,
    useBreakpointValue,
} from "@chakra-ui/react";

import { Member } from "../member/Member";

import { useUser } from '../../contexts/UserContextProvider';
import { VscTrash as DeleteIcon } from "react-icons/vsc";
import {convertToDateDomElementFormat} from "../../helpers/convertToDateDomElementFormat";
import {ActivityChart} from "../activity-chart";
import {AdminFiltersAccordionWrapper} from "../admin-filters-accordion-wrapper";

const { getNames } = require('country-list');

export const UserList = () => {
    // TODO This is repeated in Filters
    const backgroundColor = useColorModeValue("blue.50", "blue.800");
    const { getUsers, users, handleSearch, exportUsers, deleteUsers, getStatistics } = useUser();

    const countryList = getNames().sort();

    const [ searchStr, setSearchStr] = React.useState(null);
    const [ country, setCountry] = React.useState(null);
    const [ fromRegisterDate, setFromRegisterDate] = React.useState(null);
    const [ toRegisterDate, setToRegisterDate] = React.useState(null);
    const [ globalChartData, setGlobalChartData] = React.useState(null);

    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const cancelRef = React.useRef();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const FilterWrapperComponent = useBreakpointValue({ base: AdminFiltersAccordionWrapper, md: React.Fragment });

    const visibleSelectedUsers = useMemo(() => {
        return users.filter(user => selectedUsers.includes(user.userId))
    }, [users, selectedUsers]);

    React.useEffect(() => {
        getUsers();
        getStatistics().then(data => {
            setGlobalChartData(data);
        })
    }, []);

    React.useEffect(() => {
        handleSearch(searchStr, country, fromRegisterDate, toRegisterDate);
    }, [searchStr, country, fromRegisterDate, toRegisterDate]);

    function onExportUsersClicked () {
        if (visibleSelectedUsers.length > 0) {
            exportUsers({
                userIds: visibleSelectedUsers.map(user => user.userId),
            })
        } else {
            exportUsers({
                userIds: users.map(user => user.userId),
            })
        }
    }

    function onBulkDeleteClicked() {
        onClose();
        let usersToDelete;
        if (visibleSelectedUsers.length > 0) {
            usersToDelete = visibleSelectedUsers;
        } else {
            usersToDelete = users;
        }
        const userIdsToDelete = usersToDelete.map(user => user.userId);
        deleteUsers(userIdsToDelete)
    }

    function onClearSearch() {
        setSearchStr("");
        setCountry("");
        setFromRegisterDate("");
        setToRegisterDate("");
        setSelectedUsers([])
    }

    const searchQueryPresent = !!searchStr || !!country || !!fromRegisterDate || !!toRegisterDate;
    const fromRegisterDateText = fromRegisterDate ? convertToDateDomElementFormat(fromRegisterDate) : "";
    const toRegisterDateText = toRegisterDate ? convertToDateDomElementFormat(toRegisterDate) : "";
    const amountOfUsersForBulkOperations = visibleSelectedUsers.length === 0
        ? users.length
        : visibleSelectedUsers.length;

    return (
        <>
            <FilterWrapperComponent>
                <Flex
                    flexDirection={{ base: "column", sm: "row" }}
                    justifyContent="flex-start"
                    gap={4}
                    padding={8}
                    alignItems="end"
                    backgroundColor={{ base: undefined, md: backgroundColor }}
                    borderBottom="1px solid"
                    borderColor="gray.200"
                >
                    <FormControl width="xs">
                        <FormLabel>User</FormLabel>
                        <Input
                            value={searchStr}
                            onChange={(e) => {
                                setSearchStr(e.target.value);
                            }}
                        />
                    </FormControl>
                    <FormControl width="xs">
                        <FormLabel>Country</FormLabel>
                        <Select
                            name="Country"
                            value={country}
                            onChange={(event) => {
                                setCountry(event.target.value)
                            }}
                        >
                            <option value="">Select Country</option>
                            {countryList.map(country => <option value={country}>{country}</option>)}
                        </Select>
                    </FormControl>
                    <FormControl width="xs">
                        <FormLabel>From Register Date</FormLabel>
                        <Input
                            type="date"
                            value={fromRegisterDateText}
                            onChange={(event) => {
                                let date = event.target.valueAsDate;
                                setFromRegisterDate(date);
                            }}
                        />
                    </FormControl>
                    <FormControl width="xs">
                        <FormLabel>To Register Date</FormLabel>
                        <Input
                            type="date"
                            value={toRegisterDateText}
                            onChange={(event) => {
                                let date = event.target.valueAsDate;
                                setToRegisterDate(date);
                            }}
                        />
                    </FormControl>
                    <IconButton
                        icon={<DeleteIcon />}
                        variant="outline"
                        aria-label="Clear Filters"
                        onClick={() => {
                            onClearSearch()
                        }}
                    />
                    <Button
                        variant="outline"
                        colorScheme="blue"
                        onClick={onExportUsersClicked}
                    >
                        Export {amountOfUsersForBulkOperations} users
                    </Button>
                    { selectedUsers.length > 0 &&
                        <Button
                            variant="outline"
                            colorScheme="red"
                            onClick={onOpen}
                        >
                            Delete {amountOfUsersForBulkOperations} users
                        </Button>
                    }
                </Flex>
            </FilterWrapperComponent>
            <Flex overflow={{ base: "scroll", md: "hidden" }} direction={{ base: "column", md: "row" }}>
                <Flex flexGrow={1} width={{ base: "100%", md: "40%"}} >
                    {searchQueryPresent && users.length === 0 &&
                        <Box textAlign="center" width="100%" padding={8}>
                            <Text color="red">No users found matching the search criteria</Text>
                        </Box>
                    }
                    {!!users?.length &&
                        <UnorderedList
                            listStyleType="none"
                            width="full"
                            overflowY="scroll"
                            margin={0}
                            padding={2}
                        >
                            {
                                users.map(user => (
                                     <ListItem>
                                        <Member
                                            key={`member-user-${user.userId}`}
                                            userData={user}
                                            selected={selectedUsers.includes(user.userId)}
                                            onSelect={(userId, checked) => {
                                                if (checked){
                                                    setSelectedUsers([ ...selectedUsers, userId ])
                                                }
                                                else {
                                                    setSelectedUsers([ ...selectedUsers.filter(e => e !== userId) ])
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))
                            }
                        </UnorderedList>
                    }
                </Flex>
                <Box width={{ base: "100%", md: "60%" }} padding={8} order={{ base: -1, md: "initial" }}>
                    <Heading
                        as="h2"
                        fontSize="2xl"
                        marginBottom={6}
                    >
                        General site activity
                    </Heading>
                    <ActivityChart activityData={globalChartData} heightInPixels={400} />
                </Box>
            </Flex>

            <AlertDialog
                isOpen={isOpen}
                aria-labelledby="form-dialog-title"
                onClose={() => {
                    onClose();
                }}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader id="form-dialog-title">Are you sure?</AlertDialogHeader>
                        <AlertDialogBody>
                            This action will remove the selected users and all their data.
                        </AlertDialogBody>
                        <AlertDialogFooter gap={4}>
                            <Button
                                colorScheme="red"
                                onClick={onBulkDeleteClicked}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={onClose}
                                ref={cancelRef}
                            >
                                No
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
