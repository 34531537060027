import React, { memo, useMemo } from "react";
import {TagSearchResult} from "./TagSearchResult";
import {Text, ListItem, UnorderedList} from "@chakra-ui/react";

export const ForestSearchResultsList = memo(({
     searchResults,
     searchQuery,
     searchInProgress,
     allTags,
     updateTagCheckedStatus,
     createNewTag,
     updateTag,
     deleteTag,
     shareTree,
 }) => {

    // FIXME This is here because when deleting a tag from the search view, in the next re-render
    //  we still have the same results, but allTags don't contain the removed tag already, so the rendering fails.
    //  This needs a rethinking.
    const filteredSearchResults = useMemo(() => {
        return searchResults.filter(result => allTags.some(tag => tag.getId() === result.getId() && tag.getTreeId() === result.getTreeId()));
    }, [searchQuery, searchResults, allTags]);

    // TODO Move this out of the component
    const getTagHierarchy = (tag) => {
        const foundTag = allTags.find(t => t.getId() === tag.getId() && t.getTreeId() === tag.getTreeId());
        const levels = [foundTag];
        return backtrackTreeLevels(foundTag, levels);
    };

    const backtrackTreeLevels = (tag, levels) => {
        if (!tag.parentId) {
            return levels;
        } else {
            const foundTag = allTags.find(t => t.getTreeId() === tag.getTreeId() && t.getId() === tag.getParentId());
            // TODO Consider using [foundTag, ...levels] instead
            levels.unshift(foundTag);
            return backtrackTreeLevels(foundTag, levels);
        }
    }

    const getTagPath = (treeLevels) => {
        return treeLevels
            .map(level => level.name)
            .join(" > ");
    }

    if (!searchInProgress && searchQuery && searchResults.length === 0) {
        return <Text padding={4} color="red">No Results found</Text>
    }

    return (
        <>
            <UnorderedList>
                {filteredSearchResults.map(result => (
                    <ListItem listStyleType="none">
                        <TagSearchResult
                            tag={allTags.find(tag => tag.getId() === result.getId() && tag.getTreeId() === result.getTreeId())}
                            tagPath={getTagPath(getTagHierarchy(result))}
                            searchQuery={searchQuery}
                            onCheckboxChange={(e) => {
                                updateTagCheckedStatus(getTagHierarchy(result), e.target.checked);
                            }}
                            createNewTag={createNewTag}
                            updateTag={updateTag}
                            deleteTag={deleteTag}
                            shareTree={shareTree}
                        />
                    </ListItem>
                ))}
            </UnorderedList>
        </>);
});