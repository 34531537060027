import React, { useEffect, useState } from "react";

import {
  Checkbox,
  Icon,
  Box,
  Tooltip,
  Text,
  Flex,
} from "@chakra-ui/react";
import StoreService from "../../services/store.service";

import {
  VscTriangleDown as ChevronDownIcon,
  VscTriangleRight as ChevronRightIcon,
  VscLiveShare as ShareIcon,
} from "react-icons/vsc";

import {
  getContextMenuItemsForTag,
  contextualMenuIds,
} from "../../helpers/treeContextualMenu.helper";
import tagHelper from "../../helpers/tag.helper";
import { TagContextualMenu } from "../tag-contextual-menu";
import { useTagBackgroundColor, useTagTextColor } from "../../styles/tagStyles";
import { TagChildrenDropTarget } from "../forest/TagChildrenDropTarget";
import {useDraggedData} from "../../contexts/DragDataContextProvider";

import { useModal } from "../modal-manager";

const Tag = ({
  treeId,
  tag,
  tagList,
  setTreeColor,
  onSelectedTags,
  propagateSelection,
  onTagDropped,
  depth,
  maxDepth,
  treeBackgroundColor,
}) => {
  const [isOpen, setIsOpen] = useState(
    StoreService.getKeyValue(
      "tree_" + treeId + "_tag_" + tag.getId() + "_open"
    ) === "true" || false
  );
  const [childTags, setChildTags] = useState([]);

  // Modals
  const [isDragging, setIsDragging] = useState(false);

  const { openTagModalOfType } = useModal();

  const defaultTagBackgroundColor = useTagBackgroundColor();
  const tagBackgroundColor = treeBackgroundColor || defaultTagBackgroundColor;

  const tagTextColor = useTagTextColor();
  const tagReference = React.useRef();
  const { setDraggedData } = useDraggedData();

  function contextualMenuItemSelected(e, selectedItem) {
    e.preventDefault();
    e.stopPropagation();
    switch (selectedItem.action) {
      case contextualMenuIds.NEW_TAG:
      case contextualMenuIds.RENAME_TAG:
      case contextualMenuIds.DELETE_TAG:
      case contextualMenuIds.DELETE_SHARED_TREE:
      case contextualMenuIds.CREATE_TREE_FROM_TAG:
      case contextualMenuIds.EDIT_PERMISSIONS:
      case contextualMenuIds.SHARE_TREE:
        openModalForContextualItem(selectedItem);
        break;
      case contextualMenuIds.CHANGE_COLOR:
        changeTreeColor(selectedItem);
        break;
    }
  }

  function openModalForContextualItem(item) {
    openTagModalOfType(item.action, tag);
  }

  function changeTreeColor(colorItem) {
    setTreeColor(treeId, colorItem.value);
  }

  const toggleTagHandler = async (e) => {
    e.stopPropagation();
    let value = !Boolean(isOpen);
    setIsOpen(value);
    await StoreService.setKeyValue(
      "tree_" + treeId + "_tag_" + tag.getId() + "_open",
      value
    );
  };

  const selectedTagsHandler = (tag, isChecked) => {
    onSelectedTags([tag], isChecked);
  };

  const getTagName = () => {
    if (tag.shares && tag.shares.length > 0) {
      const accountId = StoreService.getAccountId();
      const shareRes = tag.shares.reduce((acc, share) => {
          if (share.from === accountId && share.treeId === treeId) {
            acc.shared_with.push(`${share.toFirstName || ""} ${share.toLastName || ""} (${share.toEmail}) with ${share.permission} permission`)
          } else if (share.to === accountId && share.treeId === treeId) {

            acc.admins.push(`${share.fromFirstName || ""} ${share.fromLastName || ""} (${share.fromEmail})`)
            acc.permissions.push(share.permission)
          }
          return acc
        }, {
          shared_with: [],
          admins: [],
          permissions: []
        })
      let title = ""
      if (shareRes.permissions.length > 0){
        title += `You have ${[...new Set(shareRes.permissions)].join("\n")} permission.\n`;
      }
      else {
        if (shareRes.admins.length > 0 || shareRes.shared_with.length > 0){
          title += `You are admin of the tree.\n`;
        }
      }
      if (shareRes.admins.length > 0){
        title += `The admin of the subtree is  ${[...new Set(shareRes.admins)].join("\n")}.\n`;
      }
      if (shareRes.shared_with.length > 0){
        title += `Subtree shared with ${[...new Set(shareRes.shared_with)].join("\n")}.\n`;
      }

      if (title) {
        
        return (
          <>
            <Tooltip label={title}>
              <Box display="flex" alignItems="center" gap={2}>
                <Text>{tag.name}</Text>
                <ShareIcon />
              </Box>
            </Tooltip>
          </>
        );
      } else {
        return <Text>{tag.name}</Text>;
      }
    } else {
      return <Text>{tag.name}</Text>;
    }
  };

  const setDataToDrag = (event) => {
    event.stopPropagation();
    setIsDragging(true);
    setDraggedData({
      type: tag.getParentId() ? "tag" : "tree",
      payload: tag,
    });
    const dragImage = tagHelper.getTagDragImage(tag.getName(), tagReference.current);
    event.dataTransfer.setDragImage(dragImage, 0, 0);
  };

  const onDragEnd = (event) => {
    event.stopPropagation();
    setIsDragging(false);
  }

  useEffect(() => {
    let tags = tagList.filter((t) => {
      return t.getParentId() === tag.getId();
    });
    setChildTags(tags);
  }, [tagList, tag]);

  const hasChildren = childTags?.length > 0;
  const contextualMenuItems = getContextMenuItemsForTag({ tag });

  return (
    <Box
      id={`tree-${treeId}-tag-${tag.getId()}container`}
      width="100%"
      opacity={isDragging ? 0.5 : 1}
      transition={"opacity 0.4s ease-in-out"}
      // We don't attach the dragStart event if this is a root because in that case trees handle the drag
      onDragStart={setDataToDrag}
      onDragEnd={onDragEnd}
      draggable
    >
      <TagChildrenDropTarget
          onTagDropped={(droppedTag) => onTagDropped(droppedTag, tag)}
          onKeepDrag={() => setIsOpen(true)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          minWidth="fit-content"
          height={{ base: 12, sm: 8 }}
          gap={2}
          padding={2}
          shadow="md"
          backgroundColor={tagBackgroundColor}
          borderRadius={4}
          color={tagTextColor}
          onClick={toggleTagHandler}
          cursor={hasChildren ? "pointer" : null}
          ref={tagReference}
        >
          <Box display="flex" gap={2}>
            <Checkbox
              id={`cb-${treeId}-${tag.getId()}`}
              value={`cbv-${treeId}-${tag.getId()}`}
              onChange={(e) => {
                e.stopPropagation();
                selectedTagsHandler(tag, e.target.checked);
              }}
              isChecked={tag.isChecked()}
              borderColor={tagTextColor}
            />
            <Flex
                id={`tbm-${treeId}-${tag.getId()}`}
                gap={2}
                alignItems="center"
                whiteSpace={{base: undefined, sm: "nowrap"}}
            >
              {hasChildren ? (
                <Box width={4} textAlign="center">
                  <Icon
                    as={isOpen ? ChevronDownIcon : ChevronRightIcon}
                    color="gray.600"
                  />
                </Box>
              ) : (
                <Box width={4} textAlign="center" />
              )}
              {getTagName()}
            </Flex>
          </Box>
          {contextualMenuItems.length > 0 && (
            <TagContextualMenu
              contextualMenuItems={contextualMenuItems}
              onItemSelected={contextualMenuItemSelected}
            />
          )}
        </Box>
      </TagChildrenDropTarget>

      {isOpen && hasChildren && (
        <>
          <Box height={3}></Box>

          {childTags.map((childTag, index, allChildren) => {
            const shouldRenderSpacer = index < allChildren.length - 1;
            return (<div
                style={{
                  marginLeft: "30px",
                  before: {position: "absolute", width: "1px", color: "red"},
                }}
            >
              <Tag
                  treeId={treeId}
                  tag={childTag}
                  tagList={tagList.filter((t) => {
                    return t.getParentId() !== tag.getId();
                  })}
                  onSelectedTags={(tags, selected) => {
                    // Select parent
                    if (selected && propagateSelection) {
                      tags.push(tag);
                    }
                    onSelectedTags(tags, selected);
                  }}
                  propagateSelection={propagateSelection}
                  onTagDropped={onTagDropped}
                  depth={depth + 1}
                  maxDepth={maxDepth}
                  treeBackgroundColor={tagBackgroundColor}
              />
              {shouldRenderSpacer && <Box height={3}></Box>}
            </div>);
          })}
        </>
      )}
    </Box>
  );
};

export default Tag;
