import { useColorModeValue } from "@chakra-ui/react";

export function useSearchColorModeStyles() {
    const hoverBgColor = useColorModeValue("gray.50", "gray.900");
    const selectedBgColor = useColorModeValue("gray.200", "gray.900");
    const hoverSelectedBgColor = useColorModeValue("gray.200", "gray.900");
    const linkColor = useColorModeValue("blue.500", "blue.200");

    return {
        hoverBgColor,
        selectedBgColor,
        hoverSelectedBgColor,
        linkColor,
    };
}