import React, {createContext, useContext, useState} from "react";

const initialFilterFormData = {
    searchBy: [],
    text: "",
    fromDate: '',
    toDate: '',
    tagIds: [],
};

const MobileSearchFilterContext = createContext(initialFilterFormData);

export function MobileSearchFilterContextProvider({ children }) {

    function hasActiveFilters() {
        return filterFormData.searchBy.length > 0
            || filterFormData.text.length > 0
            || filterFormData.fromDate
            || filterFormData.toDate
            || filterFormData.tagIds.length > 0;
    }

    const [filterFormData, setFilterFormData] = useState(initialFilterFormData);

    function clearFilters() {
        setFilterFormData(initialFilterFormData);
    }

    return (
        <MobileSearchFilterContext.Provider value={{filterFormData, setFilterFormData, hasActiveFilters, clearFilters}}>
            {children}
        </MobileSearchFilterContext.Provider>
    )
}

export function useMobileSearchFilter() {
    return useContext(MobileSearchFilterContext);
}