import React from "react";
import {Box, Tag, Text} from "@chakra-ui/react";

export function TagsList({ tags }) {
    if (!tags.length) {
        return <Text color="gray.500" fontSize="sm">Assign tags by selecting them in the trees</Text>;
    }
    return (
        <Box display="flex" gap={2} flexWrap="wrap">
            {tags.map(tag => (
                <Tag key={`${tag.getTreeId()}-${tag.getId()}`} colorScheme="blue">
                    {tag.getName()}
                </Tag>
            ))}
        </Box>
    );
}
