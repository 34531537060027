import api from './api.service'

const ENDPOINT = 'api/group_account'

const get = () => {
    return api.get(ENDPOINT)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const post = (data) => {
    return api.post(ENDPOINT, data)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const getUsers = (text, fromDate, toDate) => {

    let endpoint = `${ENDPOINT}/users`
    const params = []
    if (text){
        params.push(`searchStr=${text}`)
    }
    if (fromDate){
        params.push(`fromRegisterDate=${fromDate}`)
    }
    if (toDate){
        params.push(`toRegisterDate=${toDate}`)
    }
    if (params && params.length > 0){
        endpoint = endpoint + '?' + params.join('&')
    }
    return api.get(endpoint)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const addUser = (data) => {
    return api.post(`${ENDPOINT}/user`, data)
    .then(response => response?.data)
    .catch(error => {throw error})
}

const deleteUsers = (userIds) => {
    return api.delete(`${ENDPOINT}/users`, { params: { userIds: userIds } })
        .then(response => response?.data)
        .catch(error => {throw error})
}

const getTreeUsers = (treeId) => {

    return api.get(`${ENDPOINT}/tree/${treeId}/users`)
        .then(response => response?.data)
        .catch(error => {throw error})
}

const addTreeUsers = (treeId, tagId, users) => {
    return api.post(`${ENDPOINT}/tree/${treeId}/tag/${tagId}/users`, { users: users })
        .then(response => response?.data)
        .catch(error => {throw error})
}
const GroupAccountService = {
    get,
    post,
    getUsers,
    addUser,
    deleteUsers,
    getTreeUsers,
    addTreeUsers,
    
}
export default GroupAccountService;
