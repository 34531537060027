import React, { useState } from "react";
import {Box, Checkbox, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import {
    VscTriangleDown as ChevronDownIcon,
    VscTriangleRight as ChevronRightIcon,
    VscLiveShare as ShareIcon,
} from "react-icons/vsc";
import tagHelper from "../../helpers/tag.helper";
import {getContextMenuItemsForTag} from "../../helpers/treeContextualMenu.helper";
import {TagContextualMenu} from "../tag-contextual-menu";
import {useTagBackgroundColor, useTagTextColor} from "../../styles/tagStyles";

// FIXME Make homogeneous naming for callback functions. Some start with on... and others are ...ed
export function DumbTag({ tag, accountId, onCheckboxChange, contextualMenuItemSelected = () => {} }) {
    const treeId = tag.getTreeId();
    const tagId = tag.getId();
    const tagName = tag.getName();
    const children = tag.getChildren();
    const isChecked = tag.isChecked();
    const hasChildren = !!children?.length;
    const contextualMenuItems = getContextMenuItemsForTag({ tag });

    const [isOpen, setIsOpen] = useState(false);
    const tagBackgroundColor = useTagBackgroundColor();
    const tagTextColor = useTagTextColor();

    function toggleTagHandler(e) {
        e.stopPropagation();
        let value = !Boolean(isOpen)
        setIsOpen(value)
    }

    function onContextualMenuItemSelected(...args) {
        // FIXME Instead of playing with what we pass as argument in different levels of the React tree, use context to
        //  give access to the tag from the contextual menu item component
        contextualMenuItemSelected(...args, tag);
    }

    function getTagName() {
        const tagShares = tag.getShares()
        if (!tagShares.length) {
            return <Text>{tagName}</Text>;
        }
        const tooltipText = tagHelper.getSharedTagTooltipText(tagShares, treeId, accountId);
        return (
            <Tooltip label={tooltipText}>
                <Flex alignItems="center" gap={2}>
                    <Text>{tagName}</Text>
                    <ShareIcon/>
                </Flex>
            </Tooltip>
        );
    }

    return (
        <Box id={`tree-${treeId}-tag-${tagId}-container`} width={"100%"}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                minWidth="350px"
                height={8}
                gap={2}
                padding={2}
                shadow="md"
                marginY={1}
                backgroundColor={tagBackgroundColor}
                borderRadius={4}
                color={tagTextColor}
                onClick={toggleTagHandler}
                cursor={hasChildren ? "pointer" : null}
            >
                <Box display="flex" gap={2}>
                    <Checkbox
                        id={`cb-${treeId}-${tag.getId()}`}
                        value={`cbv-${treeId}-${tag.getId()}`}
                        onChange={e => {
                            e.stopPropagation();
                            onCheckboxChange(tag, e);
                        }}
                        isChecked={isChecked}
                        borderColor={tagTextColor}
                    />
                    <Flex gap={2} alignItems="center" id={`tbm-${treeId}-${tag.getId()}`} >
                        {hasChildren ? (
                            <Box width={4} textAlign="center">
                                <Icon as={isOpen ? ChevronDownIcon : ChevronRightIcon} color="gray.600"/>
                            </Box>
                        ) : (
                            <Box width={4} textAlign="center" />
                        )}
                        {getTagName()}
                    </Flex>
                </Box>
                {contextualMenuItems.length > 0 && (
                    <TagContextualMenu contextualMenuItems={contextualMenuItems} onItemSelected={onContextualMenuItemSelected} />
                )}
            </Box>

            {isOpen && hasChildren &&
                children.map((childTag) =>
                    <Box
                        marginLeft="30px"
                        display="flex"
                        before={{position: "absolute", width: "1px", color: "red"}}
                        key={childTag.getId()}
                    >
                        <DumbTag tag={childTag} onCheckboxChange={onCheckboxChange} contextualMenuItemSelected={onContextualMenuItemSelected} />
                    </Box>
                )
            }
        </Box>
    );
}