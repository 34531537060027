import React, {useEffect, useMemo} from "react";
import {useToast} from "@chakra-ui/react";

import {TreesRepository} from "../repositories/TreesRepository";
import {TagsRepository} from "../repositories/TagsRepository";
import {WebpageDataRepository} from "../repositories/WebpageDataRepository";
import {WebsiteRepository} from "../repositories/WebsiteRepository";
import {NoteRepository} from "../repositories/NoteRepository";

import {ForestApplication} from "../applications/forestApplication";
import TreesService from "../services/trees.service";

import {buildForest} from "../models/Forest";

const ForestContext = React.createContext();

export function ForestContextProvider({children}) {

    const [trees, setTrees] = React.useState([]);
    const [selectedTreeTags, setSelectedTreeTags] = React.useState({});

    const [webpageData, setWebpageData] = React.useState(undefined);
    const [noteData, setNoteData] = React.useState(undefined);

    const [ isLoading, setIsLoading ] = React.useState(false);
    const toast = useToast();

    // TODO Check the side effects of this function, I removed it recently because it was causing trouble
    //  Actually making a call to fetch the forest is too much for every change of the webpage or note
    useEffect(() => {
        fetchForest()
    }, [webpageData, noteData]);

    const fetchTagTree = (treeId, tagId) => {
        setIsLoading(true)
        TreesService.getTagSubTree(treeId, tagId).then(response => {
            if (!response?.success && response?.message) {
                openErrorToast(response?.message)
            }
            setIsLoading(false)
        });
    }

    function openErrorToast(message) {
        toast({
            title: 'Error',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        })
    }

    function openSuccessToast(message) {
        toast({
            title: 'OK!',
            description: message,
            status: 'success',
            duration: 5000,
            isClosable: true,
        })
    }
    const fetchForest = async () => {
        try {
            const forestPromise = ForestApplication.fetchForest();
            let itemTagsPromise;
            if (webpageData?.url) {
                itemTagsPromise = await WebsiteRepository.getWeblinkTags(webpageData.url);
            }
            if (noteData?.noteId) {
                itemTagsPromise = await NoteRepository.getNoteTags(noteData.noteId);
            }
            const [forest, itemTags] = await Promise.all([forestPromise, itemTagsPromise]);
            if(itemTags) {
                forest.updateCheckedStatusById(itemTags, true);
            }
            // FIXME trees should be deprecated, and use forest instead
            setTrees(forest.getTrees());
            // TODO Create the forest state to replace the trees state
            // setForest(forest);
        } catch (e) {
            openErrorToast(e?.message);
        }
    }

    const createNewTag = (parentId, treeId, tagName) => {
        let treeIdentifier = treeId;
        if (!treeIdentifier) {
            const parentTag = trees.map(t => t.getTags()).flat().find(tag => tag.getId() === parentId);
            treeIdentifier = parentTag.getTreeId();
        }
        setIsLoading(true)
        TagsRepository.save(treeIdentifier, parentId, tagName)
            .then((treeTags) => {
                const tree = trees.find(tree => tree.getId() === treeId)
                tree.setTags(treeTags.tags)
                setTrees([...trees.filter(tree => tree.getId() !== treeId), tree])
                openSuccessToast('Tag created!')
                setIsLoading(false)
            }, (error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const updateTag = (tagId, treeId, tagName) => {
        setIsLoading(true)
        TreesRepository.updateTreeTag(treeId, tagId, tagName)
            .then((treeTags) => {
                const tree = trees.find(tree => tree.getId() === treeId)
                tree.setTags(treeTags.tags)
                setTrees([...trees.filter(tree => tree.getId() !== treeId), tree])
                openSuccessToast('Tag updated!')
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const deleteTag = (tagId, treeId) => {
        let treeIdentifier = treeId;
        if (!treeIdentifier) {
            const tag = trees.map(t => t.getTags()).flat().find(tag => tag.getId() === tagId);
            treeIdentifier = tag.getTreeId();
        }
        setIsLoading(true)
        TreesRepository.deleteTreeTag(treeIdentifier, tagId)
            .then((treeTags) => {
                const tree = trees.find(tree => tree.getId() === treeId)
                tree.setTags(treeTags.tags)
                setTrees([...trees.filter(tree => tree.getId() !== treeId), tree])
                openSuccessToast('The tag has been deleted!')
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const shareTree = (tagId, treeId, mail, permission) => {
        setIsLoading(true)
        TreesRepository.shareTreeTag(treeId, tagId, mail, permission)
            .then((treeTags) => {
                const tree = trees.find(tree => tree.getId() === treeId)
                tree.setTags(treeTags.tags)
                setTrees([...trees.filter(tree => tree.getId() !== treeId), tree])
                openSuccessToast(permission && permission === 'remove' ? 'Tree share canceled!' : 'Tree shared!');
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const deleteSharedTree = (treeId) => {
        setIsLoading(true)
        TreesRepository.unshareTreeTag(treeId)
            .then((treeTags) => {
                const tree = trees.find(tree => tree.getId() === treeId)
                tree.setTags(treeTags.tags)
                setTrees([...trees.filter(tree => tree.getId() !== treeId), tree])
                openSuccessToast('Delete shared tree!');
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const moveTag = (tag, newParentTag) => {
        setIsLoading(true)
        ForestApplication.moveTag(tag, newParentTag)
        .then(() => {
            openSuccessToast('Tag moved!');
            fetchForest();
            setIsLoading(false)
        })
        .catch((error) => {
            openErrorToast(error.message)
            setIsLoading(false)
        })
    }

    const createTreeFromTag = (treeId, tagId) => {
        setIsLoading(true)
        TreesRepository.createTreeFromTag(treeId, tagId)
            .then(() => {
                openSuccessToast('Tree created from tag!');
                fetchForest();
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const addNewTree = (name) => {
        setIsLoading(true)
        TreesRepository.save(name)
            .then(() => {
                openSuccessToast('Tree created!')
                fetchForest()
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const setTreeColor = (treeId, color) => {
        setIsLoading(true)
        TreesRepository.setColor(treeId, color)
            .then(() => {
                openSuccessToast('Tree color changed!');
                fetchForest();
                setIsLoading(false)
            })
            .catch((error) => {
                openErrorToast(error.message)
                setIsLoading(false)
            })
    }

    const setTreePosition = (treeId, position) => {
        setIsLoading(true)
        TreesRepository.setPosition(treeId, position)
        .then(() => {
            // openSuccessToast('Tree position changed!');
            // fetchForest();
            setIsLoading(false)
        })
        .catch((error) => {
            openErrorToast(error.message)
            setIsLoading(false)
        })
    }

    const addWebpage = async (webpage) => {
        const forest = buildForest({trees});
        webpage.title = webpage.title || webpage.url;
        try {
            const response = await WebsiteRepository.save(webpage, forest);
            if (response?.success) {
                openSuccessToast(webpage.websiteId ? 'Updated!' : 'Tagged!');
                setWebpageData(response.data);
                window.close()
            }
            if (!response?.success && response?.message) {
                openErrorToast(response?.message);
            }
        } catch (error) {
            openErrorToast(error.message)
        }
    }

    const addNote = (note) => {
        const forest = buildForest({trees});
        return NoteRepository.save(note, forest)
            .then(response => {
                if (response?.success) {
                    openSuccessToast('Tagged!');
                    setNoteData(response.data);
                    window.close()
                }
                if (!response?.success && response?.message) {
                    openErrorToast(response?.message);
                }
            })
            .catch((error) => {
                openErrorToast(error.message)
            })
    }

    const updateTagCheckedStatus = (tags, selected) => {
        const forest = buildForest({trees});
        forest.updateCheckedStatusById(tags, selected);
        // FIXME We are recreating the array of trees because otherwise we are not recreating a new instance and React
        //  won't realize there was a change. Improve this.
        setTrees([...forest.getTrees()]);
    }

    const unselectAllTags = () => {
        const forest = buildForest({trees});
        forest.unselectAllTags();
        setTrees(forest.getTrees());
    }

    const collectActiveTabData = async () => {
        try {
            const queryParams = new URLSearchParams(window.location.search);

            if (queryParams.has("website_id")) {
                const data = await WebpageDataRepository.getById(queryParams.get("website_id"));
                setWebpageData(data);
            } else if (queryParams.has("note_id")) {
                const data = await NoteRepository.getById(queryParams.get("note_id"));
                setNoteData(data);
            } else {
                const data = await WebpageDataRepository.get();
                if (!!data) {
                    setWebpageData(data);
                }
            }
        } catch (e) {
            console.error(e.message);
        }
    }

    useEffect(() => {
        const forest = buildForest({trees});
        setSelectedTreeTags(forest.getSelectedTags());
    }, [trees]);

    useEffect(() => {
        fetchForest();
    }, []);

    const allTags = useMemo(() => {
        // TODO Make the allTags object have tagIds as keys to better access them
        const forest = buildForest({trees});
        return forest.getAllForestTags();
    }, [trees]);

    return (
        <ForestContext.Provider value={{
            // TODO forest is meant to replace trees eventually. Passing both for now to avoid crashes
            forest: buildForest({trees}), trees, allTags, selectedTreeTags, fetchTagTree,
            fetchForest, updateTagCheckedStatus, unselectAllTags, createNewTag, updateTag, deleteTag,
            shareTree, deleteSharedTree, moveTag, createTreeFromTag,
            setTrees, webpageData, noteData, collectActiveTabData, setWebpageData, setNoteData,
            addNewTree, setTreeColor, setTreePosition, addWebpage, addNote,
            isLoading
        }}>
            {children}
        </ForestContext.Provider>
    );
}

export function useForest() {
    return React.useContext(ForestContext);
}
