import WebsitesService from "../services/websites.service";
import TreesService from "../services/trees.service";
import tagHelper from "../helpers/tag.helper";
import {buildTag} from "../models/Tag";



export const WebsiteRepository = {
    save: async function save(website, forest) {
        const selectedTagsByTreeId = tagHelper.formatSelectedTags(forest);
        try {
            const response = await WebsitesService.post({
                ...website,
                treeTagIds: selectedTagsByTreeId,
            });
            if (!response.success) {
                throw new Error(response.message);
            }
            return response;
        } catch (error) {
            throw error;
        }
    },
    getWeblinkTags: async function getWeblinkTags(url) {
        const response = await TreesService.getWeblinkTags(url)
        if (!response.success) {
            throw new Error(response.message);
        }
        return response.data.map(tag => buildTag({ id: tag.tagId, ...tag }));
    }
}