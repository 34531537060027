import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import StoreService from "../../services/store.service";
import UserPermission from "../user_permission";
import React from "react";

export function EditPermissionsModal({isOpen, initialFocusRef, onClose, tagShares, onSubmit}) {

    const sharesByCurrentUser = tagShares?.filter(share => share.from === StoreService.getAccountId());
    if(!sharesByCurrentUser || !sharesByCurrentUser.length) {
        onClose();
        return null;
    }

    // FIXME This is done because when removing a tag's permission, we are requesting a change for the tag.
    //  However, when this modal is rendered as part of the ModalManager, there is no props nor state change
    //  in the ModalManager, so a re-render never happens. Having to create the below function this is not a
    //  good signal
    function onPermissionSave(email, permission) {
        onSubmit(email, permission);
        if (permission === "remove" && sharesByCurrentUser?.length === 1) {
            // If this is the last permission that exists, and we are removing it, close the modal
            onClose();
        }
    }

    return (
        <Modal
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onClose}
            size="2xl"
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Edit Permissions</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    {sharesByCurrentUser.map(share => {
                        return (
                            <UserPermission
                                email={share.toEmail}
                                sharePermission={share.permission}
                                onSave={onPermissionSave}
                            />
                        );
                    })}
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        onClick={onClose}>Done</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}