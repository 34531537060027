import {ForestSearchResultsList} from "./ForestSearchResultsList";
import {ForestSharedOnly} from "./ForestSharedOnly";
import {Box, Spinner} from "@chakra-ui/react";
import {DragDataContextProvider} from "../../contexts/DragDataContextProvider";
import React, {useContext, useEffect, useState} from "react";
import {SearchForestContext, TreesForestContext} from "./Forest";
import {DropTarget} from "./DropTarget";
import Tree from "../tree";
import {useForest} from "../../contexts/ForestContextProvider";
import {useModal} from "../modal-manager";
import StoreService from "../../services/store.service";
import {arrayMoveImmutable} from "array-move";
import {areTreesEqual} from "../../helpers/tree.helper";

export const ForestTrees = ({ propagateSelection }) => {
    const {
        forest,
        setTrees,
        allTags,
        updateTagCheckedStatus,
        createNewTag,
        deleteTag,
        shareTree,
        webpageData,
        addWebpageData,
        setTreePosition,
        isLoading
    } = useForest();
    const { searchQuery, searchInProgress, searchResults } = useContext(SearchForestContext);
    // FIXME Having the useForest hook, maybe trees could be taken from there
    const { trees, sharedOnlySubtrees } = useContext(TreesForestContext);

    const [draggableTrees, setDraggableTrees] = useState([]);
    const [selectedSearchTag/*, setSelectedSearchTag*/] = useState({});
    const { openTagModalOfType } = useModal();

    function drop (droppedTag, destinationPosition) {
        const treeToMove = forest.getTreeById(droppedTag.getTreeId());
        // We get the item in the position of the one we started dragging in dragStart
        // FIXME, make the tree to already return the position
        const treeOriginalPosition = parseInt(treeToMove.getPosition(), 10);
        const destination = destinationPosition > treeOriginalPosition ? destinationPosition - 1 : destinationPosition;
        const reorderedTrees = arrayMoveImmutable(trees, treeOriginalPosition, destination);

        if(!areTreesEqual(trees, reorderedTrees)) {
            setTrees(reorderedTrees.map((tree, index) => {
                tree.position = index;
                // TODO This is a side effect different from the creation of the new array, maybe it should be outside
                setTreePosition(tree.getId(), index)
                return tree;
            }));
        }
    }

    useEffect(() => {
        if (trees){
            setDraggableTrees(trees && (
                <>
                    <DropTarget onTreeDropped={(droppedTree) => drop(droppedTree, 0)} />
                    {trees
                        .sort((a,b) => a.getPosition() - b.getPosition())
                        .map((tree, index) => {
                            return <>
                                <Box
                                    draggable
                                    key={`tree-${tree.getId()}`}
                                    style={{ display:'flex', flexDirection:'column', justifyContent:'space-around'}}
                                >
                                    <Tree
                                        treeId={tree.getId()}
                                        permission={tree.getPermission()}
                                        tags={tree.getTags()}
                                        webpageData={webpageData}
                                        addWebpageData={addWebpageData}
                                        selectedSearchTag={selectedSearchTag}
                                        propagateSelection={propagateSelection}
                                    />
                                </Box>
                                <DropTarget onTreeDropped={(droppedTree) => drop(droppedTree, index + 1)} />
                            </>
                        })}
                </>
            ))
        }
        // TODO With the new search results view, maybe the second item should be removed
    }, [trees, selectedSearchTag]);

    function contextualMenuItemSelected(...args) {
        openTagModalOfType(...args);
    }

    const accountId = StoreService.getAccountId();

    return (
        <Box overflowY="scroll" height="100%" zIndex={200}>
            { isLoading && <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor="rgba(255, 255, 255, 0.6)" // Semi-transparent
                zIndex="modal" // Ensures it is above other content
            >
                <Spinner size="xl" />
            </Box>
            }
            {searchQuery!== "" && searchResults ?
                <ForestSearchResultsList
                    searchResults={searchResults}
                    searchQuery={searchQuery}
                    searchInProgress={searchInProgress}
                    allTags={allTags}
                    updateTagCheckedStatus={updateTagCheckedStatus}
                    createNewTag={createNewTag}
                    deleteTag={deleteTag}
                    shareTree={shareTree}
                />
                :
                sharedOnlySubtrees
                    ? <ForestSharedOnly
                        subtrees={sharedOnlySubtrees}
                        accountId={accountId}
                        onCheckboxChange={(tag, event) => {
                            const tree = forest.getTreeById(tag.getTreeId());
                            // FIXME This is business logic, move it out of the view and put it in the application
                            //  or model
                            // If we are selecting tags, add all the path of tags to the root as to be selected,
                            //  if we are de-selecting, add only the de-selected tag
                            const allTagsToUpdate = event.target.checked ? tree.getPathToTag(tag): [tag];
                            updateTagCheckedStatus(allTagsToUpdate, event.target.checked);
                        }}
                        contextualMenuItemSelected={contextualMenuItemSelected}
                    />
                    : <Box padding={2} paddingBottom={8}>
                        <DragDataContextProvider>
                            {draggableTrees}
                        </DragDataContextProvider>
                    </Box>
            }
        </Box>
    )
}
