import React, {useState} from "react";
import {Box, Collapse, useColorModeValue} from "@chakra-ui/react";
import {buildTag} from "../../models/Tag";
import {useDraggedData} from "../../contexts/DragDataContextProvider";

export const DropTarget = ({ onTreeDropped }) => {
    const [isDragging, setIsDragging] = useState(false);
    const backgroundColor = useColorModeValue("gray.200", "gray.600");
    const { draggedData, setDraggedData } = useDraggedData();

    const onDragEnter = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        if (draggedData.type === "tree") {
            setIsDragging(true);
        }
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const onDragEnd = (e) => {
        e.preventDefault();
        setIsDragging(false);
        if (draggedData.type === "tree") {
            onTreeDropped(buildTag(draggedData.payload));
        }
        setDraggedData(null);
    };

    const onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
    };

    return (
        <Collapse startingHeight="1rem" in={isDragging} transition={{ enter: { duration: 0.1 }}}>
            <Box
                height={12}
                backgroundColor={isDragging && backgroundColor}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDragEnd}
                onDragOver={onDragOver}
                borderRadius={4}
            />
        </Collapse>
    )
}