import { useState } from "react";
import {
    Box,
    Tag,
    TagCloseButton,
    Checkbox,
    Editable,
    EditablePreview,
    EditableInput,
    EditableTextarea,
    Image,
} from "@chakra-ui/react";
import { useSearchColorModeStyles } from "../../styles/searchColorModeStyles";
import { MetaDataText } from "../metadata_text";
import {useEditableInputStyles} from "../../styles/editableInputStyles";

import {DateDisplay} from "../date_display";
import {LinkRepresentation} from "../link-representation";

import {getFavicon} from "../../services/favicon.service";
import StoreService from "../../services/store.service";

export function ListItem({ item, selected, onSelected, onSave }) {
    const { url, title, description, createdBy, createdAt } = item;

    const { editablePreviewStyles } = useEditableInputStyles();
    const { hoverBgColor, selectedBgColor, hoverSelectedBgColor} = useSearchColorModeStyles();

    const hasLimitedInfo = !!(title && !description && !createdAt && !createdBy);

    // TODO instead of making this an uncontrolled component, make the data come to it as props always
    const [tagsList, setTagsList] = useState(item.getTags());

    function onSubmit(fieldName) {
        return function(newValue) {
            if (newValue !== item[fieldName]) {
                item[fieldName] = newValue;
                onSave?.(item);
            }
        }
    }

    function onTagRemove(tag) {
        item.deleteTag(tag);
        // FIXME Don't extend objects like this. This is needed because the network request we will do later requires a
        //  field called deletedTags. The ideal solution would be that we just send the item in its new state to the
        //  backend and the backend deals with how to persist the changes.
        item.deletedTags = [tag.tagId];
        onSave?.(item);
        const newTagsList = tagsList.filter(stateTag => stateTag.tagId !== tag.tagId)
        setTagsList(newTagsList);
    }

    return (
        <Box
            display="flex"
            gap={4}
            borderBottom="1px solid"
            borderColor="gray.300"
            paddingX={2}
            paddingInlineEnd={{ base: 8, sm: undefined }}
            paddingY={2}
            alignItems="center"
            backgroundColor={selected ? selectedBgColor : null}
            _hover={{
                backgroundColor: selected ? hoverSelectedBgColor : hoverBgColor
            }}
        >

            <Box width={8} textAlign="center">
                { (createdBy === StoreService.getUserEmail() || tagsList.every(tag => tag.permission === 'admin' || tag.permission === 'write')) &&
                    <Checkbox
                        isChecked={selected}
                        onChange={(e) => {
                            onSelected?.(item, e.target.checked)
                        }}
                    />
                }
            </Box>
            <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                gap={2}
                flexDirection={hasLimitedInfo ? "column" : { base: "column", xl: "row" }}
            >
                <Box flexBasis="70%" overflow="hidden">
                    <Box display="flex" alignItems="center" gap={2}>
                        {url && <Image alt={`Favicon of website ${url}`} width="5" height="5" src={getFavicon(url)}/>}
                        <Box as="header">
                            <Editable defaultValue={title} onSubmit={onSubmit("title")} isDisabled={ createdBy !== StoreService.getUserEmail()}>
                                <EditablePreview as="h2" fontSize="xl" fontWeight={700} {...editablePreviewStyles} />
                                <EditableInput />
                            </Editable>
                        </Box>
                    </Box>
                    {!hasLimitedInfo && (
                        <Box display="flex" flexDirection={{ base: "column", sm: "row" }} gap={{ base: 0, sm: 4 }} marginBottom={{ base: 2, sm: 4 }}>
                            {createdBy ? (<MetaDataText>Created by: {createdBy}</MetaDataText>) : null}
                            {createdAt ? (<MetaDataText>Created at: <DateDisplay dateInMillis={createdAt} /></MetaDataText>) : null}
                        </Box>
                    )}
                    {url && (
                        <LinkRepresentation url={url} />
                    )}
                    {description && (
                        <Editable
                            defaultValue={description}
                            onSubmit={onSubmit("description")}
                            isDisabled={ createdBy !== StoreService.getUserEmail()}
                        >
                            <EditablePreview {...editablePreviewStyles} whiteSpace="pre-wrap" />
                            <EditableTextarea rows={10} />
                        </Editable>
                    )}
                </Box>
                <Box display="flex" gap={2} alignItems="flex-start" flexBasis="30%" justifyContent={!hasLimitedInfo && { base: "flex-start", xl: "flex-end" }} flexWrap="wrap">
                    {tagsList && tagsList.map((tag) => (
                        <Tag key={tag.tagId} colorScheme="blue">
                            {tag.name}
                            { tag.permission !== 'read' && <TagCloseButton onClick={() => onTagRemove(tag)} /> }
                        </Tag>))}
                </Box>
            </Box>
        </Box>
    );
}