import React, {useEffect, useRef, useState} from "react";
import {Box, useColorModeValue} from "@chakra-ui/react";
import {buildTag} from "../../models/Tag";
import {useDraggedData} from "../../contexts/DragDataContextProvider";

const noop = () => {}
export function TagChildrenDropTarget({ onTagDropped = noop, onKeepDrag = noop, children }) {
    const [isDragging, setIsDragging] = useState(false);
    const longDragTimer = useRef();
    const borderColor = useColorModeValue("gray.400", "gray.500");
    const { draggedData, setDraggedData } = useDraggedData();

    const onDragEnter = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        if (draggedData.type === "tag") {
            setIsDragging(true);
        }
    };

    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const onDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        if (draggedData.type === "tag") {
            onTagDropped(buildTag(draggedData.payload));
        }
        setDraggedData(null);
    };

    const onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
    };

    useEffect(() => {
        clearTimeout(longDragTimer.current);
        if (isDragging) {
            longDragTimer.current = setTimeout(onKeepDrag, 1000);
        }
    }, [isDragging]);

    return (
        <Box
            width="100%"
            border={isDragging ? "5px solid" : ""}
            borderColor={borderColor}
            borderRadius={4}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            {children}
        </Box>
    )
}