import {Link, Text} from "@chakra-ui/react";
import {useSearchColorModeStyles} from "../../styles/searchColorModeStyles";

export const LinkRepresentation = ({ url, target = "_blank" }) => {
    const { linkColor } = useSearchColorModeStyles();

    const parsedUrl = new URL(url);
    const { hostname, pathname} = parsedUrl;

    return (
        <Link href={url} target={target}>
            <Text color={linkColor} fontSize="md" title={url} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {hostname + pathname}
            </Text>
        </Link>
    )
}
