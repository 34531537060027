import React, {useEffect, useState} from 'react'
import {
    Box,
    Checkbox,
    Text,
    Button,
    Flex,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Image,
    useDisclosure,
    useColorModeValue,
} from "@chakra-ui/react";
import { SearchItemList } from "../../components/search_item_list";

import { useForest } from "../../contexts/ForestContextProvider";
import { useSearchItem } from "../../contexts/SearchItemContextProvider";
import { Topbar } from "../../components/topbar";
import WebsiteService from "../../services/websites.service";
import NoteService from "../../services/notes.service";
import {
    appTopBarHeight,
    searchResultsTopbarHeight
} from "../../styles/layoutStyles";
import { DashboardTopbar } from "./components";
import {useSearchLayoutConfig} from "./hooks/useSearchLayoutConfig";
import {useSearchResultSelection} from "./hooks/useSearchResultSelection";
import {getLocalPreference, saveLocalPreference} from "../../helpers/storage";
import {useMobileSearchFilter} from "./contexts/MobileSearchFilterContextProvider";
import { taggedItemTypes } from "../../models/TaggedItem";
import { useNavigate } from "react-router-dom";
import {routes} from "../../constants";
import {navConditions, useCallbackBasedOnNavCondition} from "../../hooks/useCallbackBasedOnNavDestination";

const SKIP_OPEN_PERMISSION_DIALOG = "skip-open-permission-dialog";

const initialFilterFormData = {
    searchBy: [],
    text: "",
    fromDate: '',
    toDate: '',
    tagIds: []
};

const DashboardMobile = () => {

    const searchListTopBarBg = useColorModeValue("white", "gray.800");
    const tabOpenPermissionIconSrc = useColorModeValue("/multi-tab-open-permission-icon-light.png", "/multi-tab-open-permission-icon-dark.png");
    const { items, searchItems, exportItems, itemsCount, itemsPage, itemsLimit, isLoading } = useSearchItem();
    const { forest } = useForest();
    // TODO Get from the router (if possible) the parameters that were set in the filter form
    //    OR, create a context for the filters to live (if there is not one already), and get the data from there

    // FIXME This piece of state is redundant with startDate, endDate and text above. Probably the best is to remove the
    //  above ones and keep only this
    const {filterFormData, setFilterFormData, clearFilters} = useMobileSearchFilter();
    const filterFormDataRef = React.useRef(filterFormData);

    const [currentResultsPage, setCurrentResultsPage] = useState(0);

    const { selectedItems, setSelectedItems, toggleAllCheckboxes, isAnyWebsiteSelected } = useSearchResultSelection(items);
    const { showLimitedInfo, toggleEssentialInformation, isListLayout, toggleGrid } = useSearchLayoutConfig();

    const firstShownItem = (itemsPage * itemsLimit) + 1;
    const lastShownItem = Math.min(firstShownItem + itemsLimit - 1, itemsCount);

    const cancelRef = React.useRef();
    const [skipOpenPermissionDialog, setSkipOpenPermissionDialog] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    useCallbackBasedOnNavCondition(navConditions.IS_NOT, routes.DASHBOARD_FILTERS, () => {
        forest.unselectAllTags();
        clearFilters();
    });

    // FIXME This useEffect is a code smell (a mess, in other words). We should not need to do this black magic in order
    //  to change the current search criteria. Summary:
    //  - We have a Ref that mimics the filterFormData because the identical state object is not reachable from the
    //  debounced function.
    useEffect(() => {
        const uniqueSelectedTagIds = forest.getSelectedTagIds();
        filterFormDataRef.current = {
            ...filterFormData,
            tagIds: uniqueSelectedTagIds,
        }
        searchItems(filterFormDataRef.current, currentResultsPage);
    }, [filterFormData, currentResultsPage]);

    async function clearFiltersHandler() {
        setFilterFormData(initialFilterFormData);
    }

    function showUpdateHelpMessage() {
        const userRequestedToSkipDialog = getLocalPreference(SKIP_OPEN_PERMISSION_DIALOG);
        if (userRequestedToSkipDialog) {
            updateSelectedItems();
        } else {
            onOpen();
        }
    }

    function updateSelectedItems() {
        if (selectedItems.length === 1) {
            const selectedItem = selectedItems[0];
            const itemType = selectedItem.isWebsite() ? taggedItemTypes.WEBSITE : taggedItemTypes.NOTE;
            navigate(`/tag/confirm?${itemType}_id=${selectedItem.getId()}`);
        } else {
            selectedItems
                .forEach(item => {
                    if (item.isWebsite()) {
                        window.open(`${window.location.protocol}//${window.location.host}/tag/website?website_id=${item.getId()}`, "_blank");
                    }
                    if (item.isNote()) {
                        window.open(`${window.location.protocol}//${window.location.host}/tag/note?note_id=${item.getId()}`, "_blank");
                    }
                });
        }
        setSelectedItems([]);
    }

    function showOpenHelpMessage() {
        const userRequestedToSkipDialog = getLocalPreference(SKIP_OPEN_PERMISSION_DIALOG);
        if (userRequestedToSkipDialog) {
            openSelectedItems();
        } else {
            onOpen();
        }
    }

    function openSelectedItems() {
        selectedItems
            .filter(item => item.getUrl())
            .forEach(item => {
                window.open(item.getUrl(), "_blank");
            });
        setSelectedItems([]);
    }

    function deleteSelectedItems () {
        let deletePromises = selectedItems.map((item) => {
            if (item.isWebsite()) {
                return WebsiteService.del(item.getId());
            } else {
                return NoteService.del(item.getId());
            }
        })
        Promise.all(deletePromises).then(() => {
            clearFiltersHandler();
            setSelectedItems([]);
        })
    }

    function toggleDontShowAgain() {
        setSkipOpenPermissionDialog(!skipOpenPermissionDialog);
    }

    function onUnderstoodButtonPressed() {
        onClose();
        saveLocalPreference(SKIP_OPEN_PERMISSION_DIALOG, skipOpenPermissionDialog);
        openSelectedItems();
    }

    function resultsPageChanged(newPage) {
        setCurrentResultsPage(newPage - 1);
    }

    const selectedItemsExist = selectedItems?.length > 0;

    return (
        // FIXME The below code is repeated in Dashboard.js. We should find a way to avoid this repetition
        <>
            <Box zIndex="dropdown">
                <Topbar />
            </Box>
                <Flex height={`calc(100vh - ${appTopBarHeight})`} overflowY="hidden" flexDirection="column">
                    <Box
                        flexDirection="column"
                        gap={4}
                        paddingX={4}
                        paddingBottom={selectedItemsExist ? 2 : 0}
                        justifyContent="space-between"
                        borderBottom="1px solid"
                        borderColor="gray.300"
                        height={selectedItemsExist ? undefined : searchResultsTopbarHeight}
                        backgroundColor={searchListTopBarBg}
                    >
                        {!isLoading &&
                            <DashboardTopbar
                                filterFormData={filterFormData}
                                isListLayout={isListLayout}
                                firstShownItem={firstShownItem}
                                lastShownItem={lastShownItem}
                                itemsCount={itemsCount}
                                selectedItems={selectedItems}
                                isAnyWebsiteSelected={isAnyWebsiteSelected}
                                onToggleAllCheckboxes={toggleAllCheckboxes}
                                onToggleEssentialInformation={toggleEssentialInformation}
                                onOpenWebpageClicked={showUpdateHelpMessage}
                                onUpdateWebpageClicked={showUpdateHelpMessage}
                                onExportItems={exportItems}
                                onToggleGrid={toggleGrid}
                                deleteSelectedItems={deleteSelectedItems}
                                showOpenHelpMessage={showOpenHelpMessage}
                                showLimitedInfo={showLimitedInfo}
                            />
                        }
                    </Box>
                    <Box height="inherit" overflowY="scroll">
                        <SearchItemList
                            items={items}
                            searchItems={searchItems}
                            exportItems={exportItems}
                            itemsCount={itemsCount}
                            itemsPage={itemsPage}
                            itemsLimit={itemsLimit}
                            isLoading={isLoading}
                            filterFormData={filterFormData}
                            isListLayout={isListLayout}
                            showLimitedInfo={showLimitedInfo}
                            clearFiltersHandler={clearFiltersHandler}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            resultsPage={currentResultsPage}
                            onResultsPageChanged={resultsPageChanged}
                        />
                    </Box>
                </Flex>
            <AlertDialog
                isOpen={isOpen}
                aria-labelledby="form-dialog-title"
                onClose={() => {
                    onClose();
                }}
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader id="form-dialog-title">Tab open permission</AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>If you are trying to open several pages at once, the browser could block the attempt.</Text>
                            <br/>
                            <Text>Click on the icon shown below to configure the permission:</Text>
                            <Image src={tabOpenPermissionIconSrc} alt="Icon to edit tab open permissions" margin="auto" />
                        </AlertDialogBody>
                        <AlertDialogFooter gap={4} justifyContent="space-between">
                            <Flex gap={2}>
                                <Checkbox
                                    aria-labelledby="dont-show-again-label"
                                    isChecked={skipOpenPermissionDialog}
                                    onChange={toggleDontShowAgain}
                                />
                                <Text
                                    id="dont-show-again-label"
                                    cursor="pointer"
                                    onClick={toggleDontShowAgain}
                                >
                                    Don't show again
                                </Text>
                            </Flex>
                            <Button
                                onClick={onUnderstoodButtonPressed}
                                ref={cancelRef}
                            >
                                Understood
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
};

export default DashboardMobile;
