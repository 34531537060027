import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import {
    Avatar,
    FormControl,
    FormLabel,
    Switch,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuDivider,
    MenuList,
    Link as ChakraLink,
    Flex,
    Image,
    Tooltip,
    useColorModeValue,
    useColorMode
} from '@chakra-ui/react'

import {
    SettingsIcon
} from '@chakra-ui/icons'

import {TopbarLink} from "./components/TopbarLink";
import StoreService from "../../services/store.service";
import {appTopBarHeight} from "../../styles/layoutStyles";
import { usePlatformContext } from "../../contexts/PlatformContextProvider";
import {routes} from "../../constants";

const TopBar = () => {
    
    const logoImage = useColorModeValue("/tag-machine-logo-tree-only.svg", "/tag-machine-logo-tree-only-contrast.svg");
    const borderColor = useColorModeValue("blue.600", "gray.300");
    const backgroundColor = useColorModeValue("white", "gray.800");
    const { colorMode, toggleColorMode } = useColorMode();
    const { isWebPlatform } = usePlatformContext();
    const navigate = useNavigate();
    // TODO Move the following line string to constants (use constants.ROUTES)
    const taggingRoute = isWebPlatform ? "/tag/website" : "/tag/select-tags";

    const logout = async () => {
        await StoreService.clearUserStorage()
        navigate(routes.LOGIN);
    }

    return (
        <Flex
            as="header"
            all="unset"
            justifyContent="space-between"
            height={appTopBarHeight}
            alignItems="middle"
            paddingX={4}
            paddingY={2}
            borderBottom="1px solid"
            borderColor={borderColor}
            backgroundColor={backgroundColor}
        >
            <Flex gap={{ base: 4, md: 16 }} flexGrow={3} alignItems="center">
                <Image src={logoImage} alt="Tag Machine Logo" boxSize='40px'/>
                <Flex as="nav" gap={10} alignItems="center">
                    <TopbarLink href={taggingRoute}>Tag</TopbarLink>
                    <TopbarLink href="/dashboard">Search</TopbarLink>
                    { StoreService.getIsAdmin() === 'true' &&
                        <TopbarLink href="/admin">Admin</TopbarLink>
                    }
                    { StoreService.getIsGroupAccountAdmin() === 'true' &&
                        <TopbarLink href="/group_account/users">Group Account</TopbarLink>
                    }
                </Flex>
            </Flex>
            <Flex gap={6} flexGrow={1} justifyContent="flex-end">
                {isWebPlatform &&
                    <FormControl display='flex' alignItems='center' width="fit-content">
                        <FormLabel htmlFor='color-mode' mb='0' cursor="pointer">
                            Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
                        </FormLabel>
                        <Switch id='color-mode' onChange={toggleColorMode} />
                    </FormControl>
                }
                <Menu>
                    <Tooltip label='Menu'>
                        <MenuButton>
                            <SettingsIcon boxSize={8} tool />
                        </MenuButton>
                    </Tooltip>
                    <MenuList>
                        <MenuGroup title='Profile'>
                            <MenuItem>
                                <ChakraLink as={Link} to="/user_profile">My Account</ChakraLink>
                            </MenuItem>
                            <MenuItem><ChakraLink as={Link} to="/my_account">My Subscription</ChakraLink></MenuItem>
                            <MenuItem onClick={logout}>Log Out</MenuItem>
                        </MenuGroup>
                        <MenuDivider/>
                        <MenuGroup title='Help'>
                            <MenuItem>
                                <ChakraLink as={Link} to="https://tagmachine.me" target="_blank">
                                    Website
                                </ChakraLink>
                            </MenuItem>
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    )
}

export default TopBar;
