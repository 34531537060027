import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from "@chakra-ui/react";
import React from "react";

export function CreateTreeFromTagModal({ isOpen, leastDestructiveRef, onClose, onSubmit }) {
    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={leastDestructiveRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Create Tree from Branch
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Are you sure?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={leastDestructiveRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme='red'
                            onClick={onSubmit}
                            ml={3}
                        >
                            Accept
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}