import React, { useState } from 'react'

import UserService from "../../services/user.service";
import StoreService from '../../services/store.service';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Select, Text
} from "@chakra-ui/react";
import logo from "../../assets/images/tagmac.png";
import {useNavigate} from "react-router-dom";
import {routes} from "../../constants";

const { getNames } = require('country-list');

const ProfileLayout = () => {

    const userId = StoreService.getUserId()

    const [showLoading, setShowLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [registerError, setRegisterError] = useState(null);
    const [formData, setFormData] = useState({
        dob: null,
        country: '',
        phone: ''
    })
    const navigate = useNavigate();
    const countryList = getNames().sort();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowLoading(true);
        try {
            let response = await UserService.updateUserData(userId, formData);
            if (response?.success) {
                setRegisterError('')
                let url = StoreService.getCurrentUrl();
                if (url && url !== ''){
                    StoreService.setCurrentUrl('');
                    navigate(url);
                }
                else {
                    navigate(routes.APP_ROOT);
                }
            }
            if (!response?.success && response?.message) {
                setRegisterError(response?.message);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowLoading(false);
        }
    }

    function handleDateChange(event) {
        const newDate = new Date(event.target.valueAsDate);
        setStartDate(newDate);
        let formattedDate = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
        setFormData({
            ...formData,
            dob: formattedDate
        })
    }

    function handleCountryChange(event) {
        setFormData({
            ...formData,
            country: event.target.value,
        });
    }

    function handlePhoneChange(event) {
        setFormData({
            ...formData,
            phone: event.target.value,
        });
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={4}
            alignItems="center"
            width="sm"
            marginTop={16}
            marginX="auto"
            padding={8}
            shadow="lg"
        >
            <figure>
                <Image src={logo} alt="Tag Machine logo"/>
            </figure>
            <Heading as="h1" fontSize="xl">Complete Profile</Heading>
            <form onSubmit={handleSubmit}>
                <Flex direction="column" gap={4}>
                    <FormControl>
                        <FormLabel>Date of birth</FormLabel>
                        <Input
                            type="date"
                            selected={startDate}
                            onChange={handleDateChange}
                            placeholder='Date of birth'
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Country</FormLabel>
                        <Select name="country" onChange={handleCountryChange}>
                            <option value="country">Select Country</option>
                            {countryList.map(country => <option value={country} key={country}>{country}</option>)}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Telephone number</FormLabel>
                        <Input
                            type="phone"
                            name="phone"
                            placeholder="Phone"
                            onChange={handlePhoneChange}
                        />
                    </FormControl>
                    {registerError && <Text color="red">{registerError}</Text>}
                    <Button
                        colorScheme="blue"
                        type='submit'
                    >
                        {showLoading ? 'Saving' : 'Register'}
                    </Button>
                </Flex>
            </form>
        </Box>
    )
}

export default ProfileLayout
