import React, { useState } from 'react'; 

import {
    Box, Button,
    FormControl, FormLabel, FormErrorMessage, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    Text
} from "@chakra-ui/react";

import Utils from '../../helpers/utils.helper'

import { createKeyHandler, keys } from "../../helpers/keyboard.helper";

export function ShareTagModal({ isOpen, onSubmit, onClose, initialFocusRef }) {

    const enterKeyHandler = createKeyHandler(keys.ENTER, () => submit(email, permission));

    const [ email, setEmail ] = useState('')
    const [ permission, setPermission ] = useState('read')

    const submit = ((email, permission) => {
        if (Utils.isValidEmail(email)){
            if (onSubmit){
                onSubmit(email, permission)
            }
        }
    })

    return (
        <Modal
            initialFocusRef={initialFocusRef}
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
        >
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader>Share Tag</ModalHeader>
                <ModalCloseButton/>
                <ModalBody pb={6}>
                    <Text marginBottom={4} color="gray.500">Enter a valid email to share the tree with</Text>
                    <Box display="flex" gap={4}>
                        <FormControl isInvalid={!Utils.isValidEmail(email)}>
                            <FormLabel>Email Address</FormLabel>
                            <Input
                                type="email"
                                ref={initialFocusRef}
                                placeholder='Email'
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyUp={enterKeyHandler}
                            />
                            { (!Utils.isValidEmail(email)) && 
                                <FormErrorMessage>A valid email is required.</FormErrorMessage>
                            }
                        </FormControl>
                        <FormControl>
                            <FormLabel>Share permissions</FormLabel>
                            <Select
                                name="Share permissions"
                                value={permission}
                                onChange={(e) => {
                                    setPermission(e.target.value)
                                }}
                                onKeyUp={enterKeyHandler}
                            >
                                <option value="read">Read</option>
                                <option value="write">Read/Write</option>
                            </Select>
                        </FormControl>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        mr={3}
                        onClick={() => submit(email, permission)}
                    >
                        Share
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}