class Tag {

    constructor({id, name, color = null, shares, permission, parentId, children, treeId, createdBy, createdAt, updatedAt, checked}) {
        this.id = id;
        this.name = name;
        this.color = color;
        this.shares = shares;
        this.permission = permission;
        this.parentId = parentId;
        this.children = children;
        this.treeId = treeId;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.checked = checked;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getColor() {
        return this.color;
    }

    getShares() {
        return this.shares.map(share => ({
            // TODO Consider using destination/target instead of to and from in order to decrease the amount of
            //  properties in this object
            from: share.from,
            to: share.to,
            treeId: share.treeId,
            toFirstName: share.toFirstName,
            toLastName: share.toLastName,
            toEmail: share.toEmail,
            fromFirstName: share.fromFirstName,
            fromLastName: share.fromLastName,
            fromEmail: share.fromEmail,
            permission: share.permission,
        }));
    }

    getPermission() {
        return this.permission;
    }

    getParentId() {
        return this.parentId;
    }

    setParentId(parentId) {
        this.parentId = parentId;
    }

    getChildren() {
        return this.children;
    }

    setChildren(children) {
        this.children = children;
    }

    addChild(child) {
        this.children = [
            ...this.children,
            child,
        ];
    }

    getTreeId() {
        return this.treeId;
    }

    setColor(color) {
        this.color = color;
    }

    getCreatedBy() {
        return this.createdBy;
    }

    getCreatedAt() {
        return this.createdAt;
    }

    getUpdatedAt() {
        return this.updatedAt;
    }

    isChecked() {
        return this.checked;
    }

    setIsChecked(checked) {
        this.checked = checked;
    }

    toString() {
        return JSON.stringify({
            id: this.getId(),
            name: this.getName(),
            color: this.getColor(),
            shares: this.getShares(),
            permission: this.getPermission(),
            parentId: this.getParentId(),
            children: this.getChildren(),
            treeId: this.getTreeId(),
            createdBy: this.getCreatedBy(),
            createdAt: this.getCreatedAt(),
            updatedAt: this.getUpdatedAt(),
            checked: this.isChecked(),
        });
    }
}

export function buildTag({id, name, color = null, shares, permission, parentId, children, treeId, createdBy, createdAt, updatedAt, checked}) {
    return new Tag({id, name, color, shares, permission, parentId, children, treeId, createdBy, createdAt, updatedAt, checked});
}
