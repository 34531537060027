import axios from 'axios';
import StoreService from './store.service'
import { routes } from "../constants";
import { history } from "../helpers/history.helper";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(
    async (config) => {
        const token = await StoreService.getJwtToken();
        if (token){
            config.headers["Authorization"] = token;
            config.headers["Cache-Control"] = "no-cache"
        }
        return config;
    },
    (error) => {
        throw Promise.reject(error)
    }
);
instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response !== undefined){
            if (error.response.status === 401){
                // We avoid setting current URL as login, because it's purpose is to know where to go after a
                // redirection to login and successful authentication
                if (window.location.href.indexOf(routes.LOGIN) === -1) {
                    StoreService.setCurrentUrl(window.location.pathname + window.location.search);
                }
                history.navigate(routes.LOGIN, "_self");
            }
            if (error.response.data.message){
                error.message = error.response.data.message
            }
        }

        throw error
    }
)
export default instance
