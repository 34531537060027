import React from "react";

import { 
    Flex, 
    Heading,
    TabList,
    TabPanel,
    TabPanels,
    Tab,
    Tabs, 
    Button
} from '@chakra-ui/react'

import { Outlet, useLocation } from "react-router-dom";

import Users from "./Users";

import TreeManagement from "./TreeManagement"
import { useGroupAccount } from "../../contexts/GroupAccountContextProvider"

export function GroupAccountUI() {
    
    const { groupAccount, getGroupAccount, saveGroupAccount } = useGroupAccount();

    const location = useLocation();

    let activeTabIndex = 0;
    if(location.pathname.indexOf("/users") !== -1) {
        activeTabIndex = 1;
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        saveGroupAccount(groupAccount)
    }

    React.useEffect(() => {
        getGroupAccount();
    }, []);

    return (
        <Flex flexDirection="column" >
            <Heading
                as="h1"
                fontSize="2xl"
                height="4rem"
                borderColor="gray.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
            </Heading>
            { (groupAccount && !groupAccount.id) && 
                <Flex
                    justifyContent="center"
                >
                    <Button
                        type='submit'
                        colorScheme="blue"
                        onClick={handleSubmit}
                    >Create group account</Button>
                </Flex>
            }
            { groupAccount && groupAccount.id && 
                <Tabs padding={4} defaultIndex={activeTabIndex}>
                    <TabList>
                        <Tab>Users</Tab>
                        <Tab>Tree Management</Tab>
                    </TabList>
                    <Outlet />
                </Tabs>
            }
        </Flex>
    );
}

export function GroupAccountTabPanels() {

    const { groupAccount } = useGroupAccount();

    return (
        <TabPanels>
            { groupAccount && groupAccount.id && 
                <TabPanel paddingX="10%">
                    <Users/>
                </TabPanel>
            }
            { groupAccount && groupAccount.id && 
                <TabPanel paddingX="10%">
                    <TreeManagement/>
                </TabPanel>
            }
        </TabPanels>
    );
}
