import { routes } from "../constants";

const mobileTaggingRoutes = [
    routes.TAG_SELECT_TAGS,
    routes.TAG_CONFIRM,
];

/**
 *
 * @param {String} route
 * @returns {Boolean} True if the route belongs to a desktop tagging experience route.
 */
export function isWebTaggingExperience(route) {
    // If none of the routes in mobileTaggingRoutes contains the route, then it is a desktop tagging experience route.
    if (mobileTaggingRoutes.every((mobileTaggingRoute) => !route.includes(mobileTaggingRoute))) {
        return true;
    }
    return false;
}