import React from "react";
import {Icon, Menu, MenuButton, MenuDivider, MenuList, MenuOptionGroup} from "@chakra-ui/react";
import {VscKebabVertical as KebabVerticalIcon} from "react-icons/vsc";
import { contextualItemComponents} from "./components";

export function TagContextualMenu({ contextualMenuItems, onItemSelected = () => {} }) {

    return (
        <Menu>
            {/* This way of rendering the children of Menu is to avoid a bug in Chrome that causes an */}
            {/* incorrect drag preview. Check this for details: https://github.com/chakra-ui/chakra-ui/issues/6762 */}
            {({ isOpen }) => (
                <>
                    <MenuButton onClick={(e) => e.stopPropagation()}>
                        <Icon as={KebabVerticalIcon}/>
                    </MenuButton>
                    <MenuList display={!isOpen ? 'none' : null} maxHeight="20rem" overflowY="auto">
                        {contextualMenuItems.map(contextualItem => {
                            if(contextualItem.submenu) {
                                return (
                                    <>
                                        <MenuDivider />
                                        <MenuOptionGroup title={contextualItem.label}>
                                            {contextualItem.submenu.map(submenuItem => {
                                                const Component = contextualItemComponents[submenuItem.type];
                                                return (
                                                    <Component
                                                        contextualItem={{...contextualItem, ...submenuItem}}
                                                        onClick={onItemSelected}
                                                    />
                                                );
                                            })}
                                        </MenuOptionGroup>
                                    </>
                                );
                            }
                            const Component = contextualItemComponents[contextualItem.type];
                            return (
                                <Component
                                    contextualItem={contextualItem}
                                    onClick={onItemSelected}
                                />
                            )
                        })}
                    </MenuList>
                </>
            )}
        </Menu>
    );
}
