import { Box } from "@chakra-ui/react";
import React from "react";
import { DumbTag } from "../tag/DumbTag";
import { TagPathText } from "../pathText/TagPathText";

export function ForestSharedOnly({ subtrees, accountId, onCheckboxChange, contextualMenuItemSelected }) {
    return (
        subtrees.map(({ tag, isRoot, pathToSubtreeRoot }) => {
            let pathText;
            if (isRoot) {
                // If the tag doesn't have any parent, put the name of the tag in the path
                if (pathToSubtreeRoot.length === 0) {
                    pathToSubtreeRoot.push(tag);
                }
                pathText = `/${pathToSubtreeRoot.map(tagInPath => tagInPath.getName()).join("/")}`;
            }

            return (
                <Box padding={2} key={tag.getId()}>
                    {/* FIXME This is repeated in TagSearchResult */}
                    {!!pathText && <TagPathText>{pathText}</TagPathText>}
                    <DumbTag
                        tag={tag}
                        accountId={accountId}
                        onCheckboxChange={onCheckboxChange}
                        contextualMenuItemSelected={contextualMenuItemSelected}
                    />
                </Box>
            );
        })
    )
}