import {forestTopBarHeight} from "../../styles/layoutStyles";
import {
    Box,
    Flex,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Spinner,
    useColorModeValue
} from "@chakra-ui/react";
import {VscChromeClose as CloseIcon, VscSearch as SearchIcon} from "react-icons/vsc";
import React, {useContext} from "react";
import {
    SearchForestContext,
    SharedFilterForestContext,
    TagSelectionForestContext,
    TreesForestContext
} from "./Forest";
import {usePlatformContext} from "../../contexts/PlatformContextProvider";

export const ForestSearchBar = () => {
    const { searchQuery, setSearchQuery, setSearchResults, searchInProgress } = useContext(SearchForestContext);
    const { areTreeTagsSelected, unselectAllTags } = useContext(TagSelectionForestContext);
    const { toggleSharedFilter, isSharedFilterOn } = useContext(SharedFilterForestContext);
    const { trees } = useContext(TreesForestContext);
    const { isWebPlatform} = usePlatformContext();
    const forestTopBarBg = useColorModeValue("white", "gray.800");

    const onSearchInputChanged = (e) => {
        const text = e.target.value;
        setSearchQuery(text);
    };

    const clearSearch = () => {
        setSearchQuery("");
        setSearchResults([]);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                "@container (min-width: 400px)": {
                    "&": {
                        flexDirection: "row",
                        height: { forestTopBarHeight },
                    },
                },
            }}
            justifyContent="space-around"
            padding={2}
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.300"
            backgroundColor={forestTopBarBg}
        >
            <InputGroup
                sx={{
                    "@container (min-width: 400px)": {
                        "&": {
                            minWidth: "50%"
                        },
                    },
                }}
            >
                <InputLeftElement
                    pointerEvents='none'
                    children={searchInProgress ? <Spinner size='sm' color="gray.500" /> : <SearchIcon color="gray.500" />}
                />
                <Input
                    type="text"
                    onChange={onSearchInputChanged}
                    value={searchQuery}
                    placeholder="Search tags"
                />
                <InputRightElement
                    cursor="pointer"
                    children={<CloseIcon color='gray.500' />}
                    onClick={clearSearch}
                />
            </InputGroup>
            <Flex
                sx={{
                    "@container (min-width: 400px)": {
                        "&": {
                            minWidth: "50%"
                        },
                    },
                }}
            >
                <Button
                    variant="ghost"
                    fontSize="sm"
                    paddingX={6}
                    colorScheme="blue"
                    isDisabled={!areTreeTagsSelected}
                    onClick={() => { unselectAllTags() }}
                >
                    Unselect All
                </Button>
                {isWebPlatform && (
                    <Button
                        variant="ghost"
                        fontSize="sm"
                        paddingX={6}
                        colorScheme="blue"
                        onClick={toggleSharedFilter}
                        id="toggle-shared"
                        isDisabled={!trees}
                    >
                        {isSharedFilterOn ? "Show all" : "Show shared"}
                    </Button>
                )}
            </Flex>
        </Box>
    )
}
