import UserService from "../services/user.service";


export const UserRepository = {
    getUser: function getUser() {
        return UserService.getUser()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    getUsers: function getUsers() {
        return UserService.searchUser({})
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    updateUser: function updateUser(userId, user) {
        return UserService.updateUserData(userId, user)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    deleteUsers: function deleteUsers(userIds) {
        return UserService.deleteUsers(userIds)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    deactivateUser: function deactivateUser() {
        return UserService.deactivateUser()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    exportUsers: function exportUsers(data) {
        return UserService.exportUsers(data)
    },
    search: function search(data) {
        return UserService.searchUser(data)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    getSubscriptions: function getSubscriptions() {
        return UserService.getUserSubscriptions()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    getPaymentSession: function getPaymentSession() {
        return UserService.getUserPaymentSession()
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    isEmailExist: function isEmailExist(email) {
        return UserService.isEmailExist(email)
        .then(response => {
            if (!response.success && response.message){
                throw new Error(response.message);
            }
            return response.data;
        }, (error) => {
            throw error
        })
    },
    setAdmin: function setAdmin(userId, isAdmin) {
        return UserService.setUserAdmin(userId, isAdmin)
        .then(response => {
            if (!response?.success && response?.message){
                throw new Error(response.message);
            }
            else {
                return response.data
            }
        }, (error) => {
            throw error
        })
    }
}