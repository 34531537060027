import { ForestRoot } from "./Forest";
import { ForestSearchBar } from "./ForestSearchBar";
import { ForestTitleBar } from "./ForestTitleBar";
import { ForestTrees } from "./ForestTrees";

export const Forest = {
    Root: ForestRoot,
    SearchBar: ForestSearchBar,
    TitleBar: ForestTitleBar,
    Trees: ForestTrees,
};