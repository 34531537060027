import React from 'react'

import { useUser } from "../../contexts/UserContextProvider";
import {Box, FormControl, FormLabel, Input, Select, Button, useBreakpointValue} from "@chakra-ui/react";

import { getNames as getCountryNames } from "country-list";


const UserProfile = React.memo(() => {

    const formRowStyles = useBreakpointValue(
        {
            base: {},
            sm: {
                display: "flex",
                gap: 16,
                justifyContext: "space-between",
            },
        },
    )
    const countryList = getCountryNames().sort();
    const { user, updateUser, setUser, getUser, deactivateUser } = useUser();

    const handleSubmit = (e) => {
        e.preventDefault();
        updateUser(user)
    }

    const deactivateAccount = (e) => {
        e.preventDefault();
        deactivateUser()
    }

    React.useEffect(() => {
        getUser();
    }, []);

    return (

        <form>
            <Box width={{base: null, md: "2xl"}}
                 margin="auto"
                 marginTop={{base: 4, sm: 16}}
                 padding={{base: 4, md: 0 }}
                 display={{ base: null, sm: "flex" }}
                 flexDirection={{ base: null, sm: "column" }}
                 gap={{ base: null, sm: 8 }}
            >
                <Box {...formRowStyles} >
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>First name</FormLabel>
                        <Input
                            value={user.firstName}
                            onChange={(e) => {
                                setUser({...user, firstName: e.target.value})
                            }}
                        />
                    </FormControl>
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Last name</FormLabel>
                        <Input
                            value={user.lastName}
                            onChange={(e) => {
                                setUser({...user, lastName: e.target.value})
                            }}
                        />
                    </FormControl>
                </Box>
                <Box {...formRowStyles} >
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Country</FormLabel>
                        <Select
                            placeholder='Select option'
                            value={user.country}
                            onChange={(event, value) => {
                                setUser({...user, country: value})
                            }}
                        >
                            {countryList.map(country => (
                                <option value={country}>{country}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Date of birth</FormLabel>
                        <Input
                            value={user.dob}
                            type="date"
                            onChange={(e) => {
                                setUser({...user, dob: e.target.value})
                            }}
                        />
                    </FormControl>
                </Box>
                <Box {...formRowStyles} >
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Phone</FormLabel>
                        <Input
                            type="number"
                            value={user.phone}
                            onChange={(e) => {
                                setUser({...user, phone: e.target.value})
                            }}
                        />
                    </FormControl>
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Address</FormLabel>
                        <Input
                            value={user.address}
                            onChange={(e) => {
                                setUser({...user, address: e.target.value})
                            }}
                        />
                    </FormControl>
                </Box>
                <Box {...formRowStyles} >
                    <FormControl marginBottom={{base: 4, sm: null}}>
                        <FormLabel>Mail</FormLabel>
                        <Input
                            isDisabled={true}
                            value={user.email}
                            onChange={(e) => {
                                setUser({...user, email: e.target.value})
                            }}
                        />
                    </FormControl>
                </Box>
                <Box {...formRowStyles}
                >
                    <Box
                        width="100%"
                        textAlign="start"
                    >
                        <Button
                            type='submit'
                            colorScheme="red"
                            onClick={deactivateAccount}
                        >
                            Deactivate account
                        </Button>
                    </Box>
                    <Box
                        width="100%"
                        textAlign="end"
                    >
                        <Button
                            type='submit'
                            colorScheme="blue"
                            onClick={handleSubmit}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </Box>
        </form>
    )
});

export { UserProfile };
