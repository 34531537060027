import {Box, Button, Text} from "@chakra-ui/react";

const MAX_PAGES_SHOWN = 5;

export function Pagination({currentPage, amountOfPages, onChange}) {

    const shouldShowAllPages = amountOfPages <= MAX_PAGES_SHOWN;
    let visiblePages = createListOfPages(amountOfPages);
    if (!shouldShowAllPages) {
        visiblePages = getPagesToShow(currentPage, amountOfPages);
    }
    const highlightedPage = Math.max(1, Math.min(currentPage, amountOfPages));

    return (
        <Box display="flex" gap={1}>
            <Button
                size="sm"
                variant="ghost"
                onClick={() => onChange(currentPage - 1)}
                isDisabled={highlightedPage === 1}
            >
                Previous
            </Button>
            {visiblePages.map((page, index, fullList) => {
                const nextPage = fullList[index + 1]
                const isLastItem = index === fullList.length - 1;
                const gapExistsWithNextPage = nextPage - page > 1;
                return (
                    <>
                        <Page
                            pageNumber={page}
                            onClick={() => onChange(page)}
                            isHighlighted={highlightedPage === page}
                        />
                        {!isLastItem && gapExistsWithNextPage && (
                            <Text>...</Text>
                        )}
                    </>
                )
            })}
            <Button
                size="sm"
                variant="ghost"
                onClick={() => onChange(currentPage + 1)}
                isDisabled={highlightedPage === amountOfPages}
            >
                Next
            </Button>
        </Box>
    )
}

function Page({pageNumber, onClick, isHighlighted}) {
    return (
        <Button
            key={pageNumber}
            size="sm"
            variant={isHighlighted ? "solid" : "ghost"}
            onClick={onClick}
        >
            {pageNumber}
        </Button>
    )
}

function createListOfPages(amountOfPages) {
    const [, ...pagesList] = [...Array(amountOfPages).keys(), amountOfPages];
    return pagesList;
}

function getPagesToShow(currentPage, amountOfPages) {
    if (currentPage <= 3) {
        return [1, 2, 3, 4, amountOfPages];
    } else if (currentPage >= amountOfPages - 3) {
        return [1, amountOfPages - 3, amountOfPages - 2, amountOfPages - 1, amountOfPages];
    } else {
        return [1, currentPage - 1, currentPage, currentPage + 1, amountOfPages];
    }
}